import React from 'react'
import img from '../../images/carloan.jpeg'
import img1 from '../../images/bt1.jpeg'
import img3 from '../../images/cl2.jpeg'
import img2 from '../../images/pl3.jpeg'
import img4 from '../../images/bt4.jpeg'
import img5 from '../../images/cl1.jpeg'
import { Link } from 'react-router-dom'

import HelpYou from '../home/whychoose/HelpYou'
import '../personalloan/personalloan.css'
const BalanceTransfer = () => {
    return (
        <>
            <div id="carouselExampleCaptions" class="carousel slide">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src={img} class="d-block w-100" alt="..." style={{ height: '45vh' }} />
                        <div class="carousel-caption d-md-block">
                            <h1 style={{ color: 'white' }}>Car Loan Balance Transfer + Topup</h1>
                            <p style={{ color: 'white' }}>Home &nbsp; &#10095; &nbsp; Car Loan Balance Transfer + Topup</p>
                        </div>
                    </div>
                </div>

            </div>
            <nav class="navbar navbar-dark bg-dark navbar-expand-lg navBar">
                <div class="container-fluid">
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                            <li class="nav-item">
                                <a class="nav-link" href="#bt">Car Loan Balance Transfer + Topup</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#requirements">Requirements</a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link" href="#doc"> Documents required</a>
                            </li>


                            <li class="nav-item">
                                <a class="nav-link" href="#bank">About Our Bank Tie-Up</a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#benifits">Benefits of Bank Tie-Ups</a>
                            </li>
                        </ul>
                        <a href="https://wa.me/919506010080"><button class="btn btn-outline-wtsap my-2 my-sm-0" style={{ color: 'white', backgroundColor: 'green', borderRadius: '5px', padding: '6px 15px', marginLeft: '10px' }}><i class="fa fa-whatsapp"></i>&nbsp;Whatsapp</button></a>
                        <Link to="/getappointment"> <button class="btn btn-outline-apply my-2 my-sm-0" type="submit" style={{ color: 'black', backgroundColor: 'white', borderRadius: '5px', padding: '6px 15px', marginLeft: '10px' }}>Apply Now</button></Link>

                    </div>
                </div>
            </nav>

            <div id="bt"></div>
            <section class="main-body">
                <div class="container">


                    <div id="bt"></div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="loan-lg-card">
                                <h4> About Car Loan Balance Transfer + Topup</h4>
                                <div class="row">
                                    <div class="col-md-8">
                                        <p>Getting a top-up loan for working capital needs can allow you to keep the balance of your existing car loan. We make it easy and hassle-free to transfer your car loan</p>

                                        <h5>Benefits</h5>

                                        <ul class="listnone bullet bullet-check-circle-default">
                                            <li style={{ color: "#404040" }}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                                                <b>Low-cost loans with high values -</b> With a maximum loan amount of Rs. 20 lakh, get loans up to 160% of your car's value. Car khareedo becho 24 offers attractive interest rates so that your EMIs are easy on the wallet.
                                            </li>
                                            <li style={{ color: "#404040" }}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                                                <b> EMIs are easy – </b>Our attractive range of tenor options allows you to repay the loan when it is convenient for you.
                                            </li>
                                            <li style={{ color: "#404040" }}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                                                <b> Quicker processing - </b>The funds are transferred to your bank account within 48 hours after Bajaj approves your loan application.
                                            </li>
                                            <li style={{ color: "#404040" }}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                                                <b>  Our services are available at your doorstep - </b>For a hassle-free application process, we will collect your documents directly from your home.
                                            </li>
                                            <li style={{ color: "#404040" }}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                                                <b>  Pre-approved special offers - </b>Customers with an active account are eligible for a host of pre-approved offers.
                                            </li>
                                            <li style={{ color: "#404040" }}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                                                <b> Providers of premium services - </b>Pay a premium for additional services such as car insurance and life insurance, financial fitness reports, GPS trackers, and more.
                                            </li>


                                        </ul>



                                    </div>
                                    <div class="col-md-4">
                                        <img width="100%" src={img1} alt="" />
                                    </div>
                                </div>
                            </div>

                            <div id="requirements"></div>
                        </div>
                    </div>






                    <div class="row">
                        <div class="col-md-12">
                            <div class="loan-lg-card">
                                <h4> Requirements</h4>
                                <div class="row">
                                    <div class="col-md-8">


                                        <h6>Paid employees</h6>

                                        <ul class="listnone bullet bullet-check-circle-default">
                                            <li style={{ color: "#404040" }}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                                                The borrower must be 21 years old when applying for the loan and no younger than 60 at the end of the loan agreement.
                                            </li>
                                            <li style={{ color: "#404040" }}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                                                Individuals who have worked for a minimum of one year and earn at least Rs. 20,000 per month.
                                            </li>
                                            <li style={{ color: "#404040" }}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                                                There should be a minimum of 11 months of repayment history on the vehicle.
                                            </li>
                                        </ul>

                                        <h6>Self-employed individuals:</h6>

                                        <ul>
                                            <li style={{ color: "#404040" }}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                                                It includes self-employed sole proprietors with a minimum age of 25 to apply for the loan and no older than 60 at the end of the loan tenor.
                                            </li>
                                            <li style={{ color: "#404040" }}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                                                An individual's ITR of a minimum of Rs. 250000 and two years of ITR are required.
                                            </li>
                                            <li style={{ color: "#404040" }}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                                                There should be a minimum of 11 months of repayment history on the vehicle.
                                            </li>


                                        </ul>
                                    </div>
                                    <div class="col-md-4">
                                        <img width="100%" src={img2} alt="" />
                                    </div>
                                
                            </div>
                        </div>

                        <div id="doc"></div>
                    </div>
                </div>





                <div class="row">
                    <div class="col-md-12">
                        <div class="loan-lg-card">
                            <h4>We help you to transfer the balance of your existing car loan & get a top up on that to fulfill your capital needs.</h4>
                            <div class="row">
                                <div class="col-md-8">


                                    <h5>Documents required</h5>

                                    <ul class="listnone bullet bullet-check-circle-default">
                                        <li style={{ color: "#404040" }}><span class="material-symbols-outlined">
                                            check_circle
                                        </span>
                                            KYC
                                        </li>
                                        <li style={{ color: "#404040" }}><span class="material-symbols-outlined">
                                            check_circle
                                        </span>
                                            Picture the size of a passport
                                        </li>
                                        <li style={{ color: "#404040" }}><span class="material-symbols-outlined">
                                            check_circle
                                        </span>
                                            Statement of accounts
                                        </li>

                                        <li style={{ color: "#404040" }}><span class="material-symbols-outlined">
                                            check_circle
                                        </span>
                                            Income tax returns or salary slips
                                        </li>
                                    </ul>

                                    </div>

                                    <div class="col-md-4">
                                        <img width="100%" src={img3} alt="" />
                                    </div>
                                </div>
                            </div>

                            <div id="bank"></div>
                        </div>
                    </div>


                    <div class="row">
                        <div class="col-md-12">
                            <div class="loan-lg-card">
                                <h4>About Our Bank Tie-Up</h4>
                                <div class="row">
                                    <div class="col-md-8">

                                        <p>As a car buyer, you will learn extensively about the various insurance policies available. The car dealer makes it easy to buy insurance by bundling it with the car sale, so you would probably prefer to buy it directly from them.<br /><br />

                                            By eliminating some tasks during loan processing that involve the units involved in the projects, tie-up arrangements reduce loan turnaround time. No one can claim their project is a matter of right linked to the Bank.</p>


                                    </div>
                                    <div class="col-md-4">
                                        <img width="100%" src={img4} alt="" />
                                    </div>
                                </div>
                            </div>
                            <div id="benifits"></div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="loan-lg-card">
                                <h4>Benefits of Bank Tie-Ups</h4>
                                <p>Getting a car loan at the dealership where you purchase your car can simplify the loan process slightly. Even so, there are benefits to dealer financing, such as:</p>
                                <div class="row">
                                    <div class="col-md-8">


                                        <h5>Documents required</h5>

                                        <ul class="listnone bullet bullet-check-circle-default">
                                            <li style={{ color: "#404040" }}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                                                <b>Discounts -- </b>A dealer financing is usually a better deal than going to a bank or credit union because dealerships often offer promotions, like 0% APR for preferred new car models.
                                            </li>
                                            <li style={{ color: "#404040" }}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                                                <b>A day of shopping. -- </b>The advantage of dealer financing is you get your car and your car loan all at the same place, which means you can drive away in one afternoon.
                                            </li>
                                        </ul>

                                    </div>

                                    <div class="col-md-4">
                                        <img width="100%" src={img5} alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    
                </div>
                </div>
            </section>
            <HelpYou />
        </>
    )
}

export default BalanceTransfer
