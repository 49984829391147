import React from 'react'
import img from '../../images/personalloan.jpeg'
import img1 from '../../images/pl1.jpeg'
import img2 from '../../images/pl2.jpeg'
import img3 from '../../images/pl3.jpeg'
import img4 from '../../images/pl4.jpeg'
import HelpYou from '../home/whychoose/HelpYou'
import { Link } from 'react-router-dom'
import './personalloan.css'
const PersonalLoan = () => {
  return (
    <>
     <div id="carouselExampleCaptions" class="carousel slide">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src={img} class="d-block w-100" alt="..." style={{ height: '45vh' }} />
                        <div class="carousel-caption d-md-block">
                            <h1 style={{ color: 'white' }}>Personal Loan</h1>
                            <p style={{ color: 'white' }}>Home &nbsp; &#10095; &nbsp; Personal Loan</p>
                        </div>
                    </div>
                </div>

            </div>
            <nav class="navbar navbar-dark bg-dark navbar-expand-lg navBar">
  <div class="container-fluid">
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
      <li class="nav-item">
                    <a class="nav-link" href="#personal">Personal loan</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#features">Features</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#eligible">Eligibility</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#types">Loan Types</a>
                </li>
               
                <li class="nav-item">
                    <a class="nav-link" href="#why">Why BHUMI FINANCE</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#point">Point To Be Considered </a>
                </li>
                <li className='nav-item'>
                 <Link class="nav-link" to="/emicalc">Calculate your Home Loan EMI</Link>
                </li>
                <li class="nav-item">
                    <Link class="nav-link" to="/contact">Contact Us</Link>
                </li>
      </ul>
      <a href="https://wa.me/919506010080"><button class="btn btn-outline-wtsap my-2 my-sm-0" style={{color:'white',backgroundColor:'green',borderRadius:'5px',padding:'6px 15px',marginLeft:'10px'}}><i class="fa fa-whatsapp"></i>&nbsp;Whatsapp</button></a>
      <Link to="/getappointment"> <button class="btn btn-outline-apply my-2 my-sm-0" type="submit" style={{ color: 'black', backgroundColor: 'white', borderRadius: '5px', padding: '6px 15px', marginLeft: '10px' }}>Apply Now</button></Link>
      
    </div>
  </div>
</nav>

<div id="personal"></div>
    <section class="main-body">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h3>About Personal Loan</h3>
                        <div class="row">
                            <div class="col-md-8">
                                <p>A personal loan is usually of a smaller amount than a mortgage and used for personal purposes, instead of commercial/business needs. It includes loans for reasons like going on vacation, wedding, buying of new furniture and various other needs that arise for an individual.</p>
                                 
                                
                            </div>
                            <div class="col-md-4">
                                <img width="100%" src={img1} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div id="features"></div>
            <div class="row">
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h3>Features</h3>
                        <div class="row">
                            <div class="col-md-12">
                                

                                <div class="row">
                                    <div class="col-md-8">
                                       
                                        <ul class="listnone bullet bullet-check-circle-default">
                                            <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                                               Collateral-free loan
                                            </li>
                                            <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                                               No end-use restriction
                                            </li>
                                            <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                                               Repayment tenure up to 60 months, which may exceed on case to case basis
                                            </li>
                                            <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                                               Minimal documentation
                                            </li>
                                            <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                                               Quick disbursals
                                            </li>
                                        </ul>
                                        
                                        <h5>Interest Rate:- 10.5%*
</h5>
<span style={{color:'red'}}>We compare and offer best interest rate possible!</span>
                                    </div>

                                    <div class="col-md-4">
                                        <img width="80%" src={img2} alt=""/>
                                    </div>

                                </div>

                                
                            </div>
                          
                        </div>
                        <div id="eligible"></div>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="loan-lg-card">


                        <h3>Eligibility</h3>
                        <div class="row">
                            <div id="cfpl"></div>
                            <div class="col-md-8">
                                <ul class="listnone bullet bullet-check-circle-default">
                                    <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                                      <b> Age:</b> 18 - 60 years
                                    </li>
                                    <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                                       <b>Salary:</b> At least Rs. 15,000 per month for salaried customers
                                    </li>
                                    <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                                     <b>  Credit Score: </b>Preferably 750 and above as having higher credit scores improve chances of loan approval
                                    </li>
                                    <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                                      <b> Employment Stability:</b> At least 2 years with minimum 1 year of work experience in the same job for salaried
                                    </li>
                                    <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                                      <b>Employment Type: </b>Salaried employees working with reputed organizations, MNCs, Private and Public Limited Companies, Govt. organizations, PSUs and large enterprises.
                                    </li>
                                     <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                                     <b> Identity Proof: </b>Passport/PAN Card/ Voter’s ID/ Aadhaar Card/ Driving License
                                    </li>
                                     <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                                    <b>  Address Proof: </b>Passport/ Aadhaar Card/ Lease/ Property purchase Agreement/ Utility Bills (not more than 3 months old)/ Passport/ Driving License
                                    </li>
                                     <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                                    <b>  Income Proof For Salaried Individuals:</b> Salary Slips/ Bank Account Statement/ Form 16 
                                    </li>
                                </ul>


                            </div>
                            <div class="col-md-4">
                                <img width="100%" src={img3} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div id="types"></div>
                </div>
            </div>

            <div class="row">
                <div id="cfpl"></div>
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <div class="row">
                            <div class="col-md-8">
                                <h3>Personal Loan Types</h3>
                                  <ul class="listnone bullet bullet-check-circle-default">
                                   
                                    <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                                       <b> Instant Personal Loans </b>are approved instantly with minimal document requirements. Personal loans with instant disbursal are usually offered by banks and NBFCs to their select customers on the basis of their credit profiles.
                                    </li>
                                    <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                                     <b>Short-term Personal Loans</b> have short repayment period ranging from a few days to 12 months.
                                    </li>
                                    <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                                      <b>Pre-approved Personal Loans</b> are usually offered by banks and NBFCs to their existing customers on the basis of their credit history, income, employer's profile, etc.
                                    </li>
                                    <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                                     <b> Consumer Durable Loans</b> can be used to purchase any consumer durable items like smartphone, furniture, microwave, etc. The purchase amount gets divided into EMIs and can be repaid within the tenure decide.  products may or may not require a down payment or a processing fee.
                                    </li>
                                     <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                                     <b> Personal Loan Balance Transfer </b>facility allows borrowers to transfer their outstanding personal loan to a new lender from current lender for lower interest rates or better loan terms. However, transfer your outstanding personal loan only when the savings made through the transfer outweighs the cost of loan transfer.
                                    </li>
                                     <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                                   <b> Personal Loan Top Up</b> is offered to existing personal loan borrowers who need additional funds to meet their financial requirements. This loan facility is only offered to select existing personal loan borrowers having satisfactory loan repayment history and/or having completed a specified number of EMIs which differs bank to bank.
                                    </li>
                                     
                                </ul>


                                
                            </div>
                            <div class="col-md-4" style={{textAlign:'center'}}>

                                <img width="100%" src={img4} alt=""/>
                            </div>
                        </div>
                    </div>
                      <div id="help"></div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <div class="row">
                            <div class="col-md-8">

                                <h3>How we can help?</h3>

                                <ul class="listnone bullet bullet-check-circle-default">
                                    <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                                       	As one of the leading loan & finance Company in India. Bhumi Finance provides you with an unbiased platform to analyse and decide upon the available deals in the market.</li>
                                    <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                                        We offer a customized loan consulting experience to each customer.
                                    </li>
                                    <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                                        Our experienced loan consultants help you to understand which is the right loan for you
                                    </li>
                                    <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                                        We make sure that your documents are in order
                                    </li>
                                    <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                                        	We make the process smooth, efficient, and easy
                                    </li>
                                    <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                                        	We take care of any issues on the way
                                    </li>
                                </ul>

                            </div>
                            <div class="col-md-4">
                                <img width="100%" src={img3} alt=""/>
                            </div>

                        </div>
                    </div>
                      <div id="why"></div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <div class="row">
                            <div class="col-md-8">
                                <h3>Why BHUMI FINANCE</h3>

                                <ul class="listnone bullet bullet-check-circle-default">
                                    <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                                        Get best offers & deals on from our partners banks & NBFCs.
                                    </li>
                                    <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                                        Compare personal loan offers and services.
                                    </li>
                                    <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                                        	Apply with the best bank or NBFC.
                                    </li>
                                    <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                                        Lowest Rate of Interest in offering.
                                    </li>
                                    <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                                      	Hassle free process and easy documentation.
                                    </li>
                                    
                                    <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                                      	Free Document Pickup by Bhumi finance Team.
                                    </li>
                                    
                                    <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                                      Free expert guidance & advice from our dedicated team of experts.
                                    </li>
                                    
                                    
                                    <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                                      We are not an agent of any bank and you can apply with the bank of your choice.
                                    </li>
                                    
                                    <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                                      No Fees for services provided.
                                    </li>
                                    
                                    <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                                     We are here to help &assist you .We understand that it’s an important decision for you as you are in immediate need of money; and trust us we will ensure quick processing and disbursal of loan.
                                    </li>
                                    
                                </ul>
                            </div>
                            <div class="col-md-4" style={{textAlign:'center'}}>
                                <img width="100%" src={img4} alt=""/>
                            </div>
                        </div>
                    </div>
                      <div id="point"></div>
                </div>
            </div>




        <div class="row">
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <div class="row">
                            <div class="col-md-8">
                                <h3>Point To Be Considered Before Taking A Personal Loan</h3>

                                <ul class="listnone bullet bullet-check-circle-default">
                                    <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                                       	Share all the accurate information at time of filling up of application form. Bank will process your loan based the information you provide
                                    </li>
                                    <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                                       Processing fees are deducted from Loan amount and thus do not pay any fees in cash/cheque to anyone to process your loan sanctioning
                                    </li>
                                    <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                                       Compare Loan offers & deals but handover document to any one of the bank of your choice
                                    </li>
                                    
              
                                   
                                    
                                </ul>
                            </div>
                            <div class="col-md-4" style={{textAlign:'center'}}>
                                <img width="100%" src={img3} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>
<HelpYou/>
    </>
  )
}

export default PersonalLoan
