import React from 'react'

const WhatsApp = () => {
  return (
    <>
        <div className="whatsapp">
            <a  href="https://wa.me/919506010080" style={{textDecoration:'none'}}>
                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/5e/WhatsApp_icon.png/598px-WhatsApp_icon.png" alt=""  style={{width:'60px',height:'60px',borderRadius:'50px'}}/>
            </a>
        </div>
    </>
  )
}

export default WhatsApp
