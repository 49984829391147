import React from 'react'
import AboutCards from './aboutcards/AboutCards'
import Carousel from './Carousel'
import LoanProducts from './loanproducts/LoanProducts'
import LoanItems from './loans/LoanItems'
import ApplicationProcess from './applicationprocess/ApplicationProcess'
import WhyChoose from './whychoose/WhyChoose'
import HelpYou from './whychoose/HelpYou'
import Testimonial from './testimonial/Testimonial'
const Home = () => {
  return (
    <>
    <Carousel/>
    <LoanItems/>
    <AboutCards/>
    <LoanProducts/>
    <ApplicationProcess/>
    <WhyChoose/>
    <Testimonial/>
    <HelpYou/>
    </>
  )
}

export default Home
