import './basicstyle.css'
import img from './images/logo.jpeg'
import {Link} from 'react-router-dom'
const Footer=()=>{
    return(
        <>
        <footer style={{backgroundColor:'black',padding:'50px 0',position:'relative'}}>
    
    
    
    
    <div class="container" >
      <div class="row">

        <div class="col-md-3 col-sm-6 col-12">
          <h3>About Us</h3>
          <div class="mt-25">
            <img src={img} alt="footer-logo" style={{width:'70px',height:'50px'}}/>
            <p style={{color:'white'}} class="mt-25">RIDDHISIDDHI FINANCE is a one of its kind financial services distribution firm catering to discerning individuals and corporate clientele at PAN INDIA level.
            </p>
            <button className='btn btn-primary'><Link to="/aboutus" style={{color:'white',fontWeight:600}}>Read More</Link></button>
            <div class="footer-social-icons mt-25">
          <ul>
            <li><a href="#"><i class="fa fa-facebook"></i></a></li>
            <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
            <li><a href="#"><i class="fa fa-instagram"></i></a></li>
            <li><a href="#"><i class="fa fa-twitter"></i></a></li>
            <li><a href="#"><i class="fa fa-youtube"></i></a></li>
          </ul>
            </div>
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
          <h3>Quick Links</h3>
          <ul class="footer-list">
            <li><Link to="/aboutus">About Us</Link></li>
            <li><Link to="/aboutus">Our Blogs</Link></li>
            <li><Link to="/leadershipteam">Leadership Team</Link></li>
            <li><Link to="/companybranch">Company Branches</Link></li>
            <li><Link to="/contact">Contact Us</Link></li>
            <li><Link to="/emicalc">Calculate Your EMI</Link></li>
            <li><Link to="/aboutus">Terms And Condition</Link></li>
            <li><Link to="/aboutus">Privacy Policy</Link></li>
          </ul>
        </div>

        <div class="col-md-3 col-sm-6 col-12">
          <h3>Our Services</h3>
          <ul class="footer-list">
            <li><Link to="/personalloan">Personal Loan</Link></li>
            <li><Link to="/balancetransfer">Personal Loan Transfer</Link></li>
            <li><Link to="/contact">Instant Loan Approval</Link></li>
            <li><Link to="/loanagainstproperty">Loan Against Property</Link></li>

              <li>
                <Link to="/refinancepurchase">Used Car Loan</Link>
              </li>
              <li>
                <Link to="/homeloan">Home Loan</Link>
              </li>
            <li><Link to="/goldloan">Gold Loan</Link></li>
          </ul>
        </div>

        <div class="col-md-3 col-sm-6 col-12">
          <h3>Get In Touch</h3>
          <ul class="footer-list">
            <li style={{color:'gray'}}><i class="fa fa-map-marker" aria-hidden="true"></i>OM 1/9 SHASTRI NAGAR SIGRA VARANASI-221010</li>
  
  <li style={{color:"gray"}}><i class="fa fa-envelope" aria-hidden="true"></i><br/>office@<br/>riddhisiddhifinances.com</li>
  
  
  
           
          </ul>
        </div>


      </div>
  
  
    </div>
    
    
    
    
    
    
    
       
    <div class="container footbottom footer-bar" >
      <div class="row">
        <div class="col-md-3 col-sm-6 col-12">
          <h4 class="vt-15">Home Loan Locations</h4>
          <div class="vt-15">
              
           <ul class="footer-list-bottom">
               
               
                    <li><a href="#">Home Loan in Azamgarh</a></li>
                <li><a href="#">Home Loan in Ballia</a></li>
                  <li><a href="#">Home Loan in Basti</a></li>
                   <li><a href="#">Home Loan in Chandauli</a></li>
                    <li><a href="#">Home Loan in Deoria</a></li>
                     <li><a href="#">Home Loan in Ghazipur</a></li>
                      <li><a href="#">Home Loan in Gorakhpur</a></li>
                       <li><a href="#">Home Loan in Jaunpur</a></li>
                        <li><a href="#">Home Loan in Kushinagar</a></li>
                         <li><a href="#">Home Loan in Mau</a></li>
                         <li><a href="#">Home Loan in Mirzapur</a></li>
                          <li><a href="#">Home Loan in Sant Kabir Nagar</a></li>
                           <li><a href="#">Home Loan in Siddharth Nagar</a></li>
                           <li><a href="#">Home Loan in Sonbhadra</a></li>
                           <li><a href="#">Home Loan in Varanasi</a></li>
      
          </ul>
          
          
          </div>
        </div>
        <div class="col-md-3 col-sm-6 col-12">
            
              <h4 class="vt-15">Personal Loan Locations</h4>
         
               <ul class="footer-list-bottom">
                   
                   
             <li><a href="#">Personal Loan in Azamgarh</a></li>
                <li><a href="#">Personal Loan in Ballia</a></li>
                  <li><a href="#">Personal Loan in Basti</a></li>
                   <li><a href="#">Personal Loan in Chandauli</a></li>
                    <li><a href="#">Personal Loan in Deoria</a></li>
                     <li><a href="#">Personal Loan in Ghazipur</a></li>
                      <li><a href="#">Personal Loan in Gorakhpur</a></li>
                       <li><a href="#">Personal Loan in Jaunpur</a></li>
                        <li><a href="#">Personal Loan in Kushinagar</a></li>
                         <li><a href="#">Personal Loan in Mau</a></li>
                         <li><a href="#">Personal Loan in Mirzapur</a></li>
                          <li><a href="#">Personal Loan in Sant Kabir Nagar</a></li>
                           <li><a href="#">Personal Loan in Siddharth Nagar</a></li>
                           <li><a href="#">Personal Loan in Sonbhadra</a></li>
                           <li><a href="#">Personal Loan in Varanasi</a></li>
                           
                           <li><a  style={{fontSize:"15px"}} href="#">Personal Loan Faq's</a></li> 
        
          </ul>
        </div>

        <div class="col-md-3 col-sm-6 col-12">
          
          <h4 class="vt-15">Business Loan Locations</h4>
         
               <ul class="footer-list-bottom">
                   
                
             <li><a href="#">Business Loan in Azamgarh</a></li>
                <li><a href="#">Business Loan in Ballia</a></li>
                  <li><a href="#">Business Loan in Basti</a></li>
                   <li><a href="#">Business Loan in Chandauli</a></li>
                    <li><a href="#">Business Loan in Deoria</a></li>
                     <li><a href="#">Business Loan in Ghazipur</a></li>
                      <li><a href="#">Business Loan in Gorakhpur</a></li>
                       <li><a href="#">Business Loan in Jaunpur</a></li>
                        <li><a href="#">Business Loan in Kushinagar</a></li>
                         <li><a href="#">Business Loan in Mau</a></li>
                         <li><a href="#">Business Loan in Mirzapur</a></li>
                          <li><a href="#">Business Loan in Sant Kabir Nagar</a></li>
                           <li><a href="#">Business Loan in Siddharth Nagar</a></li>
                           <li><a href="#">Business Loan in Sonbhadra</a></li>
                           <li><a href="#">Business Loan in Varanasi</a></li>
                           
                          
        
          </ul>
          
              
        </div>
      </div>
  
      <div class="footer-bar">
        <p style={{textAlign:"center",color:'white'}}><span class="primary-color">RiddhiSiddhi Finserv</span> © 2021. All Rights Reserved. | Powered by<a href="https://kinminds.com/" target="_blank" style={{color: "red"}}> Kin Minds Technologies</a></p>
      </div>
    </div>
   

    
  </footer>
        </>
    )
}
export default Footer