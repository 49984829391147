import img from '../../images/associate.jpeg'
import './businesspartner.css'
import img1 from '../../images/form1.jpeg'
import img2 from '../../images/joinasso.jpeg'
import React,{useState} from 'react'
import { useToast } from '@chakra-ui/react'
import {useNavigate} from 'react-router-dom'
import axios from 'axios'
const JoinAssociate = () => {
    const toast = useToast()
    const navigate =useNavigate()

  const [firstname,setFirstname] = useState("")
  const [lastname,setLastname] = useState("")
  const [email,setEmail] = useState("")
  const [mobile,setMobile] = useState("")
  const [occupation,setOccupation] = useState("")
  const [degree,setDegree] = useState("")
  const [postaladdress,setPostaladdress] = useState("")
  const [state,setState] = useState("")
  const [city,setCity] = useState("")
  const [pincode,setPincode] = useState("")


  const firstnameChange=(e)=>{
      setFirstname(e.target.value)
  }
  const lastnameChange=(e)=>{
      setLastname(e.target.value)
  }
  const emailChange=(e)=>{
      setEmail(e.target.value)
  }
  const mobileChange=(e)=>{
      setMobile(e.target.value)
  }
  const occupationChange=(e)=>{
      setOccupation(e.target.value)
  }
  const degreeChange=(e)=>{
      setDegree(e.target.value)
  }
  const postaladdressChange=(e)=>{
      setPostaladdress(e.target.value)
  }
  const stateChange=(e)=>{
      setState(e.target.value)
  }
  const cityChange=(e)=>{
      setCity(e.target.value)
  }
  const pincodeChange=(e)=>{
      setPincode(e.target.value)
  }

  const formSubmit=async(e)=>{
    e.preventDefault()
    if(!firstname || !lastname || !email || !mobile || !occupation || !degree || !postaladdress || !state || !city || !pincode){
        toast({
            title: 'Failed.',
            description: "Please Fill all the details.",
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
          return;
    }
    try{
        const config={
            headers:{
                "Content-type":"application/json"
            }
        }
        const {data} =await axios.post("/api/user/joinassociate",{firstname,lastname,email,mobile,occupation,degree,postaladdress,state,city,pincode},config)
            toast({
                title: 'Submitted Successfully.',
                description: "Our team will contact you soon.",
                status: 'success',
                duration: 5000,
                isClosable: true,
              })
              localStorage.setItem("userData",JSON.stringify(data))
              navigate("/")
       
    }catch(err){
        toast({
            title: 'Failed.',
            description: err.message,
            status: 'error',
            duration: 5000,
            isClosable: true,
          })
    }
}
    return (
        <>
            <div id="carouselExampleCaptions" class="carousel slide">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src={img} class="d-block w-100" alt="..." style={{ height: '45vh' }} />
                        <div class="carousel-caption d-md-block">
                            <h1 style={{ color: 'white' }}>Associate Program</h1>
                            <p style={{ color: 'white' }}>Home &nbsp; &#10095; &nbsp; Associate Program</p>
                        </div>
                    </div>
                </div>

            </div>
            <div className="heading">
                <h1>Increase your earning by become RiddhiSiddhi Finance Associate</h1>
            </div>
            <div class="section-block">
                <div class="container">
                    <div class="row mt-50">

                        <div class="col-md-6 col-sm-6 col-12">

                            <h5>How to become RiddhiSiddhi Finance Associate</h5><br />


                            <ul class="listnone bullet bullet-check-circle-default">
                                <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>Fill the form given below.</li>
                                <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>Receive  a call from us for guidance.</li>
                                <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>Complete the training process.</li>
                                <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>Complete other formalities also.</li>
                                <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>Become a Bhumi Finance Associate.</li>
                            </ul>


                        </div>

                        <div class="col-md-6 col-sm-6 col-12">

                            <h5>Advantages of RiddhiSiddhi Finance Associate</h5><br />

                            <ul class="listnone bullet bullet-check-circle-default">
                                <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>It gives extra income along with your current business/job.</li>
                                <li><span class="material-symbols-outlined">
                                    check_circle
                                </span> Little investment needed.</li>
                                <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>You can manage this easily as a part time business.</li>
                                <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>Easy procedure to enrol </li>

                            </ul>



                        </div>
                    </div>

                </div>
            </div>
<div className="heading">
    <h1>How Does It Works</h1>
</div>
            <div class="section-block my-3">
  <div class="container">

    <div class="row mt-50">
         
      <div class="col-md-6 col-sm-6 col-12">
      
         

<h6 style={{color:"#d9482b",fontSize:"18px"}}>Identification of client</h6>
<p>Generate a lead by Identifying a loan-seeker, categorize client needs & requirement regarding loan.</p>  <br/> 


<h6 style={{color:"#d9482b",fontSize:"18px"}}>Eligibility Check & Verification</h6>
<p>Check facts and parameters of client by gathering required information. </p>  <br/> 


<h6 style={{color:"#d9482b",fontSize:"18px"}}>Share the lead with us</h6>
<p>Share the details and requirement of the client with us for further procedure.</p>  <br/> 




<h6 style={{color:"#d9482b",fontSize:"18px"}}>Loan Disbursal</h6>
<p>Get the loan disbursed for the client by his preferred lending institution.</p>  <br/> 



<h6 style={{color:"#d9482b",fontSize:"18px"}}>Instant Commission Payout</h6>
<p>Get your commission within 7 days of loan disbursal.
                                                                                                                <br/> *(T&C Apply)</p>

         
      </div>
      
      
      
       <div class="col-md-6 col-sm-6 col-12" style={{textAlign:"right"}}>
        <img src={img2} alt="image" width="80%" />
        
      </div>

  
    </div>

  </div>
</div>

            <div className="heading">
                <h1>Apply Now</h1>
            </div>
            <div class="section-block">
  <div class="container my-3">
    <div class="row mt-50">
      
      <div class="col-md-6 col-sm-6 col-12">
      <form class="contact-form" method="post">
 
 <div class="row">
   <div class="col-md-6 col-sm-6 col-12">
     <input type="text" name="first_name" className='form-control' value={firstname} onChange={firstnameChange} placeholder="First Name" required/>
           </div>
   <div class="col-md-6 col-sm-6 col-12">
     <input type="text" name="last_name" className='form-control' value={lastname} onChange={lastnameChange} placeholder="Last Name" required/>
           </div>

    <div class="col-md-6 col-sm-6 col-12">
     <input type="email" name="email" className='form-control' value={email} onChange={emailChange} placeholder="E-mail" required/>
           </div>

    <div class="col-md-6 col-sm-6 col-12">
     <input type="number" name="mobile" className='form-control' value={mobile} onChange={mobileChange} placeholder="Mobile" required/>
           </div>
   
   
   
   
   
   
   <div class="col-md-6 col-sm-6 col-12">
     <select id= "profession" value={occupation} onChange={occupationChange} name="profession" className='form-select' required>
       <option value="">Occupation</option>
       
      
       <option value="Builder">Tax Consultant</option>
       <option value="Chartered Accountant">Financial Worker</option>
       <option value="DSA/DST">CA</option>
       <option value="Ex-banker">Banker</option>
       <option value="Financial Analyst">Loan Agent</option>
       <option value="Financial Consultant">Financial Consultant</option>
       <option value="Freelancer">Associate</option>
       
       <option value="Others">Others</option>
     </select>
                   </div>
   
   
   <div class="col-md-6 col-sm-6 col-12">
     <select id= "education" value={degree} onChange={degreeChange} className='form-select' name="education" required>
       <option value="">Degree</option>
       <option value="10th">10th</option>
       <option value="12th">12th</option>
       <option value="diploma">diploma</option>
       <option value="graduate">graduate</option>
       <option value="postgraduate">postgraduate</option>
       <option value="other">other</option>
     </select>
                   </div>
   
   <div class="col-md-6 col-sm-6 col-12">
     <input type="text" name="postal_address" className='form-control' value={postaladdress} onChange={postaladdressChange} placeholder="Postal Address" required/>
                   </div>
   
                   <div class="col-md-6 col-sm-6 col-12">
     <input type="text" name="state" value={state} onChange={stateChange} className='form-control' placeholder="State" required/>
                   </div>
                   <div class="col-md-6 col-sm-6 col-12">
     <input type="text" name="city" value={city} onChange={cityChange} className='form-control' placeholder="City" required/>
                   </div>
   

   
   
   
   <div class="col-md-6 col-sm-6 col-12">
     <input type="number" name="pincode" className='form-control' value={pincode} onChange={pincodeChange} placeholder="Pincode" required/>
                 </div>
   <div class="g-recaptcha" id="rcaptcha"  data-sitekey="6LeJ4eQfAAAAAMfapz_pEBGhXp3n7ETcqTJYFowK"></div>
<span id="captcha" style={{color:"red"}} ></span>
   <div class="col-md-12">
       <p>Our team will connect with you shortly.</p>
   </div>
   <div class="col-md-12">
     <div class="center-holder">
       <button type="submit" onClick={formSubmit}>Send</button>
     </div>
   </div>
 </div>
</form>
      </div>

      <div class="col-md-6 col-sm-6 col-12">
        <img src={img1} alt="image" style={{width:"80%"}}/>
        
      </div>
    </div>

  </div>
</div>
        </>
    )
}
export default JoinAssociate;