import './App.css';
import Header from './components/Header'
import Footer from './components/Footer';
import BusinessPartner from './components/pages/businesspartner/BusinessPartner';
import {BrowserRouter as Router,Routes,Route,Link} from 'react-router-dom'
import JoinAssociate from './components/pages/businesspartner/JoinAssociate';
import GetAppointment from './components/pages/businesspartner/GetAppointment';
import Contact from './components/pages/contact/Contact';
import Home from './components/pages/home/Home';
import AboutUs from './components/pages/about/AboutUs'
import LeadershipTeam from './components/pages/about/LeadershipTeam';
import CompanyBranch from './components/pages/about/CompanyBranch';
import PersonalLoan from './components/pages/personalloan/PersonalLoan';
import HomeLoan from './components/pages/personalloan/HomeLoan';
import BusinessLoan from './components/pages/personalloan/BusinessLoan'
import LoanAgainstCar from './components/pages/carrefinance/LoanAgainstCar';
import BalanceTransfer from './components/pages/carrefinance/BalanceTransfer';
import RefinancePurchase from './components/pages/carrefinance/RefinancePurchase';
import GoldLoan from './components/pages/otherloan/GoldLoan'
import LoanAgainstProperty from './components/pages/otherloan/LoanAgainstProperty';
import CarLoan from './components/pages/otherloan/CarLoan';
import Reinsurance from './components/pages/insurance/Reinsurance';
import LifeInsurance from './components/pages/insurance/LifeInsurance';
import GroupInsurance from './components/pages/insurance/GroupInsurance';
import HealthInsurance from './components/pages/insurance/HealthInsurance';
import GeneralInsurance from './components/pages/insurance/GeneralInsurance';
import Call from './components/Call';
import WhatsApp from './components/WhatsApp'

function App() {
  return (
    <>
    <Router>
     <div id="" class="hidden-sm-down">
  <div class="container">
    <div class="row">
      <div class="col-md-12 col-12 vnote">
          
          
          
          
      <marquee><p class="pm"> Disclaimer: We do not ask for any kind of fees/charges/commission for processing the loan file or any other services. Please inform on 9506010080 for any discrepancy      &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; डिस्क्लेमर- RIDDHISIDDHI FINANCES किसी भी तरह की फाइनेंसियल/लोन या अन्य सर्विसेज के लिए कोई फीस/कमीशन/चार्जेज नहीं लेती है| धोखाधड़ी की आशंका होने प(+91) 9506010080 पे संपर्क करें| </p>
    </marquee>  
    


          
          </div>
          
          </div>
          </div>
          </div>
    <div id="top-bar" class="hidden-sm-down" style={{backgroundColor:'gray'}}>
  <div class="">
    <div class="row myrow">
      <div class="col-md-4 col-12 top-header-mrgn head">
        <div class="top-bar-welcome">
        </div>
        <div class="top-bar-info">
          <ul>
            
            <li><i class="fa fa-phone"></i><a style={{color:"white",fontSize:'13px'}} href="#">(+91) ) 9506010080</a></li>
            <li><i class="fa fa-envelope"></i><a style={{color:"white",fontSize:'13px'}} href="mailto:office@riddhisiddhifinances.com">office@riddhisiddhifinances.com</a>
            </li>
                
            <li class="lan">  <div id="google_translate_element"></div></li>
                
                
          </ul>
        </div>
      </div>
      <div class="col-md-2 col-12">
        <ul class="social-icons hidden-md-down">
          <li><a href="#" ><i class="fa fa-facebook"></i></a></li>
          <li><a href="#"><i class="fa fa-linkedin"></i></a></li>
          <li><a href="#"><i class="fa fa-instagram"></i></a></li>
          <li><a href="#"><i class="fa fa-twitter"></i></a></li>
          <li><a href="#"><i class="fa fa-youtube"></i></a></li>
          
        </ul>
       
      </div>
      
      
      
       <div class="col-md-2 col-12 ">
       <Link class="button" to="/businesspartner">Business Partner</Link> 
     
      </div>
      
      
       <div class="col-md-2 col-12">
       <Link class="button" to="/joinassociate">Join Associate Program</Link> 
      </div>
      
   
      
       <div class="col-md-2 col-12">
       <Link class="button" to="/getappointment">Get Appointment</Link> 
     
      </div>
      
     
      
      
      
    </div>
  </div>
</div>
      <Header/>
      <Call/>
      <WhatsApp/>
      <Routes>
        <Route path="/businesspartner" element={<BusinessPartner/>}/>
        <Route path="/joinassociate" element={<JoinAssociate/>}/>
        <Route path="/getappointment" element={<GetAppointment/>}/>
        <Route path="/contact" element={<Contact/>}/>
        <Route path="/" element={<Home/>}/>
        <Route path="/aboutus" element={<AboutUs/>}/>
        <Route path="/leadershipteam" element={<LeadershipTeam/>}/>
        <Route path="/companybranch" element={<CompanyBranch/>}/>
        <Route path="/personalloan" element={<PersonalLoan/>}/>
        <Route path="/homeloan" element={<HomeLoan/>}/>
        <Route path="/businessloan" element={<BusinessLoan/>}/>
        <Route path="/loanagainstcar" element={<LoanAgainstCar/>}/>
        <Route path="/balancetransfer" element={<BalanceTransfer/>}/>
        <Route path="/refinancepurchase" element={<RefinancePurchase/>}/>
        <Route path="/goldloan" element={<GoldLoan/>}/>
        <Route path="/loanagainstproperty" element={<LoanAgainstProperty/>}/>
        <Route path="/carloan" element={<CarLoan/>}/>
        <Route path="/reinsurance" element={<Reinsurance/>}/>
        <Route path="/lifeinsurance" element={<LifeInsurance/>}/>
        <Route path="/groupinsurance" element={<GroupInsurance/>}/>
        <Route path="/healthinsurance" element={<HealthInsurance/>}/>
        <Route path="/generalinsurance" element={<GeneralInsurance/>}/>




















        </Routes>
      <Footer/>
      
      </Router>
    </>
  );
}

export default App;