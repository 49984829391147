import React from 'react'
import img from '../../images/healthinsurance.jpeg'
import './insurance.css'
import { Link } from 'react-router-dom'
import HelpYou from '../home/whychoose/HelpYou'
import '../personalloan/personalloan.css'
const HealthInsurance = () => {
    return (
        <>
            <div id="carouselExampleCaptions" class="carousel slide">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src={img} class="d-block w-100" alt="..." style={{ height: '45vh' }} />
                        <div class="carousel-caption d-md-block">
                            <h1 style={{ color: 'black' }}>Health Insurance</h1>
                            <p style={{ color: 'black' }}>Home &nbsp; &#10095; &nbsp; Health Insurance</p>
                        </div>
                    </div>
                </div>

            </div>
            <nav class="navbar navbar-dark bg-dark navbar-expand-lg navBar">
                <div class="container-fluid">
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                    <a class="nav-link" href="#Recommended">Recommended Plans</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#Other">Other Plans</a>
                </li>
                        </ul>
                        <a href="https://wa.me/919506010080"><button class="btn btn-outline-wtsap my-2 my-sm-0" style={{ color: 'white', backgroundColor: 'green', borderRadius: '5px', padding: '6px 15px', marginLeft: '10px' }}><i class="fa fa-whatsapp"></i>&nbsp;Whatsapp</button></a>
                        <Link to="/getappointment"> <button class="btn btn-outline-apply my-2 my-sm-0" type="submit" style={{ color: 'black', backgroundColor: 'white', borderRadius: '5px', padding: '6px 15px', marginLeft: '10px' }}>Apply Now</button></Link>

                    </div>
                </div>
            </nav>
            <div id="Life"></div>
            <div id="Recommended"></div>
    <section class="main-body">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h3>Recommended Plans</h3>
                        <div class="row">
                            <div class="col-md-12">
                               <div class="row mt-50">
      <div class="col-md-4 col-sm-4 col-12">
        <div class="feature-box-long">
          <i class="icon-user"></i>
          <h3>Senior Citizen</h3>
          <p style={{fontSize:"12px"}}>SENIOR CITIZENS RED CARPET HEALTH INSURANCE POLICY</p>
          <p>The optimum policy for seniors over 60 years of age with several advantages.</p>
        </div>
      </div>

      <div class="col-md-4 col-sm-4 col-12">
        <div class="feature-box-long">
          <i class="icon-group"></i>
          <h3>Family Plans</h3>
          <p style={{fontSize:"12px"}}>COMPREHENSIVE INSURANCE POLICY</p>
          <p>Complete healthcare protection for your entire family on individual and floater basis.</p>
        </div>
      </div>

      <div class="col-md-4 col-sm-4 col-12">
        <div class="feature-box-long" style={{paddingBottom:"60px"}}>
          <i class="icon-shield2  "></i>
          <h3>Top-Up</h3>
          <p style={{fontSize:"12px"}}>SUPER SURPLUS INSURANCE POLICY</p><br/>
          <p>Surplus protection at affordable pricing.</p>
        </div>
      </div>
    </div> 
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div id="Other"></div>
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h3>Other Plans</h3>
                        <div class="row">
                            <div class="col-md-12">
                               <div class="row mt-50">
      <div class="col-md-4 col-sm-4 col-12">
        <div class="feature-box-long">
          <i class="icon-team2"></i>
          <h3>Family Health Optima Insurnace Plan</h3>
          <p>Most Popular insurance policy with your growing family, on floater basis.</p>
        </div>
      </div>

      <div class="col-md-4 col-sm-4 col-12">
        <div class="feature-box-long">
          <i class="icon-job2"></i>
          <h3>Medi Calssic Insurance Policy (Individual)</h3>
          <p>A favorite insurance plan with family package, health benefits and bonus options.</p>
        </div>
      </div>

      <div class="col-md-4 col-sm-4 col-12">
        <div class="feature-box-long">
          <i class="icon-man"></i>
          <h3>Health Gain Insurance Policy</h3>
          <p>Maximum benefits at affordable premiums, available for individuals and family.</p>
        </div>
      </div>
    </div>
                               
                               <div class="row mt-50">
      <div class="col-md-4 col-sm-4 col-12">
        <div class="feature-box-long">
          <i class="icon-team"></i>
          <h3>Diabetes Safe Insurnace Policy</h3>
          <p>A specialised healthcare policy for diabetic patients.</p>
        </div>
      </div>

      <div class="col-md-4 col-sm-4 col-12">
        <div class="feature-box-long">
          <i class="icon-sprout"></i>
          <h3>Cardic Care Insurance Policy</h3>
          <p>Special healthcare insurace policy for health patients with multiple benefits. </p>
        </div>
      </div>

      <div class="col-md-4 col-sm-4 col-12">
        <div class="feature-box-long">
          <i class="icon-networking"></i>
          <h3>Cancer Care Gold(Pilot Product)</h3>
          <p>First ever health insurance product for persons diagnosed with cancer.</p>
        </div>
      </div>
    </div>
                               
                               
                               
                               <div class="row mt-50">
      <div class="col-md-4 col-sm-4 col-12">
        <div class="feature-box-long">
          <i class="icon-podium"></i>
          <h3>Care Micro Insurance Policy</h3>
          <p>A complete care policy for you and your family.</p>
        </div>
      </div>

      <div class="col-md-4 col-sm-4 col-12">
        <div class="feature-box-long">
          <i class="icon-job2"></i>
          <h3>Criticare Plus Insurance Policy</h3>
          <p>Protection policy with critical benefits and special advantages.</p>
        </div>
      </div>

      <div class="col-md-4 col-sm-4 col-12">
        <div class="feature-box-long">
          <i class="icon-group"></i>
          <h3>Family Delite Insurance Policy</h3>
          <p>A health insurance plan meant for families with multiple benefits</p>
        </div>
      </div>
    </div>
                               
                               
                               
                               <div class="row mt-50">
      <div class="col-md-4 col-sm-4 col-12">
        <div class="feature-box-long">
          <i class="icon-care"></i>
          <h3>Special Care</h3>
          <p>A unique policy for children diagnosed with austism spectrum disorder.</p>
        </div>
      </div>

      <div class="col-md-4 col-sm-4 col-12">
        <div class="feature-box-long">
          <i class="icon-sprout"></i>
          <h3>Micro Rural And Farmers Care</h3>
          <p>The Bhumi micro rural and farmers care caters to the need of individuals and their families in rural area.</p>
        </div>
      </div>

      <div class="col-md-4 col-sm-4 col-12">
        <div class="feature-box-long">
          <i class="icon-bank"></i>
          <h3>Hospital Cash Insurance Policy</h3>
          <p>Bhumi hospital cash insurance policy provides additional protection and tak care of your incidental .</p>
        </div>
      </div>
    </div>
                               
                               
                               
                               <div class="row mt-50">
      <div class="col-md-4 col-sm-4 col-12">
        <div class="feature-box-long">
          <i class="icon-sprout"></i>
          <h3>Outpatient Care Insurnace Plan</h3>
          <p>We cover your out patient care. Your recover easy and hassle-free.</p>
        </div>
      </div>

      <div class="col-md-4 col-sm-4 col-12">
        <div class="feature-box-long">
          <i class="icon-user-1"></i>
          <h3>Insurance Policy</h3>
          <p>Buy young bhumi when you are young. Get ceaseless protection and cheer lifelong.</p>
        </div>
      </div>

      <div class="col-md-4 col-sm-4 col-12">
        <div class="feature-box-long">
          <i class="icon-humanpictos"></i>
          <h3>BNovel Coronavirus (nCoV) (COVID-19) Insurance Policy (Pilot product)</h3>
          <p>An affordable care policy for you and your loved ones in the event of covid-19 (novel coronavirus).</p>
        </div>
      </div>
    </div>
                               
                               
                               <div class="row mt-50">
      <div class="col-md-4 col-sm-4 col-12">
        <div class="feature-box-long">
          <i class="icon-team"></i>
          <h3>Aarogya Sanjeevani Policy</h3>
          <p>A simple policy loaded with essential benefits.</p>
        </div>
      </div>

      <div class="col-md-4 col-sm-4 col-12">
        <div class="feature-box-long">
          <i class="icon-care"></i>
          <h3>Corona Rakshak Policy</h3>
          <p>Bosot your immunity by adapting to healthy habits. Protect your saving from covid-19 with corona.</p>
        </div>
      </div>

      <div class="col-md-4 col-sm-4 col-12">
        <div class="feature-box-long">
          <i class="icon-shield2"></i>
          <h3>Corona Kavach Policy</h3>
          <p>Shield yourself against the invisible threat.</p>
        </div>
      </div>
    </div>
                               
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            


        </div>
    </section>

            <HelpYou />
        </>
    )
}

export default HealthInsurance
