import React from 'react'
import img from '../../images/homeloan.jpeg'
import img1 from '../../images/pl1.jpeg'
import img2 from '../../images/pl2.jpeg'
import HelpYou from '../home/whychoose/HelpYou'
import { Link } from 'react-router-dom'
import './personalloan.css'
const HomeLoan = () => {
  return (
    <>
     <div id="carouselExampleCaptions" class="carousel slide">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src={img} class="d-block w-100" alt="..." style={{ height: '45vh' }} />
                        <div class="carousel-caption d-md-block">
                            <h1 style={{ color: 'white' }}>Home Loan</h1>
                            <p style={{ color: 'white' }}>Home &nbsp; &#10095; &nbsp; Home Loan</p>
                        </div>
                    </div>
                </div>

            </div>
            <nav class="navbar navbar-dark bg-dark navbar-expand-lg navBar">
  <div class="container-fluid">
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
      <li class="nav-item">
                    <a class="nav-link" href="#home">Home Loan</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#eli">Eligibility Criteria</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#document">Documents Required</a>
                </li>
                <li className='nav-item'>
                <Link class="nav-link" to="/emicalc">Calculate your Home Loan EMI</Link>
                </li>
                <li className='nav-item'>
                    <Link class="nav-link" to="/contact">Contact Us</Link>
                </li>
      </ul>
      <a href="https://wa.me/919506010080"><button class="btn btn-outline-wtsap my-2 my-sm-0" style={{color:'white',backgroundColor:'green',borderRadius:'5px',padding:'6px 15px',marginLeft:'10px'}}><i class="fa fa-whatsapp"></i>&nbsp;Whatsapp</button></a>
      <Link to="/getappointment"> <button class="btn btn-outline-apply my-2 my-sm-0" type="submit" style={{ color: 'black', backgroundColor: 'white', borderRadius: '5px', padding: '6px 15px', marginLeft: '10px' }}>Apply Now</button></Link>
      
    </div>
  </div>
</nav>

<div id="home"></div>
    <section class="main-body">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h3>About Home Loan</h3>
                        <div class="row">
                            <div class="col-md-8">
                                <p>Home loans are given to individuals who are interested in buying/constructing houses. It is supported by banks or financial institutions who loan a certain amount, based on the need of the individual(s). Loans can be applied for purchase, construction or improvement of homes.</p>
                                <p>Use our home loan EMI calculator  to calculate the loan EMI . The online loan calculator gives results based on principal amount, interest rate and tenure. The loan calculator also displays total interest payable, total principal payable and amortization schedule to help you understand your home loan repayment better.</p>
                               
                               
                               <h4>Home loan interest rate - 8.10%*
</h4>
We compare and offer best interest rate possible!
                            </div>
                            <div class="col-md-4">
                                <img width="100%" src={img1} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div id="eli"></div>
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h3>Eligibility Criteria:</h3>
                        <div class="row">
                            <div class="col-md-12">
                                
                                <p><strong>Home loan eligibility differs across lending institutions and loan schemes. However, a common set of housing loan eligibility criteria is given below:</strong></p>
                    <ul class="listnone bullet bullet-check-circle-default">
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                      <b>Nationality:</b> Indian Residents, Non-Resident Indians (NRIs), and Persons of Indian Origin (PIOs)
                    </li>
                      <li style={{color:"#404040"}} ><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                      <b> Credit Score:</b> Preferably 750 and above
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                     <b>   Age Limit:</b> 18 - 70 years
                      </li> 
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                   <b>   Work Experience:</b> At least 2 years (for salaried)
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                    <b>  Business Continuity: </b>At least 3 years (for self-employed)
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                   <b> Minimum Salary: </b>At least Rs. 25,000 per month (varies across lenders & locations)
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                    check_circle
                                </span>
               <b> Loan Amount:</b> Up to 90% of property value 
 </li>
                      
                       <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                    check_circle
                                </span>
              <b>Home Purchase Loan </b>is to take a loan to buya ready-to-move-in propertieslike row house, flats etc, under-construction properties and pre-owned homes/resale properties.  

</li>

    <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                    check_circle
                                </span>
              <b>Composite Loan</b> can be opted to buy a plot and building a house on it. The loan amount comes in slots depending on the stages of construction. The first disbursement in composite loan is made towards plot purchase. Subsequent payments/disbursements depend on the stages of construction of the house.

</li>

<li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                    check_circle
                                </span>

           <b>Home Construction Loan </b>is offered to individuals for house construction if you own a plot already. The disbursements depend on the stages of construction of the house.

</li>
<li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                    check_circle
                                </span>
           <b>Home Renovation/ Improvement Loan</b> is for meeting home renovation costs of an existing house. The interest rate for a home renovation/improvement loan and a regular home loan are usually same.

</li>
<li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                    check_circle
                                </span>
           <b>Home Extension Loan </b>is for those requiring funds to extend or add more space by adding more floors to their existing house. 

</li>

<li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                    check_circle
                                </span>
          <b>Bridge Loan </b>is a short-term home loan if you are planning to buy a new house with the sale proceeds of your existing house. 

</li>


<li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                    check_circle
                                </span>
           <b>Interest Saver Loan</b> is a home loan overdraft wherein the borrowers’ home loan account is linked toborrowers bank account. Any amount deposited in the bank account over and above the EMI is considered as a prepayment towards the loan, thus, saving on the interest amount.

</li>


<li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                    check_circle
                                </span>
          <b>Step Up Loan </b>allows borrowers to pay lower EMIs during the initial years of the loan tenure and have the provision of increasing the EMI amount over time 

</li>



                    </ul>
                    
                            </div>
                           
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="row">
                <div id="document"></div>
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h3>Documents Required</h3>
                        <div class="row">
                            <div class="col-md-8">
                                
                        
                                <ul class="listnone bullet bullet-check-circle-default">
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                    <b>  Proof of Residence:</b> Copy of anyone (Bank Passbook, Voter’s ID, Ration Card. Passport, Utility Bills (Telephone Bill, Electricity Bill, Water Bill, Gas Bill) and LIC Policy Receipt
                    </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                    <b> Proof of Identity: </b>Copy of any one (PAN Card, Passport, Aadhaar Card, Voter’s ID Card, and Driving License)
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                    <b> Proof of Age: </b>Copy of any one (Aadhaar Card, PAN Card, Passport, Birth Certificate, 10th Class Mark-sheet, Bank Passbook, and Driving License)
                      </li> 
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                       <b> Proof of Income for Salaried: </b>Copy of Form 16, latest payslips, IT returns (ITR) of past 3 years, and investment proofs (if any) Proof of Income for Self Employed: Details of ITR of last 3 years, Balance Sheet and Profit & Loss Account Statement of the Company/Firm, Business License Details, and Proof of Business Address
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                      <b> Property-related Documents: </b>NOC from Society/Builder, detailed estimate of the cost of construction of the house, registered sale deed, allotment letter, and an approved copy of the building plan.


                      </li>
                    </ul>
                    
                            </div>
                            <div class="col-md-4">
                                <img width="80%" src={img2} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
           

            
            

            
      

        </div>
    </section>
<HelpYou/>
    </>
  )
}

export default HomeLoan
