import img from '../../images/associate.jpeg'
import './businesspartner.css'
import img1 from '../../images/form1.jpeg'
import React,{useState} from 'react'
import axios from 'axios'
import { useToast } from '@chakra-ui/toast'
import {useNavigate} from 'react-router-dom'
const BusinessPartner = () => {
    const toast = useToast()
    const navigate =useNavigate()
    const [firstname,setFirstname] = useState("")
    const [lastname,setLastname] = useState("")
    const [email,setEmail] = useState("")
    const [mobile,setMobile] = useState("")
    const [occupation,setOccupation] = useState("")
    const [degree,setDegree] = useState("")
    const [postaladdress,setPostaladdress] = useState("")
    const [state,setState] = useState("")
    const [city,setCity] = useState("")
    const [pincode,setPincode] = useState("")


    const firstnameChange=(e)=>{
        setFirstname(e.target.value)
    }
    const lastnameChange=(e)=>{
        setLastname(e.target.value)
    }
    const emailChange=(e)=>{
        setEmail(e.target.value)
    }
    const mobileChange=(e)=>{
        setMobile(e.target.value)
    }
    const occupationChange=(e)=>{
        setOccupation(e.target.value)
    }
    const degreeChange=(e)=>{
        setDegree(e.target.value)
    }
    const postaladdressChange=(e)=>{
        setPostaladdress(e.target.value)
    }
    const stateChange=(e)=>{
        setState(e.target.value)
    }
    const cityChange=(e)=>{
        setCity(e.target.value)
    }
    const pincodeChange=(e)=>{
        setPincode(e.target.value)
    }


    const formSubmit=async(e)=>{
        e.preventDefault()
        if(!firstname || !lastname || !email || !mobile || !occupation || !degree || !postaladdress || !state || !city || !pincode){
            toast({
                title: 'Failed.',
                description: "Please Fill all the details.",
                status: 'error',
                duration: 5000,
                isClosable: true,
              })
              return;
        }
        try{
            const config={
                headers:{
                    "Content-type":"application/json"
                }
            }
            const {data} =await axios.post("/api/user/businesspartner",{firstname,lastname,email,mobile,occupation,degree,postaladdress,state,city,pincode},config)
                toast({
                    title: 'Submitted Successfully.',
                    description: "Our team will contact you soon.",
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                  })
                  localStorage.setItem("userData",JSON.stringify(data))
                  navigate("/")
           
        }catch(err){
            toast({
                title: 'Failed.',
                description: err.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
              })
        }
    }
    return (
        <>
            <div id="carouselExampleCaptions" class="carousel slide">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src={img} class="d-block w-100" alt="..." style={{ height: '45vh' }} />
                        <div class="carousel-caption d-md-block">
                            <h1 style={{ color: 'white' }}>Business Partner</h1>
                            <p style={{ color: 'white' }}>Home &nbsp; &#10095; &nbsp; Business Partner</p>
                        </div>
                    </div>
                </div>

            </div>
            <div className="heading">
                <h1>Become Our Business Partner</h1>
            </div>
            <div class="section-block">
                <div class="container">
                    <div class="row mt-50">

                        <div class="col-md-6 col-sm-6 col-12">

                            <h5>How to become RiddhiSiddhi Finance Business Partner</h5><br />


                            <ul class="listnone bullet bullet-check-circle-default">
                                <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>Fill the form given below.</li>
                                <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>Receive  a call from us for guidance.</li>
                                <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>Complete the training process.</li>
                                <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>Complete other formalities also.</li>
                                <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>Become a RiddhiSiddhi Finance Business Partner.</li>
                            </ul>


                        </div>

                        <div class="col-md-6 col-sm-6 col-12">

                            <h5>Advantages of RiddhiSiddhi Finance Business Partner System</h5><br />

                            <ul class="listnone bullet bullet-check-circle-default">
                                <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>It gives extra income along with your current business/job.</li>
                                <li><span class="material-symbols-outlined">
                                    check_circle
                                </span> Little investment needed.</li>
                                <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>You can manage this easily as a part time business.</li>
                                <li><span class="material-symbols-outlined">
                                    check_circle
                                </span>Easy procedure to enrol </li>

                            </ul>



                        </div>
                    </div>

                </div>
            </div>

            <div className="heading">
                <h1>Apply Now</h1>
            </div>
            <div class="section-block">
  <div class="container my-3">
    <div class="row mt-50">
      
      <div class="col-md-6 col-sm-6 col-12">
<form class="contact-form" method="POST">
 
          <div class="row">
            <div class="col-md-6 col-sm-6 col-12">
              <input type="text" name="first_name" className='form-control' value={firstname} onChange={firstnameChange} placeholder="First Name" required/>
                    </div>
            <div class="col-md-6 col-sm-6 col-12">
              <input type="text" name="last_name" className='form-control' value={lastname} onChange={lastnameChange} placeholder="Last Name" required/>
                    </div>

             <div class="col-md-6 col-sm-6 col-12">
              <input type="email" name="email" className='form-control' value={email} onChange={emailChange} placeholder="E-mail" required/>
                    </div>

             <div class="col-md-6 col-sm-6 col-12">
              <input type="number" name="mobile" className='form-control' value={mobile} onChange={mobileChange} placeholder="Mobile" required/>
                    </div>
            
            
            
            
            
            
            <div class="col-md-6 col-sm-6 col-12">
              <select id= "profession" value={occupation} onChange={occupationChange} name="profession" className='form-select' required>
                <option value="">Occupation</option>
                
               
				<option value="Builder">Tax Consultant</option>
				<option value="Chartered Accountant">Financial Worker</option>
				<option value="DSA/DST">CA</option>
				<option value="Ex-banker">Banker</option>
				<option value="Financial Analyst">Loan Agent</option>
				<option value="Financial Consultant">Financial Consultant</option>
				<option value="Freelancer">Associate</option>
				
				<option value="Others">Others</option>
              </select>
                            </div>
            
            
            <div class="col-md-6 col-sm-6 col-12">
              <select id= "education" value={degree} onChange={degreeChange} className='form-select' name="education" required>
                <option value="">Degree</option>
                <option value="10th">10th</option>
                <option value="12th">12th</option>
                <option value="diploma">diploma</option>
                <option value="graduate">graduate</option>
                <option value="postgraduate">postgraduate</option>
                <option value="other">other</option>
              </select>
                            </div>
            
            <div class="col-md-6 col-sm-6 col-12">
              <input type="text" name="postal_address" className='form-control' value={postaladdress} onChange={postaladdressChange} placeholder="Postal Address" required/>
                            </div>
            
                            <div class="col-md-6 col-sm-6 col-12">
              <input type="text" name="state" value={state} onChange={stateChange} className='form-control' placeholder="State" required/>
                            </div>
                            <div class="col-md-6 col-sm-6 col-12">
              <input type="text" name="city" value={city} onChange={cityChange} className='form-control' placeholder="City" required/>
                            </div>
            

            
            
            
            <div class="col-md-6 col-sm-6 col-12">
              <input type="number" name="pincode" className='form-control' value={pincode} onChange={pincodeChange} placeholder="Pincode" required/>
			              </div>
            <div class="g-recaptcha" id="rcaptcha"  data-sitekey="6LeJ4eQfAAAAAMfapz_pEBGhXp3n7ETcqTJYFowK"></div>
<span id="captcha" style={{color:"red"}} ></span>
            <div class="col-md-12">
                <p>Our team will connect with you shortly.</p>
            </div>
            <div class="col-md-12">
              <div class="center-holder">
                <button type="submit" onClick={formSubmit}>Send</button>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="col-md-6 col-sm-6 col-12">
        <img src={img1} alt="image" style={{width:"80%"}}/>
        
      </div>
    </div>

  </div>
</div>
        </>
    )
}
export default BusinessPartner