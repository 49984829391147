import React from 'react'
import './whychoose.css'
import {Link} from 'react-router-dom'
const HelpYou = () => {
  return (
    <>
    <div className="container my-5">
    <div className="heading">
        <h1>We Are Here To Help You</h1>
    </div>
    <div className="whychoose-cards">
        <div className="whychoose-card">
        <span class="material-symbols-outlined">
calendar_month
</span>
<h4>APPLY FOR LOAN</h4>
<p>Looking for a Loan? then apply for loan now.</p>
<Link to="/getappointment">Get Appointment</Link>
        </div>
        <div className="whychoose-card">
        <span class="material-symbols-outlined">
phone_iphone
</span>
<h4>CALL US AT</h4>
<p>(+91) 9506010080</p>
<p>office@riddhisiddhifinances.com</p>
<Link to="/contact">Contact Us</Link>
        </div>
        <div className="whychoose-card">
        <span class="material-symbols-outlined">
pin_drop
</span>
<h4>Our Location</h4>
<p>OM 1/9 SHASTRI NAGAR SIGRA VARANSI -221010</p>
<Link to="/companybranch">Our Location</Link>
        </div>
    </div>
    </div>
    
    </>
  )
}

export default HelpYou
