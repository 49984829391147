import React from 'react'
import img from '../../images/loanap.jpeg'
import './insurance.css'
import { Link } from 'react-router-dom'
import HelpYou from '../home/whychoose/HelpYou'
import '../personalloan/personalloan.css'
const GeneralInsurance = () => {
    return (
        <>
            <div id="carouselExampleCaptions" class="carousel slide">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src={img} class="d-block w-100" alt="..." style={{ height: '45vh' }} />
                        <div class="carousel-caption d-md-block">
                            <h1 style={{ color: 'black' }}>General Insurance</h1>
                            <p style={{ color: 'black' }}>Home &nbsp; &#10095; &nbsp; General Insurance</p>
                        </div>
                    </div>
                </div>

            </div>
            <nav class="navbar navbar-dark bg-dark navbar-expand-lg navBar">
                <div class="container-fluid">
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                    <a class="nav-link" href="#Two">2 Wheeler</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#Car">Car</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#TwoWheeler">Motor Act 2 Wheeler</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#longTerm">Long Term</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#ActCar">Motor Act - Car</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#TrailerInsurance">Trailer Insurance </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#CommercialVechicle">Commercial Vehicle Insurance </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#Policy">Policy </a>
                </li>
                        </ul>
                        <a href="https://wa.me/919506010080"><button class="btn btn-outline-wtsap my-2 my-sm-0" style={{ color: 'white', backgroundColor: 'green', borderRadius: '5px', padding: '6px 15px', marginLeft: '10px' }}><i class="fa fa-whatsapp"></i>&nbsp;Whatsapp</button></a>
                        <Link to="/getappointment"> <button class="btn btn-outline-apply my-2 my-sm-0" type="submit" style={{ color: 'black', backgroundColor: 'white', borderRadius: '5px', padding: '6px 15px', marginLeft: '10px' }}>Apply Now</button></Link>

                    </div>
                </div>
            </nav>
            <div id="Two"></div>
    <section class="main-body">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h3> Two Wheeler Insurance</h3>
                        <div class="row">
                            <div class="col-md-12">
                               <div class="testmonial-box-5">
                     <div class="row mt-30">
                           <div class="col-md-4 col-sm-4 col-12">
                             <div class="feature-box-long">
                                <i class="icon-delivery-truck2"></i>
                                 <h3>Accidental Damage to Vehicle</h3>
                           </div>
                         </div>

                          <div class="col-md-4 col-sm-4 col-12">
                            <div class="feature-box-long">
                                <i class="icon-networking-1"></i>
                                   <h3>Third Party Liability</h3>          
                             </div>
                          </div>

                         <div class="col-md-4 col-sm-4 col-12">
                           <div class="feature-box-long">
                              <i class="icon-man"></i>
                                  <h3>Personal Accident to Owner Driver</h3>
                           </div>
                        </div>
                    </div>
                    <div class="row mt-30">
                           <div class="col-md-4 col-sm-4 col-12">
                             <div class="feature-box-long">
                                <i class="icon-curriculum2"></i>
                                 <h3>No Claim Bonus</h3>
                           </div>
                         </div>

                          <div class="col-md-4 col-sm-4 col-12">
                            <div class="feature-box-long">
                                <i class="icon-document2"></i>
                                   <h3>Add on: Return to Invoice</h3>          
                             </div>
                          </div>

                         <div class="col-md-4 col-sm-4 col-12">
                           <div class="feature-box-long">
                              <i class="icon-puzzle"></i>
                                  <h3>Add on: Protection of NCB</h3>
                           </div>
                        </div>
                    </div>
                  </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="row">
                <div id="Car"></div>
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h3> Private Car Insurance</h3>
                        <div class="row">
                            <div class="col-md-12">
                               <div class="testmonial-box-5">
                     <div class="row mt-30">
                           <div class="col-md-4 col-sm-4 col-12">
                             <div class="feature-box-long">
                                <i class="icon-delivery-truck2"></i>
                                 <h3>Accidental Damage to Vehicle</h3>
                           </div>
                         </div>

                          <div class="col-md-4 col-sm-4 col-12">
                            <div class="feature-box-long">
                                <i class="icon-networking-1"></i>
                                   <h3>Third Party Liability</h3>          
                             </div>
                          </div>

                         <div class="col-md-4 col-sm-4 col-12">
                           <div class="feature-box-long">
                              <i class="icon-man"></i>
                                  <h3>Personal Accident to Owner Driver</h3>
                           </div>
                        </div>
                    </div>
                    <div class="row mt-30">
                           <div class="col-md-4 col-sm-4 col-12">
                             <div class="feature-box-long">
                                <i class="icon-curriculum2"></i>
                                 <h3>No Claim <br/>Bonus</h3>
                           </div>
                         </div>

                          <div class="col-md-4 col-sm-4 col-12">
                            <div class="feature-box-long">
                                <i class="icon-document2"></i>
                                   <h3>Add on: Return to Invoice</h3>          
                             </div>
                          </div>

                         <div class="col-md-4 col-sm-4 col-12">
                           <div class="feature-box-long">
                              <i class="icon-coins2"></i>
                                  <h3>Add on: Depreciation Reimbursement</h3>
                           </div>
                        </div>
                    </div>
                    <div class="row mt-30">
                           <div class="col-md-4 col-sm-4 col-12">
                             <div class="feature-box-long">
                                <i class="icon-settings"></i>
                                 <h3>Add on: Engine Guard</h3>
                           </div>
                         </div>

                          <div class="col-md-4 col-sm-4 col-12">
                            <div class="feature-box-long">
                                <i class="icon-panel"></i>
                                   <h3>Add on: Road Side Assistance</h3>          
                             </div>
                          </div>

                         <div class="col-md-4 col-sm-4 col-12">
                           <div class="feature-box-long">
                              <i class="icon-dollar"></i>
                                  <h3>Add on: Cover for Consumables</h3>
                           </div>
                        </div>
                    </div>
                  </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
             <div class="row">
                 <div id="TwoWheeler"></div>
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h3> Motor Act Only- Two Wheeler(5 Years)</h3>
                        <div class="row">
                            <div class="col-md-12">
                               <div class="testmonial-box-5">
                     <div class="row mt-30">
                           <div class="col-md-4 col-sm-4 col-12">
                             <div class="feature-box-long">
                                <i class="icon-networking-1"></i>
                                 <h3>Third Party Liability for 5 Years</h3>
                           </div>
                         </div>

                          <div class="col-md-4 col-sm-4 col-12">
                            <div class="feature-box-long">
                                <i class="icon-man"></i>
                                   <h3>Personal Accident to Individual Owner</h3>          
                             </div>
                          </div>

                         <div class="col-md-4 col-sm-4 col-12">
                           <div class="feature-box-long">
                              <i class="icon-document2"></i>
                                  <h3>No Annual Renewal</h3>
                           </div>
                        </div>
                    </div>
                    <div class="row mt-30">
                           <div class="col-md-4 col-sm-4 col-12">
                             <div class="feature-box-long">
                                <i class="icon-curriculum2"></i>
                                 <h3>Long-Term <br/>Policy</h3>
                           </div>
                         </div>

                          <div class="col-md-4 col-sm-4 col-12">
                            <div class="feature-box-long">
                                <i class="icon-man"></i>
                                   <h3>PA Cover to Pillion Rider</h3>          
                             </div>
                          </div>

                         <div class="col-md-4 col-sm-4 col-12">
                           <div class="feature-box-long">
                              <i class="icon-briefcase"></i>
                                  <h3>Bi-Fuel <br/> Kit</h3>
                           </div>
                        </div>
                    </div>
                  </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
             <div class="row">
                 <div id="longTerm"></div>
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h3> Long Term Two Wheeler Insurance Policy - Package</h3>
                        <div class="row">
                            <div class="col-md-12">
                               <div class="testmonial-box-5">
                     <div class="row mt-30">
                           <div class="col-md-4 col-sm-4 col-12">
                             <div class="feature-box-long">
                                <i class="icon-delivery-truck2"></i>
                                 <h3>Accidental Damage to Vehicle</h3>
                           </div>
                         </div>

                          <div class="col-md-4 col-sm-4 col-12">
                            <div class="feature-box-long">
                                <i class="icon-networking-1"></i>
                                   <h3>Third Party Liability for 2 / 3 Years</h3>          
                             </div>
                          </div>

                         <div class="col-md-4 col-sm-4 col-12">
                           <div class="feature-box-long">
                              <i class="icon-man"></i>
                                  <h3>Personal Accident to Owner Driver</h3>
                           </div>
                        </div>
                    </div>
                    <div class="row mt-30">
                           <div class="col-md-4 col-sm-4 col-12">
                             <div class="feature-box-long">
                                <i class="icon-document2"></i>
                                 <h3>No Annual Renewal</h3>
                           </div>
                         </div>

                          <div class="col-md-4 col-sm-4 col-12">
                            <div class="feature-box-long">
                                <i class="icon-curriculum2"></i>
                                   <h3>Add on: Return to Invoice</h3>          
                             </div>
                          </div>

                         <div class="col-md-4 col-sm-4 col-12">
                           <div class="feature-box-long">
                              <i class="icon-puzzle"></i>
                                  <h3>Add on: Protection of NCB</h3>
                           </div>
                        </div>
                    </div>
                  </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
             <div class="row">
                 <div id="ActCar"></div>
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h3> Motor Act Only- Private Car(3 Years)</h3>
                        <div class="row">
                            <div class="col-md-12">
                              <div class="testmonial-box-5">
                     <div class="row mt-30">
                           <div class="col-md-4 col-sm-4 col-12">
                             <div class="feature-box-long">
                                <i class="icon-networking-1"></i>
                                 <h3>Third Party Liability for 3 Years</h3>
                           </div>
                         </div>

                          <div class="col-md-4 col-sm-4 col-12">
                            <div class="feature-box-long">
                                <i class="icon-man"></i>
                                   <h3>Personal Accident to Individual Owner</h3>          
                             </div>
                          </div>

                         <div class="col-md-4 col-sm-4 col-12">
                           <div class="feature-box-long">
                              <i class="icon-document2"></i>
                                  <h3>No Annual Renewal</h3>
                           </div>
                        </div>
                    </div>
                    <div class="row mt-30">
                           <div class="col-md-4 col-sm-4 col-12">
                             <div class="feature-box-long">
                                <i class="icon-briefcase"></i>
                                 <h3>Bi-Fuel <br/>Kit</h3>
                           </div>
                         </div>

                          <div class="col-md-4 col-sm-4 col-12">
                            <div class="feature-box-long">
                                <i class="icon-delivery-truck2"></i>
                                   <h3>Legal Liability to Paid Driver</h3>          
                             </div>
                          </div>

                         <div class="col-md-4 col-sm-4 col-12">
                           <div class="feature-box-long">
                              <i class="icon-man"></i>
                                  <h3>PA Cover to Passenger</h3>
                           </div>
                        </div>
                    </div>
                  </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div id="TrailerInsurance"></div>
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h3> Motor - Trailer Insurance</h3>
                        <div class="row">
                            <div class="col-md-12">
                               <div class="testmonial-box-5">
                     <div class="row mt-30">
                           <div class="col-md-4 col-sm-4 col-12">
                             <div class="feature-box-long">
                                <i class="icon-delivery-truck2"></i>
                                 <h3>Accidental Damage to Vehicle</h3>
                           </div>
                         </div>

                          <div class="col-md-4 col-sm-4 col-12">
                            <div class="feature-box-long">
                                <i class="icon-networking-1"></i>
                                   <h3>Third Party Liability</h3>          
                             </div>
                          </div>

                         <div class="col-md-4 col-sm-4 col-12">
                           <div class="feature-box-long">
                              <i class="icon-man"></i>
                                  <h3>Personal Accident to Owner Driver</h3>
                           </div>
                        </div>
                    </div>
                    <div class="row mt-30">
                           <div class="col-md-4 col-sm-4 col-12">
                             <div class="feature-box-long">
                                <i class="icon-checked-12"></i>
                                 <h3>No Claim <br/> Bonus</h3>
                           </div>
                         </div>

                          <div class="col-md-4 col-sm-4 col-12">
                            <div class="feature-box-long">
                                <i class="icon-curriculum2"></i>
                                   <h3>Add on: Return to Invoice</h3>          
                             </div>
                          </div>

                         <div class="col-md-4 col-sm-4 col-12">
                           <div class="feature-box-long">
                              <i class="icon-coins2"></i>
                                  <h3>Add on: Depreciation Reimbursement</h3>
                           </div>
                        </div>
                    </div>
                  </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div id="CommercialVechicle"></div>
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h3> Motor Commercial Vehicle Insurance Policy Package</h3>
                        <div class="row">
                            <div class="col-md-12">
                              <div class="testmonial-box-5">
                     <div class="row mt-30">
                           <div class="col-md-4 col-sm-4 col-12">
                             <div class="feature-box-long">
                                <i class="icon-delivery-truck2"></i>
                                 <h3>Accidental Damage to Vehicle</h3>
                           </div>
                         </div>

                          <div class="col-md-4 col-sm-4 col-12">
                            <div class="feature-box-long">
                                <i class="icon-networking-1"></i>
                                   <h3>Third Party Liability</h3>          
                             </div>
                          </div>

                         <div class="col-md-4 col-sm-4 col-12">
                           <div class="feature-box-long">
                              <i class="icon-man"></i>
                                  <h3>Personal Accident to Owner Driver</h3>
                           </div>
                        </div>
                    </div>
                    <div class="row mt-30">
                           <div class="col-md-4 col-sm-4 col-12">
                             <div class="feature-box-long">
                                <i class="icon-checked-12"></i>
                                 <h3>No Claim <br/> Bonus</h3>
                           </div>
                         </div>

                          <div class="col-md-4 col-sm-4 col-12">
                            <div class="feature-box-long">
                                <i class="icon-curriculum2"></i>
                                   <h3>Add on: Return to Invoice</h3>          
                             </div>
                          </div>

                         <div class="col-md-4 col-sm-4 col-12">
                           <div class="feature-box-long">
                              <i class="icon-coins2"></i>
                                  <h3>Add on: Depreciation Reimbursement</h3>
                           </div>
                        </div>
                    </div>
                  </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div id="Policy"></div>
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h3> Act Only Insurance Policy</h3>
                        <div class="row">
                            <div class="col-md-12">
                               <div class="testmonial-box-5">
                     <div class="row mt-30">
                           <div class="col-md-4 col-sm-4 col-12">
                             <div class="feature-box-long">
                                <i class="icon-networking-1"></i>
                                 <h3>Third Party Liability</h3>
                           </div>
                         </div>

                          <div class="col-md-4 col-sm-4 col-12">
                            <div class="feature-box-long">
                                <i class="icon-man"></i>
                                   <h3>Personal Accident to Owner</h3>          
                             </div>
                          </div>

                         <div class="col-md-4 col-sm-4 col-12">
                           <div class="feature-box-long">
                              <i class="icon-briefcase"></i>
                                  <h3>Bi-Fuel <br/> Kit</h3>
                           </div>
                        </div>
                    </div>
                    <div class="row mt-30">
                           <div class="col-md-4 col-sm-4 col-12">
                             <div class="feature-box-long">
                                <i class="icon-target"></i>
                                 <h3>Natural <br/>Calamities</h3>
                           </div>
                         </div>

                          <div class="col-md-4 col-sm-4 col-12">
                            <div class="feature-box-long">
                                <i class="icon-delivery-truck2"></i>
                                   <h3>Liability for Paid Driver</h3>          
                             </div>
                          </div>

                         <div class="col-md-4 col-sm-4 col-12">
                           <div class="feature-box-long">
                              <i class="icon-signature"></i>
                                  <h3>PA to Paid <br/>Driver</h3>
                           </div>
                        </div>
                    </div>
                  </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            


        </div>
    </section>

            <HelpYou />
        </>
    )
}

export default GeneralInsurance
