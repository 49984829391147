import React from 'react'
import img from '../../images/businessloan.jpeg'
import HelpYou from '../home/whychoose/HelpYou'
import './personalloan.css'
import { Link } from 'react-router-dom'
const BusinessLoan = () => {
  return (
    <>
     <div id="carouselExampleCaptions" class="carousel slide">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src={img} class="d-block w-100" alt="..." style={{ height: '45vh' }} />
                        <div class="carousel-caption d-md-block">
                            <h1 style={{ color: 'white' }}>Business Loan</h1>
                            <p style={{ color: 'white' }}>Home &nbsp; &#10095; &nbsp; Business Loan</p>
                        </div>
                    </div>
                </div>

            </div>
            <nav class="navbar navbar-dark bg-dark navbar-expand-lg navBar">
  <div class="container-fluid">
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
      <li class="nav-item">
                    <a class="nav-link" href="#business">Business Loan</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#eli">Eligibility Criteria</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#document">Documents Required</a>
                </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#types">Type of Business loan</a>
                </li>
                
                
                <li class="nav-item">
                <Link class="nav-link" to="/emicalc">Calculate your Home Loan EMI</Link>
                </li>
                <li class="nav-item">
                    <Link class="nav-link" to="/contact">Contact Us</Link>
                </li>
      </ul>
      <a href="https://wa.me/919506010080"><button class="btn btn-outline-wtsap my-2 my-sm-0" style={{color:'white',backgroundColor:'green',borderRadius:'5px',padding:'6px 15px',marginLeft:'10px'}}><i class="fa fa-whatsapp"></i>&nbsp;Whatsapp</button></a>
      <Link to="/getappointment"> <button class="btn btn-outline-apply my-2 my-sm-0" type="submit" style={{ color: 'black', backgroundColor: 'white', borderRadius: '5px', padding: '6px 15px', marginLeft: '10px' }}>Apply Now</button></Link>
      
    </div>
  </div>
</nav>

<div id="business"></div>

<div class="section-block-grey">
  <div class="container">
    <div class="section-heading">
        
      <h4>Business Loan</h4>


<br/>

<p>Business loan is offered by banks and NBFCs to fulfil the financial requirements of self-employed customers and enterprises. business owners, entrepreneurs, retailers, traders, manufacturers, self-employed professionals like CAs/Doctors, and several other business entities can avail this credit facility. </p>
<p>Business loans are of two types, such as secured loans and unsecured loans. Secured loans are types of loans that require collateral/security, that borrowers need to deposit with the lender to avail funds. However, in the case of unsecured loans, there is no need to submit any type of collateral/security with the Bank, NBFC.</p>
<p>Banks/NBFCs offer both secured and unsecured business loans that include Term Loans (Short-term/Intermediate-term/Long-term), Working Capital Loans, Cash Credit, Overdraft, Letter of Credit, Bill/Invoice Discounting, Equipment Finance, Machinery Loans, Point-of-Sale (POS) Loans/Merchant Cash Advance, Fleet Finance, Loans under Bank Guarantee (BG), Loan under Govt. schemes, etc.
</p>

<div id="eli"></div>

<br/>

<h5>Interest Rate - 12%*</h5>
We compare and offer best interest rate possible!
<br/><br/>
<h5>Eligibility Criteria</h5>
<ul class="primary-list">
                <li><i class="fa fa-caret-right"></i>Business Tenure: Minimum 1 year or above</li>
                <li><i class="fa fa-caret-right"></i>Minimum Annual Turnover: Shall vary from lender to lender</li>
                <li><i class="fa fa-caret-right"></i>Credit Score: 750 or above</li>
                <li><i class="fa fa-caret-right"></i>Applicants with No past loan defaults with any financial institutions</li>
               
              </ul>
<br/>

<h5>Eligible Entities</h5>
<ul class="primary-list">
               
                <li><i class="fa fa-caret-right"></i>Business Owners, Entrepreneurs, CAs/CSs/Doctors/Architects,Start-ups, and MSMEs</li>
                
                <li><i class="fa fa-caret-right"></i>Private and Public Limited Companies, Sole Proprietorships, Partnership Firms, Limited Liability Partnerships (LLPs), and Large Enterprises engaged only in the Manufacturing, Trading, or Services Sectors</li>
                
                <li><i class="fa fa-caret-right"></i>NGOs, Co-operative Societies and Trusts, etc.</li>
                
               
              </ul>
<div id="document"></div>
<br/>

<h5>Documents Required</h5>
<ul class="primary-list">
               
                <li><i class="fa fa-caret-right"></i>Duly filled application form along with passport-sized photographs</li>
                
                <li><i class="fa fa-caret-right"></i>KYC Documents of the applicant, including PAN card, Passport, Aadhar Card, Driving License, Voter ID card, Utility Bills (Water/Electricity Bills)</li>
                
                <li><i class="fa fa-caret-right"></i>Last 1 years’ bank statement</li>
                
                
                  <li><i class="fa fa-caret-right"></i>Copy of Non-Collateral Overdraft, if any</li>
                  
                  
                    <li><i class="fa fa-caret-right"></i>Copy of Business Incorporation</li>
                    
                    
                      <li><i class="fa fa-caret-right"></i>Any other document required by the lender</li>
                      
                      
                    
               
              </ul>
<div id="types"></div>
<br/>


<h5>
Type of Business loan
</h5>
<ul class="primary-list">
                <li><i class="fa fa-caret-right"></i>Term Loan</li>
                
                <li><i class="fa fa-caret-right"></i>Working Capital Loan</li>
                
                <li><i class="fa fa-caret-right"></i>Bill/Invoice Discounting</li>
                
                <li><i class="fa fa-caret-right"></i>Letter of Credit (LC)</li>
                
                 
                <li><i class="fa fa-caret-right"></i>Point-of-Sale (POS) Loan / Merchant Cash Advance</li>
                
                 
                <li><i class="fa fa-caret-right"></i>Overdraft (OD)</li>
               
              </ul>

<br/>

       
    </div>
    </div>
    </div>
<HelpYou/>
    </>
  )
}

export default BusinessLoan
