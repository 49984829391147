import React from 'react'
import {Link} from 'react-router-dom'
const LoanItem = (props) => {
  return (
    <>
    <div className="loanitem" style={{padding:'10px 15px',border:'1px solid gray',margin:'10px',borderRadius:'10px'}}>
        <Link to={props.url} style={{textDecoration:'none',display:'flex',alignItems:'center'}}>
            <img src={props.imgUrl} alt=""style={{width:'70px',height:'70px'}}/>
            <div style={{marginLeft:'5px'}}>
                <h4 style={{color:'black',marginBottom:'5px'}}>{props.h4}</h4>
                <p style={{color:'red',fontWeight:600}}>{props.p}</p>
            </div>
        </Link>
        </div>
        </>
  )
}

export default LoanItem
