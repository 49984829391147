import React from 'react'
import img from '../../images/carloan.jpeg'
import img1 from '../../images/cl1.jpeg'
import img2 from '../../images/cl2.jpeg'
import img3 from '../../images/pl3.jpeg'
import { Link } from 'react-router-dom'
import HelpYou from '../home/whychoose/HelpYou'
import '../personalloan/personalloan.css'
const LoanAgainstCar = () => {
  return (
    <>
     <div id="carouselExampleCaptions" class="carousel slide">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src={img} class="d-block w-100" alt="..." style={{ height: '45vh' }} />
                        <div class="carousel-caption d-md-block">
                            <h1 style={{ color: 'white' }}>Loan Against Car</h1>
                            <p style={{ color: 'white' }}>Home &nbsp; &#10095; &nbsp; Loan Against Car</p>
                        </div>
                    </div>
                </div>

            </div>
            <nav class="navbar navbar-dark bg-dark navbar-expand-lg navBar">
  <div class="container-fluid">
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
      <li class="nav-item">
                    <a class="nav-link" href="#loan">Loans Against Cars</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#beni">Benefits s</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#borrow">How Should One Decide Whether To Borrow Against A Car Or Refinance?</a>
                </li>
                 <li class="nav-item">
                    <a class="nav-link" href="#dec">How Should One Decide Whether To Borrow</a>
                </li>
      </ul>
      <a href="https://wa.me/919506010080"><button class="btn btn-outline-wtsap my-2 my-sm-0" style={{color:'white',backgroundColor:'green',borderRadius:'5px',padding:'6px 15px',marginLeft:'10px'}}><i class="fa fa-whatsapp"></i>&nbsp;Whatsapp</button></a>
      <Link to="/getappointment"> <button class="btn btn-outline-apply my-2 my-sm-0" type="submit" style={{ color: 'black', backgroundColor: 'white', borderRadius: '5px', padding: '6px 15px', marginLeft: '10px' }}>Apply Now</button></Link>
      
    </div>
  </div>
</nav>

<div id="loan"></div>
    <section class="main-body">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h3>A Guide To Loans Against Cars</h3>
                        <div class="row">
                            <div class="col-md-8">
                                <p>A refinance loan involves taking out a new loan at a lower interest rate or extending the repayment period to lower your monthly payments.
</p>
<p>A refinance loan involves taking out a new loan at a lower interest rate or extending the repayment period to lower your monthly payments.</p>
                               
                                 
                            </div>
                            <div class="col-md-4">
                                <img width="100%" src={img1} alt=""/>
                            </div>
                        </div>
                         <div id="beni"></div>
                    </div>
                </div>
                 
            </div>
            
            
          
            <div class="row">
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h4>Benefits of a loan against vehicle/ Car</h4>
                        <p>Our car loan program offers unique benefits that make it easier for borrowers to pay for high-end expenses with ease. As an example, here are a few benefits:</p>
                         <div class="row">
                                    <div class="col-md-8">
                                        
                                    <ul class="listnone bullet bullet-check-circle-default">
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                     	<b>Low car loan EMIs --</b> Choosing a long repayment period may allow you to pay a lower EMI every month.
                    </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                   <b>Most rapid loan approval --</b>   With our fastest mortgage loan in India, you will receive the loan amount within four business days of your agreement.
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                      <b>A low rate of interest --</b>The 'Car Kharido Becho 24 Service' provides the best interest rate for auto/vehicle loans at affordable rates.
                      </li> 
                      
                     </ul>
                                    </div>

                                    <div class="col-md-4">
                                        <img width="80%" src={img2} alt=""/>
                                    </div>

                                </div>
                        <div id="borrow"></div>
                    </div>
                </div>
            </div>

            
          
            <div class="row">
                <div class="col-md-12">
                    <div class="loan-lg-card">


                        <h4>How Should One Decide Whether To Borrow Against A Car Or Refinance?
 
</h4>

<p>  Refinancing refers to taking out a new loan to repay an existing loan, the collateral being your car. You will get a lump sum payment to pay off your outstanding loan. As well as a new rate and payment schedule, you will receive a new one.</p>
                        <div class="row">
                            <div id="cfpl"></div>
                            <div class="col-md-8">
                    
                            <ul class="listnone bullet bullet-check-circle-default">
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                  Refinancing makes sense if:The interest rate is reduced during the first repayment period. You can reduce your EMIs, thereby reducing spending by refinancing. Your credit score has increased, which entitles you to better interest rates. You wish to extend the repayment period to save more money. You will have to repay more in the long run.
                    </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                      Refinancing doesn't make sense if:
1) Your existing loan has prepayment penalties
2) The new loan has higher processing fees and other charges
3) Your budget will not allow you to repay a new loan.
                      </li>
                     
                     </ul>
                     

                            </div>
                            <div class="col-md-4">
                                <img width="100%" src={img3} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div id="dec"></div>
                </div>
            </div>
            

            
            
               <div class="row">
                <div class="col-md-12">
                    <div class="loan-lg-card">


                        <h4>How Should One Decide Whether To Borrow
 
</h4>

<p>A car loan depends on a variety of factors, including:</p>
                        <div class="row">
                            <div id="cfpl"></div>
                            <div class="col-md-8">
                    
                            <ul class="listnone bullet bullet-check-circle-default">
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                     An age limit based on a minimum and maximum
                    </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                      A secure job or business history with a stable monthly income


                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                    A credit history report. When you obtain your free credit report on Carkhareedobecho24, you should be able to get the best possible car loan deal.
                      </li> 
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                Previous experience with the specific bank/financier
                      </li>
                       <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                    Car type - new or used
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                    check_circle
                                </span>
                   Having a stable residence could lead to more affordable interest rates or lesser loan amounts.
                      </li>
                      
                       <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                    check_circle
                                </span>Loan eligibility can be made simpler by using online tools such as Carkhareedobecho24. All you have to do is click/tap - there is no need to carry around documents, visit various banks, or prove your eligibility each time.
                      </li>
                     </ul>
                     
                     <p>The exact criteria for loan eligibility might differ from one lender to another, so check with the lender for details.</p>
                     

                            </div>
                            <div class="col-md-4">
                                <img width="100%" src={img1} alt=""/>
                            </div>
                        </div>
                    </div>
                 
                </div>
            </div>
            
            
            
          
        
        
            
            
            

        </div>
    </section>

<HelpYou/>
    </>
  )
}

export default LoanAgainstCar
