import React from 'react'
import HelpYou from '../home/whychoose/HelpYou'
import ApplicationProcess from '../home/applicationprocess/ApplicationProcess'
import AboutCards from '../home/aboutcards/AboutCards'
import img from '../../images/aboutus.jpeg'
const AboutUs = () => {
  return (
    <>
     <div id="carouselExampleCaptions" class="carousel slide">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src={img} class="d-block w-100" alt="..." style={{ height: '45vh' }} />
                        <div class="carousel-caption d-md-block">
                            <h1 style={{ color: 'white' }}>About Us</h1>
                            <p style={{ color: 'white' }}>Home &nbsp; &#10095; &nbsp; About Us</p>
                        </div>
                    </div>
                </div>

            </div>
            <AboutCards/>
            <ApplicationProcess/>
            <HelpYou/>
    </>
  )
}

export default AboutUs
