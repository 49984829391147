import React from 'react'
import img from '../../images/lifeinsurance.jpeg'
import img1 from '../../images/pl1.jpeg'

import { Link } from 'react-router-dom'
import HelpYou from '../home/whychoose/HelpYou'
import '../personalloan/personalloan.css'
const LifeInsurance = () => {
    return (
        <>
            <div id="carouselExampleCaptions" class="carousel slide">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src={img} class="d-block w-100" alt="..." style={{ height: '45vh' }} />
                        <div class="carousel-caption d-md-block">
                            <h1 style={{ color: 'white' }}>Life Insurance</h1>
                            <p style={{ color: 'white' }}>Home &nbsp; &#10095; &nbsp; Life Insurance</p>
                        </div>
                    </div>
                </div>

            </div>
            <nav class="navbar navbar-dark bg-dark navbar-expand-lg navBar">
                <div class="container-fluid">
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                    <a class="nav-link" href="#Life">Life Insurance</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#Policy">Life Insurance Policy</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#Terms">Life Insurance Terms</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#Why">Why Life Insurance Policy</a>
                </li>
                        </ul>
                        <a href="https://wa.me/919506010080"><button class="btn btn-outline-wtsap my-2 my-sm-0" style={{ color: 'white', backgroundColor: 'green', borderRadius: '5px', padding: '6px 15px', marginLeft: '10px' }}><i class="fa fa-whatsapp"></i>&nbsp;Whatsapp</button></a>
                        <Link to="/getappointment"> <button class="btn btn-outline-apply my-2 my-sm-0" type="submit" style={{ color: 'black', backgroundColor: 'white', borderRadius: '5px', padding: '6px 15px', marginLeft: '10px' }}>Apply Now</button></Link>

                    </div>
                </div>
            </nav>
            <div id="Life"></div>
    <section class="main-body">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h3>Life Insurance </h3>
                        <div class="row">
                            <div class="col-md-8">
                              <p>Life Insurance is a financial product, wherein the policy holder and the life insurance company comes to an agreement. A lump sum amount is paid by the insurance company in the form of insurance coverage to the nominee/insured in return for the premium after a specific period or in case of the death of the insured.</p>  
                            </div>
                            <div class="col-md-4">
                                <img width="100%" src={img1} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="row">
                <div id="Policy"></div>
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h3>What is Life Insurance Policy? </h3>
                        <div class="row">
                            <div class="col-md-8">
                               <p>A life insurance policy is an agreement between an insurance company & a policyholder that offers financial coverage under which the insurance company guarantees to pay a certain amount to the nominated beneficiary in the unfortunate event of the insured person's demise during the term of life insurance plans. In exchange, the policyholder agrees to pay a predefined amount of money as premium either on a regular basis or as a single premium.</p> 
                               
                               <p>If covered by the policy, coverage will be provided for critical illness as well.</p>

                                <p>Since it provides enhanced insurance coverage, it attracts an enhanced life insurance premium.</p>
                            </div>
                            <div class="col-md-4">
                                <img width="100%" src={img1} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="row">
                <div id="Terms"></div>
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h3>Important Life Insurance Terms You Should Know </h3>
                        <div class="row">
                            <div class="col-md-8">
                                <div class="testmonial-box-5">
                    
                    <p>To understand life insurance completely it is important to know the basic terminologies that are often used within the plan. Sometimes you might not be aware of some terms and refrain from buying the plan.</p>

                    <p>Let us help you to understand the given below key important life insurance terms with brief explanations of each:</p>
                    
                    
                    <p><strong>Policyholder </strong><br/>
                    Anyone who buys the life insurance plan and pays the premium is referred to as the policyholder. An individual might own the policy, however, may or may not be the life assured.</p>
                    
                    <p><strong>Life Assured </strong><br/>
                    In simple words, the individual who is protected or insured is known as a life assured. In case of demise of the life assured, the beneficiary will receive the insurance sum. For example, when a husband buys the life insurance policy for his wife then he is the policyholder and the wife is the life assured.</p>


                    <p><strong>Nominee </strong><br/>
                      The person that is nominated by the policyholder is known as a nominee. The nominee will receive the life insurance payouts along with other benefits in case of any eventuality. The nominee is also referred to as the beneficiary. A nominee is declared when the policy is being bought. Generally, the policyholders’ immediate family members such as the spouse, children and parents are declared as a nominee who is directly financially dependent upon you.
                    </p>

                    <p><strong>Policy Tenure </strong><br/>
                      The duration for, which the life insurance plan provides coverage, is known as the policy tenure sometimes also known as policy term. On the premise of the type of life insurance policy and the terms and conditions of the insurance provider, the life insurance policy tenure differs.
                    </p>

                    <p><strong>Premium </strong><br/>
                      It is the amount that you will pay to keep the life insurance plan active. In case you are unable to pay the life insurance premium sum either before the due date or under the grace period, then the policy will lapse. The life insurance premium sum depends on factors such as the policy term, the age of the life assured, lifestyle habits, etc.
                    </p><br/>
                  
                
                    <p><strong>Sum Assured </strong><br/>
                    </p><p>It is the guaranteed sum that the beneficiary or the nominee will receive when the life assured has passed away. Most of the times, the choice to arrive at a sum assured is upon the premise of the financial loss that might incur due to the death of the life assured. At the time of buying the life insurance plan, the policyholder chooses this sum, which is then paid to the nominee if the life assured passes away during the policy term.</p><br/>
                  
                    <p><strong>Death Benefit </strong><br/>
                    </p><p>The death benefit is the sum that is paid to a nominee when the life assured has passed away during the policy tenure. It is to be noted that sum assured and death benefits are two different terms. This implies that the death benefit could be equivalent or higher than a sum assured as it could involve the rider benefit too.</p>
                    <br/>

                    <p><strong>Maturity Benefit </strong><br/>
                    </p><p>The sum that is paid to the policyholder when the policy tenure is completed is known as the maturity benefit.</p><br/>

                    <p><strong>Lapsed Policy </strong><br/>
                    </p><p>When the policy gets terminated because of non-payment of the premium amount even after the grace period is known as a lapsed policy. There are life insurance companies that offer the facility to revive a lapsed policy only when the outstanding premiums are duly paid by the policyholder.</p><br/>

                    <p><strong>Grace Period </strong><br/>
                    </p><p>It is essentially the extension given by the insurance provider to the policyholder after the due date of the premium payment. When the policyholder pays the premium sum then the cover of the plan continues.</p>

                    <p><strong>Revival Period </strong><br/>
                    </p><p>When the premiums are not paid during the grace period the policy gets lapsed. In case you wish to continue with the plan then you are provided with an option of re-activating the lapsed plan, however, it should be completed with a certain time frame after the end of the grace period. This is known as the revival period.</p>

                    <p><strong>Free-look Period </strong><br/>
                    </p><p>In case you are not comfortable with the terms and conditions of the plan it can be returned within a certain timeframe mentioned in the policy documents. This is known as a free-look period. After deducting stamp duty charges, medical examination, proportionate risk premium, the premium sum will be refunded.</p>

                    <p><strong>Rider </strong><br/>
                    </p><p>The riders are additional benefit than enhances the coverage of the plan. These rider benefits are optional and enhance the financial safety to secure the family against any unforeseen event by paying an additional premium sum.</p>

                    <p><strong>Claim Process </strong><br/>
                    </p><p>When the life assured passes away during the policy term, the nominee will file a claim to receive the death benefit. This process is known as the claim process.</p>

                    <p><strong>Exclusions </strong><br/>
                    </p><p>Certain situations remain uncovered within a life insurance plan. In case the claim is made on such exclusions then no benefit is provided by the insurance company.</p>



                 </div>
                            </div>
                            <div class="col-md-4">
                                <img width="100%" src={img1} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="row">
                <div id="Why"></div>
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h3>Why Do I Need Life Insurance Policy? </h3>
                        <div class="row">
                            <div class="col-md-8">
                                <div class="testmonial-box-5">
                    
                     <p>A life insurance policy is essentially a financial cover for any contingency that is linked with human life in case of a disability, death, accident, retirement, and so forth. Human life is mostly subject to risks of demise or disability due to an accident or even natural causes. When human life is lost or suffers a disability whether partial, temporary or permanent undoubtedly it is a loss of income to a household specifically if the individual is the sole bread earner.</p>

                     <p>Of course, the life of a human can merely not be valued; however, a money sum can be determined on the premise of the loss of income in the prospective years. So, in the life insurance, a sum assured that is the sum guaranteed to be paid in case of the loss is by the way of the benefit. The life insurance products offer the definite amount of sum in case of life insured pass away during the policy term or disability on account of a mishap.</p>

                    <p>Listed below are some of the key reasons that will highlight the need for a life insurance policy: </p>
                    <ul class="listnone bullet bullet-check-circle-default">
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                     In the event the policyholder passes away, a life insurance plan ensures that the family has financial support.
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                      It will help to finance the educational and various other needs of the children.
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                       Even after retirement they have a steady flow of income.
                      </li> 

                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        In case of a or an accident, life insurance ensures that additional income is there even when the earnings are reduced or exhausted.
                      </li> 

                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                       It provides for various financial contingencies and also the lifestyle requirements.
                      </li> 

                      <p>Henceforth, a life insurance plan is ideal for those who have a family to support and is the breadwinner. The life insurance coverage depends upon various factors such as the number of dependents, investment needs, and so much more. With the evolving time, the life insurance plan is merely not confined to provide protection but also serve the savings and investment needs. As life is full of uncertainties so having a life insurance policy in place will act as the financial hedge and enable you to overcome this insecurity.</p>

                    </ul><br/>

                    <p></p>


                  </div>
                            </div>
                            <div class="col-md-4">
                                <img width="100%" src={img1} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            


        </div>
    </section>


            <HelpYou />
        </>
    )
}

export default LifeInsurance
