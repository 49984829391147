import img from '../../images/contact.jpeg'
import '../businesspartner/businesspartner.css'
import HelpYou from '../home/whychoose/HelpYou'
import React,{useState} from 'react'
import { useToast } from '@chakra-ui/react'
import {useNavigate} from 'react-router-dom'
import axios from 'axios'
const Contact = () => {
  const toast = useToast()
  const navigate =useNavigate()

const [firstname,setFirstname] = useState("")
const [lastname,setLastname] = useState("")
const [email,setEmail] = useState("")
const [mobile,setMobile] = useState("")
const [city,setCity] = useState("")
const [occupation,setOccupation] = useState("")
const [loantype,setLoantype] = useState("")
const [itrstatus,setItrstatus] = useState("")
const [creditscore,setCreditscore] = useState("")


const firstnameChange=(e)=>{
    setFirstname(e.target.value)
}
const lastnameChange=(e)=>{
    setLastname(e.target.value)
}
const emailChange=(e)=>{
    setEmail(e.target.value)
}
const mobileChange=(e)=>{
    setMobile(e.target.value)
}
const occupationChange=(e)=>{
    setOccupation(e.target.value)
}
const cityChange=(e)=>{
  setCity(e.target.value)
}
const itrstatusChange=(e)=>{
    setItrstatus(e.target.value)
}
const loantypeChange=(e)=>{
    setLoantype(e.target.value)
}
const creditscoreChange=(e)=>{
    setCreditscore(e.target.value)
}

const formSubmit=async(e)=>{
  e.preventDefault()
  if(!firstname || !lastname || !email || !mobile || !occupation || !itrstatus || !loantype || !creditscore || !city ){
      toast({
          title: 'Failed.',
          description: "Please Fill all the details.",
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
        return;
  }
  try{
      const config={
          headers:{
              "Content-type":"application/json"
          }
      }
      const {data} =await axios.post("/api/user/",{firstname,lastname,email,mobile,occupation,itrstatus,creditscore,loantype,city},config)
          toast({
              title: 'Submitted Successfully.',
              description: "Our team will contact you soon.",
              status: 'success',
              duration: 5000,
              isClosable: true,
            })
            localStorage.setItem("userData",JSON.stringify(data))
            navigate("/")
     
  }catch(err){
      toast({
          title: 'Failed.',
          description: err.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
  }
}
    return (
        <>
            <div id="carouselExampleCaptions" class="carousel slide">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src={img} class="d-block w-100" alt="..." style={{ height: '45vh' }} />
                        <div class="carousel-caption d-md-block">
                            <h1 style={{ color: 'white' }}>Contact</h1>
                            <p style={{ color: 'white' }}>Home &nbsp; &#10095; &nbsp; Contact</p>
                        </div>
                    </div>
                </div>

            </div>
           

            <div className="heading">
                <h6 style={{color:'red',marginTop:'30px',textAlign:'center'}}>Get in Touch</h6>
                <h1>Get Your Appointment</h1>
                <h4 style={{color:'black',textAlign:'center',marginTop:'20px'}}>Fill the form below to get the best deals
</h4>
            </div>
            <div class="section-block">
  <div class="container my-3">
    <div class="row mt-50">
      
      <div class="col-md-6 col-sm-6 col-12">
<form class="contact-form" method="post">
 
          <div class="row">
            <div class="col-md-6 col-sm-6 col-12">
              <input type="text" name="first_name" className='form-control' value={firstname} onChange={firstnameChange} placeholder="First Name" required/>
                    </div>
            <div class="col-md-6 col-sm-6 col-12">
              <input type="text" name="last_name" className='form-control' value={lastname} onChange={lastnameChange} placeholder="Last Name" required/>
                    </div>

             <div class="col-md-6 col-sm-6 col-12">
              <input type="email" name="email" className='form-control' value={email} onChange={emailChange} placeholder="E-mail" required/>
                    </div>

             <div class="col-md-6 col-sm-6 col-12">
              <input type="number" name="mobile" className='form-control' value={mobile} onChange={mobileChange} placeholder="Mobile" required/>
                    </div>
                    <div class="col-md-6 col-sm-6 col-12">
              <input type="text" name="city" value={city} onChange={cityChange} className='form-control' placeholder="City" required/>
                            </div>
            
            
            
            
            
            
            <div class="col-md-6 col-sm-6 col-12">
              <select id= "profession" value={loantype} onChange={loantypeChange} name="profession" className='form-select' required>
               
				
              <option value="">Loan Type</option>
                <option value="personal">Personal Loan</option>
                <option value="home">Home Loan</option>
                <option value="property">Loan Against Property</option>
                <option value="car">Car Loan</option>
                <option value="gold">Gold Loan</option>
                <option value="business">Business Loan</option>
                <option value="other">Other Loan</option>              </select>
                            </div>
            
            
            <div class="col-md-6 col-sm-6 col-12">
              <select id= "education" value={occupation} onChange={occupationChange} className='form-select' name="education" required>
              <option value="">Occupation</option>
                <option value="salaried">Salaried</option>
                <option value="business">Business</option>
              </select>
                            </div>
                            <div class="col-md-6 col-sm-6 col-12">
              <select id= "education" value={itrstatus} onChange={itrstatusChange} className='form-select' name="education" required>
              <option value="">ITR Status</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
                            </div>
                            <div class="col-md-6 col-sm-6 col-12">
              <select id= "education" value={creditscore} onChange={creditscoreChange} className='form-select' name="education" required>
              <option value="">Credit Score</option>
                <option value="excellent">Excellent</option>
                <option value="good">Good</option>
                <option value="bad">bad</option>
                <option value="notsure">Not Sure</option>
              </select>
                            </div>
            
           
                           
            

            
            
            
            <div class="g-recaptcha" id="rcaptcha"  data-sitekey="6LeJ4eQfAAAAAMfapz_pEBGhXp3n7ETcqTJYFowK"></div>
<span id="captcha" style={{color:"red"}} ></span>
            <div class="col-md-12">
                <p>Our team will connect with you shortly.</p>
            </div>
            <div class="col-md-12">
              <div class="center-holder">
                <button type="submit" onClick={formSubmit}>Send</button>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="col-md-6 col-sm-6 col-12">
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3606.6633206597653!2d82.98754156420036!3d25.315513383104367!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x398e2dfc584be90b%3A0x55ba82bc5bd027c3!2sShastri%20Nagar%2C%20Chetganj%2C%20Varanasi%2C%20Uttar%20Pradesh!5e0!3m2!1sen!2sin!4v1678531059400!5m2!1sen!2sin" width="450" height="400" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        
      </div>
    </div>

  </div>
</div>
<HelpYou/>
        </>
    )
}
export default Contact;