import React from 'react'
import Carousel from 'react-elastic-carousel'
import './testimonial.css'
const Testimonial = () => {
  return (
    <>
    <div className="testimonials">
        <div className="heading">
            <h1>Some of Our Awesome Testimonials</h1>
        </div>
        <div className="container">
        <Carousel itemsToShow={1}>
        <div className="testimonial">
            <div>
                <p>Contacted Bhumi Finance for business loan. Was able to get loan efficiently within 1 day and helped in doing all paper work. Never expected such quality service. Thank you Bhumi Finance. Thanks a lot 😌🙏</p>
                <h2>Keshvendra</h2>
            </div>
            <img src="https://st2.depositphotos.com/47577860/46974/v/600/depositphotos_469745192-stock-illustration-boy-male-testimonial-icon-flat.jpg" alt="" />
        </div>
        <div className="testimonial">
            <div>
                <p>Contacted Bhumi Finance for business loan. Was able to get loan efficiently within 1 day and helped in doing all paper work. Never expected such quality service. Thank you Bhumi Finance. Thanks a lot 😌🙏</p>
                <h2>Keshvendra</h2>
            </div>
            <img src="https://st2.depositphotos.com/47577860/46974/v/600/depositphotos_469745192-stock-illustration-boy-male-testimonial-icon-flat.jpg" alt="" />
        </div>
        <div className="testimonial">
            <div>
                <p>Contacted Bhumi Finance for business loan. Was able to get loan efficiently within 1 day and helped in doing all paper work. Never expected such quality service. Thank you Bhumi Finance. Thanks a lot 😌🙏</p>
                <h2>Keshvendra</h2>
            </div>
            <img src="https://st2.depositphotos.com/47577860/46974/v/600/depositphotos_469745192-stock-illustration-boy-male-testimonial-icon-flat.jpg" alt="" />
        </div>
        </Carousel>
        </div>
    </div>
    </>
  )
}

export default Testimonial
