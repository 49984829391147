import React from 'react'
import img from '../../images/reinsurance.jpeg'
import img1 from '../../images/pl1.jpeg'

import { Link } from 'react-router-dom'
import HelpYou from '../home/whychoose/HelpYou'
import '../personalloan/personalloan.css'
const Reinsurance = () => {
    return (
        <>
            <div id="carouselExampleCaptions" class="carousel slide">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src={img} class="d-block w-100" alt="..." style={{ height: '45vh' }} />
                        <div class="carousel-caption d-md-block">
                            <h1 style={{ color: 'white' }}>Reinsurance</h1>
                            <p style={{ color: 'white' }}>Home &nbsp; &#10095; &nbsp; Reinsurance</p>
                        </div>
                    </div>
                </div>

            </div>
            <nav class="navbar navbar-dark bg-dark navbar-expand-lg navBar">
                <div class="container-fluid">
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                    <a class="nav-link" href="#Reinsurance">Reinsurance</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#Service">Service Offered</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#Expertise">Team Expertise</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#FACULTATIVE">Faculative</a>
                </li>
                        </ul>
                        <a href="https://wa.me/919506010080"><button class="btn btn-outline-wtsap my-2 my-sm-0" style={{ color: 'white', backgroundColor: 'green', borderRadius: '5px', padding: '6px 15px', marginLeft: '10px' }}><i class="fa fa-whatsapp"></i>&nbsp;Whatsapp</button></a>
                        <Link to="/getappointment"> <button class="btn btn-outline-apply my-2 my-sm-0" type="submit" style={{ color: 'black', backgroundColor: 'white', borderRadius: '5px', padding: '6px 15px', marginLeft: '10px' }}>Apply Now</button></Link>

                    </div>
                </div>
            </nav>
            <div id="Reinsurance"></div>
    <section class="main-body">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h3>Reinsurance</h3>
                        <div class="row">
                            <div class="col-md-8">
                               <p>Bhumi Finance Reinsurance broking business is a new business opportunity for the company. The Reinsurance (RI) product is used by insurance companies to transfer their portfolio risks to reinsurers, protecting the insurer’s balance sheets and enabling continuity of business operations.</p> 
                               <p>Our company’s Reinsurance team is comprised of experts whose collective experience in reinsurance totals over eight decades in India. The team is backed by an Advanced RI Administration and Database software system, ensuring process efficiency and reliable delivery to our clients. We are constantly investing in expertise, skills and tools that would be required to become a distinguished player in the market and strive to deliver innovative and customized solutions to our customers.</p>
                               <p>Through its introduction of the Reinsurance product, Bhumi Finance enters the international arena of insurance broking. The RI Broking team uses its international domain knowledge and expertise to provide innovative and customized solutions to insurance companies, nurturing long term business, relationships and expanding the company’s industry network.</p>
                               <p>Bhumi Finance Direct Broking Business provides the foundation of this network, with initial Reinsurance business being drawn from this base of clients.</p>
                               
                               <ul class="listnone bullet bullet-check-circle-default">
							
              <li style={{color:"#404040",marginTop:"10px"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                   Import of reinsurance in which foreign reinsurance capacity and protection is obtained for Indian insurance companies
              </li>
							
              <li style={{color:"#404040",marginTop:"10px"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                  Export of reinsurance in which reinsurance from Indian reinsurers support overseas insurance companies
              </li>
							
              <li style={{color:"#404040",marginTop:"10px"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                  Cross Border operations in which one insurance company uses Bhumi Finance expertise to reinsure their risks with reinsurers from countries other than India.
                </li>
						</ul>
                            </div>
                            <div class="col-md-4">
                                <img width="100%" src={img1} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <div class="row">
                <div id="Service"></div>
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h3>Service Offered</h3>
                        <div class="row">
                            <div class="col-md-8">
                                <ul class="listnone bullet bullet-check-circle-default">
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                      Relationship with potential Lead-Reinsurers and ability to secure best terms.
                    </li>
                      <li style={{color:"#404040",marginTop:"10px"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Designing a suitable Reinsurance program taking into account the Business Plan and projections of the company; structuring various Non-proportional covers and proportional treaties; also arranging a suitable Leader for Fac proposals.
                      </li>
                      <li style={{color:"#404040",marginTop:"10px"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Identifying the right Lead-Reinsurers and obtaining quotations.
                      </li> 
                      <li style={{color:"#404040",marginTop:"10px"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                         Negotiation of best terms for various arrangements.
                      </li>
                      <li style={{color:"#404040",marginTop:"10px"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Marketing and placement of a reinsurance programme with best securities.
                      </li>
                      <li style={{color:"#404040",marginTop:"10px"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Administration of the programme, including all documentation.
                      </li>
                      <li style={{color:"#404040",marginTop:"10px"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Assessment of counterparty risk (i.e. the financial strength of reinsurance companies).
                      </li>
                      <li style={{color:"#404040",marginTop:"10px"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Recovery of claims from reinsurers, obtaining their payments promptly.
                      </li>
                      <li style={{color:"#404040",marginTop:"10px"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Providing regular market information and assessment.
                      </li>
                      <li style={{color:"#404040",marginTop:"10px"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Technical advice.
                      </li>
                      <li style={{color:"#404040",marginTop:"10px"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Assistance with direct contact between reinsured and reinsurers.
                      </li>
                      <li style={{color:"#404040",marginTop:"10px"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Training / Seminars for employees of the company.
                      </li>
                      <li style={{color:"#404040",marginTop:"10px"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Other services that may be volunteered
                      </li>
                     </ul>
                            </div>
                            <div class="col-md-4">
                                <img width="100%" src={img1} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div id="Expertise"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h3>Team Expertise</h3>
                        <div class="row">
                            <div class="col-md-8">
                                <ul class="listnone bullet bullet-check-circle-default">
                      <li style={{color:"#404040",marginTop:"10px"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                      The reinsurance team is headed by an expert with over 37 years reinsurance experience with national reinsurer and other private insurers.
                    </li>
                      <li style={{color:"#404040",marginTop:"10px"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                       Ably assisted by experts with reinsurance broking and underwriting experience in India.
                      </li>
                      <li style={{color:"#404040",marginTop:"10px"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Team having diverse background and rich experience for handling both Treaty and Facultative Reinsurance.
                      </li> 
                      <li style={{color:"#404040",marginTop:"10px"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Expertise in handling various lines of business such as Aviation, Energy, Marine, Liability, Property, Terrorism, etc.
                      </li>
                      
                    </ul>
                            </div>
                            <div class="col-md-4">
                                <img width="100%" src={img1} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div id="FACULTATIVE"></div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h3>FACULTATIVE</h3>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-12">
                      <div class="row mt-3">
                    	<div class="col-md-3">
                    		<h6>Property</h6>
                    		  <ul class="listnone bullet bullet-check-circle-default">
                      				<li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                      					Fire and Burglary
                      				</li>
                      				<li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                      					Large Risk / Mega Risk
                      				</li>
                      			</ul>
                    	</div>
                    	<div class="col-md-3">
                    		<h6>Engineering</h6>
                    		  <ul class="listnone bullet bullet-check-circle-default">
                    			  <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                      					CAR / EAR
                      				</li>
                      				<li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                      					IAR
                      				</li>
                      			</ul>
                    	</div>
                    	<div class="col-md-3">
                    		<h6>Marine (CARGO and HULL)</h6>
                    		  <ul class="listnone bullet bullet-check-circle-default">
                    			  <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                      					Bulk Cargos
                      				</li>
                      				<li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                      					Pleasure crafts
                      				</li>
                      				<li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                      					Oil Cargos
                      				</li>
                      				<li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                      					Military Cargos
                      				</li>
                      			</ul>
                    	</div>
                    	<div class="col-md-3">
                    		<h6>Energy</h6>
                    		  <ul class="listnone bullet bullet-check-circle-default">
                    			  <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                      					Onshore
                      				</li>
                      				<li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                      					Offshore
                      				</li>
                      				<li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                      					Renewable Energy
                      				</li>
                      			</ul>
                    	</div>
                    </div>
                    <div class="row" style={{marginTop: "50px"}}>
                    	<div class="col-md-3">
                    		<h6>Aviation</h6>
                    		  <ul class="listnone bullet bullet-check-circle-default">
                    			  <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                      					Hull all Risk
                      				</li>
                      				<li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                      					Liabilities
                      				</li>
                      				<li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
									             	Hull War Risk
	                   				</li>
                      				<li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                      					Aviation excess War Liability
                      				</li>

                      				<li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                      					Aviation Products –Avn Prduct liability, Ground handlers, Refullers, Hangkeepers liability etc
                      				</li>
                      			</ul>
                    	</div>
                    	<div class="col-md-3">
                    		<h6>Miscellaneous</h6>
                    		  <ul class="listnone bullet bullet-check-circle-default">
                    		  	<li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                    		  		GPA, Health and Life
                    		  	</li>
                    		  	<li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                    		  		Credit
                    		  	</li> 	
                    		  	<li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                    		  		Terrorism
                    		  	</li>	  	
                    		  	<li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                    		  		Jeweler's Block
                    		  	</li>
                    		  	<li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                    		  		Fine Arts &amp; Speices
                    		  	</li>
                    		  	<li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                    		  		Event Insurance
                    		  	</li>
                    		  </ul>
                    	</div>
                    	<div class="col-md-3">
                    		<h6>Liability</h6>
                    		<ul class="listnone bullet bullet-check-circle-default">
                    			<li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                    				D &amp; O
                    			</li>
                    			<li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                    				E &amp; O
                    			</li>
                    			<li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                    				KNR
                    			</li>
                    			<li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                    				CGL
                    			</li>
                    			<li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                    				Financial Liability
                    			</li>
                    			<li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                    				Bankers Blanket
                    			</li>
                    		</ul>
                    	</div>
                      
                      <div class="col-md-3">
                      	<h6>Proportional Treaties (all classes)</h6>
                      	<ul class="listnone bullet bullet-check-circle-default">
                          <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                            Quota Share
                          </li>
                          <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                            Surplus
                          </li>
                          <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                            Quota Share cum Surplus
                          </li>
                        </ul>
                      </div>
                    </div>

                    <div class="row" style={{marginTop: "50px"}}>
                      <div class="col-md-4">
                        <h6>Non Proportional Treaties</h6>
                        <ul class="listnone bullet bullet-check-circle-default">
                          <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                            Net Account XL
                          </li>
                          <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                            Stop Loss Excess
                          </li>
                          <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                            Pool Excess Cover
                          </li>
                          <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                            Short fall Excess
                          </li>
                          <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                            Proportional Short fall Excess
                          </li>
                        </ul>
                      </div>

                      <div class="col-md-4">
                        <h6>In addition to above we can also assist in :</h6>
                        <ul class="listnone bullet bullet-check-circle-default">
                          <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                            Crop Treaties
                          </li>
                          <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                            Medical Treaties
                          </li>
                          <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                            Energy Treaties
                          </li>
                          <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                            Facultative Obligatory Treaties
                          </li>
                        </ul>
                      </div>
                    </div>
                  		</div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            
            


        </div>
    </section>


            <HelpYou />
        </>
    )
}

export default Reinsurance
