import React from 'react'
import './whychoose.css'
import {Link} from 'react-router-dom'
const WhyChoose = () => {
  return (
    <>
    <div className="container my-5">
    <div className="heading">
        <h1>Why People Choose Us</h1>
    </div>
    <div className="whychoose-cards">
        <div className="whychoose-card">
        <span class="material-symbols-outlined">
diversity_3
</span>
<h4>Dedicated Specialists</h4>
<p>We meet you with our specialist dedicated team who can explain to you in full.</p>
<Link to="/leadershipteam">Meet The Team</Link>
        </div>
        <div className="whychoose-card">
        <span class="material-symbols-outlined">
local_police
</span>
<h4>Success Stories Rating</h4>
<p>We do not tell about our success stories, our client say that our good work is.</p>
<a href="#">View Client Review</a>
        </div>
        <div className="whychoose-card">
        <span class="material-symbols-outlined">
calculate
</span>
<h4>No front Appraisal Fees!
</h4>
<p>We do not take any appraisal fees from our client, we don't take the hidden charge of any method.</p>
<Link to="/aboutus">Why Choose Us</Link>
        </div>
    </div>
    </div>
    
    </>
  )
}

export default WhyChoose
