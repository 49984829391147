import React from 'react'
import img from '../../images/location.jpeg'
import HelpYou from '../home/whychoose/HelpYou'
const CompanyBranch = () => {
  return (
    <>
        <div id="carouselExampleCaptions" class="carousel slide">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src={img} class="d-block w-100" alt="..." style={{ height: '45vh' }} />
                        <div class="carousel-caption d-md-block">
                            <h1 style={{ color: 'white' }}>Company Branch</h1>
                            <p style={{ color: 'white' }}>Home &nbsp; &#10095; &nbsp; Company Branch</p>
                        </div>
                    </div>
                </div>

            </div>
            <div className="heading">
                <h1>Branch</h1>
                <p style={{color:'gray',margin:'20px 0',textAlign:'center'}}>Company has a wide branch network in different states through which company rendered services to individual & corporate clients.</p>
            </div>
            <div className="container">
            <table class="table table-bordered border-primary">
  <thead>
    <tr>
      <th scope="col" style={{color:'red'}}>Location</th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">VARANASI</th>
      <td>OM 1/9 SHASTRI NAGAR SIGRA -221010</td>
    </tr>
  </tbody>
</table>
            </div>
            <HelpYou/>

    </>
  )
}

export default CompanyBranch
