import React,{useEffect} from 'react'
import './aboutcards.css'
import AOS from 'aos'
import 'aos/dist/aos.css'
import {Link} from 'react-router-dom'
const AboutCards = () => {
    useEffect(()=>{
        AOS.init({
            duration:1000
        })
    },[])
  return (
    <>
    <div className="container my-5 aboutcards">
        <div className="aboutcard" data-aos="fade-right">
            <div className="heading">
                <h1>About Company</h1>
                <p>Incorporated in Aug-2015,<strong> RIDDHISIDDHI FINANCE</strong> is a one of its kind financial services distribution firm catering to discerning individuals and corporate clientele at <strong>PAN INDIA</strong> level. It is promoted and managed by ex-banking industry professionals with expertise in Retail Asset products and experience ranging across multi-national.<br/>
              <Link to="/aboutus"><span>Read More</span></Link>
            </p>
            </div>
        </div>
        <div className="aboutcard" data-aos="fade-left">
            <div className="heading">
                <h1>Our Company Aim</h1>
                <p>To establish ourselves as a key consulting resource to cater various financial needs of Individual & Corporate Clients through Customized Solution.</p>

            </div>
        </div>
    </div>
    
    </>
  )
}

export default AboutCards
