import React from 'react'
import './about.css'
import img from '../../images/leadership.jpeg'
import HelpYou from '../home/whychoose/HelpYou'

const LeadershipTeam = () => {
  return (
    <>
     <div id="carouselExampleCaptions" class="carousel slide">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src={img} class="d-block w-100" alt="..." style={{ height: '45vh' }} />
                        <div class="carousel-caption d-md-block">
                            <h1 style={{ color: 'white' }}>Leadership Team</h1>
                            <p style={{ color: 'white' }}>Home &nbsp; &#10095; &nbsp; Leadership Team</p>
                        </div>
                    </div>
                </div>

            </div>
    <div className="container leadershipteam">
        <div className="leadership-card">
            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTv1Z24mCRWP14bP5owzI6eBr_PrvIAOsZ9Zg&usqp=CAU" alt="" />
            <div>
                <h2>Keshvendra</h2>
                <h4>Founder</h4>
                <p>With more than 12 years of in depth experience in different aspects of financial services,Founder & Director of Bhumi Finance, Mr. Pankaj Singh is a known name in the field. Under his guidance company has collaborated with many renowned banks in India and meeting its commitments. Completed his studies from MGKVP. He has won many awards in the sector for his continuous efforts and contributions.</p>
            </div>
        </div>
        <div className="leadership-card">
            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTv1Z24mCRWP14bP5owzI6eBr_PrvIAOsZ9Zg&usqp=CAU" alt="" />
            <div>
                <h2>Keshvendra</h2>
                <h4>Founder</h4>
                <p>With more than 12 years of in depth experience in different aspects of financial services,Founder & Director of Bhumi Finance, Mr. Pankaj Singh is a known name in the field. Under his guidance company has collaborated with many renowned banks in India and meeting its commitments. Completed his studies from MGKVP. He has won many awards in the sector for his continuous efforts and contributions.</p>
            </div>
        </div>
        <div className="leadership-card">
            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTv1Z24mCRWP14bP5owzI6eBr_PrvIAOsZ9Zg&usqp=CAU" alt="" />
            <div>
                <h2>Keshvendra</h2>
                <h4>Founder</h4>
                <p>With more than 12 years of in depth experience in different aspects of financial services,Founder & Director of Bhumi Finance, Mr. Pankaj Singh is a known name in the field. Under his guidance company has collaborated with many renowned banks in India and meeting its commitments. Completed his studies from MGKVP. He has won many awards in the sector for his continuous efforts and contributions.</p>
            </div>
        </div>
        <div className="leadership-card">
            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTv1Z24mCRWP14bP5owzI6eBr_PrvIAOsZ9Zg&usqp=CAU" alt="" />
            <div>
                <h2>Keshvendra</h2>
                <h4>Founder</h4>
                <p>With more than 12 years of in depth experience in different aspects of financial services,Founder & Director of Bhumi Finance, Mr. Pankaj Singh is a known name in the field. Under his guidance company has collaborated with many renowned banks in India and meeting its commitments. Completed his studies from MGKVP. He has won many awards in the sector for his continuous efforts and contributions.</p>
            </div>
        </div>
        <div className="leadership-card">
            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTv1Z24mCRWP14bP5owzI6eBr_PrvIAOsZ9Zg&usqp=CAU" alt="" />
            <div>
                <h2>Keshvendra</h2>
                <h4>Founder</h4>
                <p>With more than 12 years of in depth experience in different aspects of financial services,Founder & Director of Bhumi Finance, Mr. Pankaj Singh is a known name in the field. Under his guidance company has collaborated with many renowned banks in India and meeting its commitments. Completed his studies from MGKVP. He has won many awards in the sector for his continuous efforts and contributions.</p>
            </div>
        </div>
    </div>
    <HelpYou/>
    </>
  )
}

export default LeadershipTeam
