import React from 'react'
import img from '../../images/groupinsurance.jpeg'
import { Link } from 'react-router-dom'

import HelpYou from '../home/whychoose/HelpYou'
import '../personalloan/personalloan.css'
const GroupInsurance = () => {
    return (
        <>
            <div id="carouselExampleCaptions" class="carousel slide">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src={img} class="d-block w-100" alt="..." style={{ height: '45vh' }} />
                        <div class="carousel-caption d-md-block">
                            <h1 style={{ color: 'white' }}>Group Insurance</h1>
                            <p style={{ color: 'white' }}>Home &nbsp; &#10095; &nbsp; Group Insurance</p>
                        </div>
                    </div>
                </div>

            </div>
            <nav class="navbar navbar-dark bg-dark navbar-expand-lg navBar">
                <div class="container-fluid">
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                    <a class="nav-link" href="#Commercial">Commercial</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#Industrial">Industrial</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#Liability">Liability</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#Cover">Group Cover</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#Life">Group Life</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#">Corporate</a>
                </li>
                        </ul>
                        <a href="https://wa.me/919506010080"><button class="btn btn-outline-wtsap my-2 my-sm-0" style={{ color: 'white', backgroundColor: 'green', borderRadius: '5px', padding: '6px 15px', marginLeft: '10px' }}><i class="fa fa-whatsapp"></i>&nbsp;Whatsapp</button></a>
                        <Link to="/getappointment"> <button class="btn btn-outline-apply my-2 my-sm-0" type="submit" style={{ color: 'black', backgroundColor: 'white', borderRadius: '5px', padding: '6px 15px', marginLeft: '10px' }}>Apply Now</button></Link>

                    </div>
                </div>
            </nav>
            <div id="Commercial"></div>
    <section class="main-body">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h3> Commercial Insurance </h3>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="testmonial-box-5">
                    <ul class="listnone bullet bullet-check-circle-default">
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Office Package. 
                    </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                      Fidelity Guarante. 
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Money Insurance.
                      </li> 
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Plate Glass Insurance.
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Art Insurance.
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Jewellers Block Insurance.
                      </li><br/>
                    </ul>
                    
                    <h5>Office Package Insurance</h5>
                        <p>The Office Package policy is designed to offer comprehensive protection to your office property. This office insurance plan covers the office building as well as the contents of your office against the hazards of fire and allied perils like riots, strikes, floods, earthquakes, explosion/implosion and impact damage.</p>
                        <p>This office package policy also covers your office against the risk of burglary, housebreaking theft and unfortunate events such as loss of money in transit or fidelity guarantee (dishonesty of employees) that may cause disruption to the functioning of offices as well as serious financial implication to your business. With our office insurance options, you can focus on your business and ensure its smooth functioning.</p>
                        <p>
                          We at Bhumi Finance Brokers Ltd, provide you a one-stop solution for product comparison on office package policies from leading corporate insurance companies with the best quotes and product features.
                        </p><br/>
                        <h6>Key Benefits :</h6>
                        <ul>
                          <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                              Save time for quote comparison and product features as the policy package is designed as per corporate insurance requirements.
                          </li>
                          <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                          Comprehensive support through our dedicated customer support team.
                            </li>
                            <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                              Domain expertise to help get the accurate coverage at the right price.
                            </li>
                        </ul><br/>
                      

        
                        <h5>Fidelity Guarantee Insurance</h5>
                          <p>This Policy broadly covers pecuniary loss sustained as a result of act of fraud or dishonesty in respect of monies or goods of the employer committed by the employees in the course of performance of their duties.</p>
                          <p>This policy is suitable for employees occupying positions of trust such as cashier and storekeeper.</p><br/>
                      

                  
                        <h5>Money Insurance</h5>
                        <p>Money Insurance policy provides cover for loss of money in transit between the insured's premises and bank or post office,or other specified places occasioned by robbery, theft or any other fortuitous cause.</p>
                        <p>The policy also covers loss by burglary or housebreaking whilst money is retained at Insured's premises in safe(s) or strong room.</p><br/>
                      

                      
                        <h5>Plate Glass Insurance</h5>
                        <p>This Policy covers loss or damage to the plate glass occasioned by accidental breakage. The covers applies to plate glass, glass doors, windows and glass frontage of buildings.</p>
                        <p>The Policy can be extended to cover Frames and framework up to a specific limit but only as a consequence of breakage of insured glass.</p><br/>

                      
                        <h5>Art Insurance</h5>  
                        <p>This coverage is available to all private individuals or entities collecting and maintaining an art collection on a non commercial basis. This would also include business houses maintaining their private art collections.</p>
                        <p>As a commercial gallery owner or private dealer, you want to make sure that proper coverage is in place for your consigned items or those owned by you or the gallery. It is also important that the proper amount of coverage is in place for items that are in transit or when they are on view with your client or at an art fair, framer, conservator or any other unnamed location. And in case of loss, you want to make sure that the valuation clause is appropriate and that the claim will be paid fairly and expeditiously.</p>
                        <p>Accidental loss or damage to the insured art piece(s), including when the art is in transit</p><br/>
                

                    
                        <h5>Jewellers Block Insurance</h5>
                        <p>This is a package policy specially designed for jewellers &amp; diamontaires i.e. those establishments dealing solely in diamonds.</p>
                        <p>Jewellers premises are categorized into Class I, II or III depending upon the type of security provided for the premises.</p>
                        <p>Discount in premium is available in case the premises have special protection devices like built-in vaults, strong rooms, closed circuit T.V. or armed guards.</p>

                  </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div id="Industrial"></div>
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h3>Industrial Insurancce </h3>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="testmonial-box-5">
                    <ul class="listnone bullet bullet-check-circle-default">
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Burglary Policy
                    </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Contractors All Risk.
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Constractors Plant and Machinery.
                      </li> 
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                         Electronics Equipment Policy.
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Fire Policy
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Machinery Breakdown Policy.
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Erection All Risk Policy.
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Bolier &amp; Pressure Plant.
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Industrial All Risk Policy.
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Trade Credit Insurance
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Consequential Loss Insurance.
                      </li>
                    </ul>
                    
                    <div class="col-12" style={{marginTop:"50px"}}>
                    <h5>Burglary Policy</h5>
                      <p>Policy is designed to cover loss or damage to the property insured by theft following actual, forcible and violent entry into the premises. It also covers cash, valuables, securities kept in a locked safe or cash box in locked steel cupboard on specific request.</p>
                      <p>It is possible to extend the policy to include loss of the insured property to cover burglary as a result of riot &amp; strike risks &amp; theft and larceny not accompanied by violent ingress or exit. The extension does not cover losses detected during routine stock taking / checking.</p>

                    <p><strong>There are three types of policies available:-</strong></p><br/>
                          <ul class="listnone bullet bullet-check-circle-default">
                              <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                                <strong>Full Value Basis:</strong><br/>
                                The policy must be effected for the full value of the property to be insured.
                              </li>
                              <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                                <strong>First Loss Basis:</strong><br/>
                                In the event of improbability of total loss, proposer can opt for a percentage of total stocks to be insured.
                              </li>
                              <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                                <strong>Declaration Basis:</strong><br/>
                                These policies are given where large stocks frequently fluctuate in quantity during the year. Monthly declarations of value are to be sent to the company and premium will be adjusted at the end of the policy period.
                              </li>
                          </ul>
                        </div>

                        <div class="col-12" style={{marginTop:"50px"}}>
                          <h5>Contractors All Risk Policy</h5>
                          <p>This policy is specially designed to give financial protection to the Civil Engineering Contractors in the event of an accident to the civil engineering works under construction.</p>
                          <p><strong>The policy comprises of 2 sections:</strong></p>
                             <ul class="listnone bullet bullet-check-circle-default">
                              <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                                Section I-Material Damage-covering physical loss, damage or destruction of the property insured by any cause, other than those specifically excluded in the policy. 
                               </li>
                             <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                               Section II-Third Party Liability-covering the legal liability falling on the insured contractor as a result of bodily injury or property damage belonging to a third party.
                               </li>
                             </ul>
                        </div>

                        <div class="col-12" style={{marginTop:"50px"}}>
                          <h5>Contractors Plant and Machinery Policy</h5>
                            <p>This policy covers all different types of machinery used for handing material or construction &amp; project site.</p>
                            <p>The policy covers sudden, accidental, external damage to the insured machinery due to any cause other than those specifically excluded in the policy.</p>
                            <p>The policy covers the machinery whilst they are in operation or at rest or whilst being dismantled for the purpose of cleaning or overhauling or whilst being shifted within the premises or during subsequent re-erection, but in any case only after successful commissioning.</p>
                        </div>

                        <div class="col-12" style={{marginTop:"50px"}}>
                          <h5>Electronics Equipment Policy</h5>
                          <p>This is a specially designed policy which covers accidental loss or damage to non portable &amp; immobile electronic equipment.</p>
                          <p>This policy covers entire range of Electronic Equipment from a Computer to sophisticated gadgets like EPABX, EDP Machines, etc.</p>
                          <ul class="listnone bullet bullet-check-circle-default">
                              <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                                <strong>SECTION I:</strong> Covers any unforeseen and sudden physical loss or damage to Electronic Equipments from any cause, other than those excluded.
                              </li>
                              <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                                <strong>SECTION II:</strong> Covers External Data Media and the expenses for reconstruction and rerecording of information.
                              </li>
                              <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                                <strong>SECTION III:</strong> Covers Increased Cost of Working
                              </li>
                          </ul>
                        </div>

                        <div class="col-12" style={{marginTop:"50px"}}>
                          <h5>Fire Policy</h5>
                            <p>The Policy covers the damage to the assets due to fire &amp; other special perils like Fire, lightning, explosion / implosion, impact damage, aircraft impact, bursting / overflowing of water tanks / pipes / apparatus, sprinkler leakage, storm, cyclone, typhoon, hurricane, tempest, tornado, flood, inundation, subsidence, landslide (including rock slide), riots, strikes, malicious damage, missile testing operations. Additionally, there are options to insure Earthquake and Terrorism risks and a host of other add-on covers</p>
                        </div>

                        <div class="col-12" style={{marginTop:"50px"}}>
                          <h5>Machinery Breakdown Policy</h5>
                            <p>The policy covers sudden and unforeseen accidental damage to machinery.</p>
                            <p>The machinery can be at work or at rest.</p>
                            <p>Machinery being dismantled for cleaning / overhauling and in course of these operations being shifted within specified premises or being re-erected subsequently also covered.</p>
                            <p>Policy can also be extended to cover: Escalation, Express Freight (excluding Air Freight), Overtime and Holiday Rates of Wages, Air Freight, Owner's Surrounding Property, Third Party Liability, Additional Customs Duty.</p>
                        </div>

                        <div class="col-12" style={{marginTop:"50px"}}>
                          <h5>Erection All Risk Policy</h5>
                          <p>Marine Cum Erection / Storage-cum Erection Policy</p>
                          <p>Erection All Risks (EAR) policy is designed to cover Plant and Machinery under erection. Interest of Suppliers / Manufacturers, Contractors, Sub-Contractors can be recorded in the policy.</p>
                          <p>This policy covers risks associated with storage, assembly / erection and testing of Plant and Machinery. EAR insurance provides comprehensive cover. All perils are covered unless specifically excluded. Cover incepts from the time of unloading of the first consignment at the project site and terminates on completion of testing or handing over of the project to the Principal, of the period chosen, whichever is earlier.</p>
                          <p>Policy can be extended on payment of additional premium to cover Maintenance Cover, Damage to Owner's Surrounding Property, Third Party Liability, Clearance and Removal of Debris, Additional Customs Duty, Express Freight, Holiday and Overtime rates and wages.</p>
                        </div>

                        <div class="col-12" style={{marginTop:"50px"}}>
                          <h5>Boiler &amp; Pressure Plant Insurance</h5>
                            <p>The policy covers damage other than by fire, to boilers &amp; pressure plant &amp; damage to surrounding property.</p>
                            <p>The policy can be extended to cover third party legal liability arising due to explosion and collapse of the plant during its normal course of working.</p>
                        </div>

                        <div class="col-12" style={{marginTop:"50px"}}>
                          <h5>Industrial All Risk Policy</h5>
                          <p>All industrial risks (other than risks rateable under Petrochemical Tariff) having overall Sum Insured of Rs. 100 Crores and above in one or more locations in India are eligible.</p>
                          <p>This is a package policy with the following covers:</p>
                        
                        <ul class="listnone bullet bullet-check-circle-default">
                          <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                            Fire and Special Perils including Flood, Storm, Tempest.
                          </li>
                          <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                            Earthquake, Fire &amp; Shock.
                          </li>
                          <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                            Consequential Loss (Fire).
                          </li>
                          <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                            Machinery Breakdown, Boiler Explosion, Electronic Equipment.
                          </li>
                          <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                            Machinery Breakdown - Optional.
                          </li>
                        </ul>
                        </div>


                        <div class="col-12" style={{marginTop:"50px"}}>
                          <h5>Trade Credit Insurance</h5>
                          <p>Credit Insurance offers one of efficient and cost effective ways of managing credit risk for any enterprise. The credit risk can exist on trade receivables internationally or even on account of domestic sales. Most trade whether domestic or exports are carried out on credit terms and one of the major risks that the enterprise may face is the overdue payments or protracted default against sales invoiced.</p>
                          <p>The Policy covers following risk.</p>
                          <p>Commercial Risk: these can be in the nature of Protracted default or delayed payments by debtors (the risk of non-payment even if he is NOT officially insolvent) / Insolvency of the buyer.</p>
                          <p>Political Risk: can be any activity - political or economic difficulty preventing or delaying payment of a transaction and can be in the nature of Natural disasters, cancellation of import license of the buyer, protracted default on state owned entities, measures taken by host country to prevent payment of transaction.</p>
                        </div>

                        <div class="col-12" style={{marginTop:"50px"}}>
                          <h5>Consequential Loss Insurance</h5>
                          <p>This policy operates in the event of damage to the premises of an insured due to any peril insured under the Standard Fire &amp; Special Perils Policy, causing an interruption to his business. It enables the insured to recover :</p>
                          <ul class="listnone bullet bullet-check-circle-default">
                            <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                              His Loss of Gross Profit due to a reduction in turnover
                            </li>
                            <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                              Increased Cost of Working incurred in minimizing that loss of Gross Profit
                            </li>
                          </ul>
                          <p>The policy can be Extended to cover Wages, Lay-off and Retrenchment Compensation and Notice Wages Liability, Auditor's Fees, Supplier's Extension, Customer's Extension, Insured's Property stored at other locations, Public Utilities Extension</p>
                          </div>
                  </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div id="Liability"></div>
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h3> Liability Insurance </h3>
                        <div class="row">
                            <div class="col-md-12">
                               <div class="testmonial-box-5">
                     <ul class="listnone bullet bullet-check-circle-default">
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Cyber Insurance.
                    </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Directors &amp; Officers Liability.
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Professional Indemnity Policy.
                      </li> 
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                         Products Liability Policy.
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Commercial General / Public Liability Policy.
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Workmen's Compensation Policy.
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        IPO Insurance.
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Media Liability Insurance.
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Public Liability Act Policy.
                      </li>
                    </ul>

                    <div class="col-12" style={{marginTop:"50px"}}>
                      <h5>Cyber Insurance</h5>
                      <p>Our reliability on the technology is increasing day by day. Our life is getting dependent on the digital world of mobile and internet banking, social media, online shopping and various other online activities. Thus, we end up spending significant amount of our time on the internet.</p>
                      <p>In the process of doing online activities, like logging in to our social media account, paying taxes online, online shopping, internet banking etc. we share our key information or data with certain entities, because it helps us in accomplishing our online activities easily, but at the same time, it is associated with gamut of Cyber Risks, such as Identity Theft, Malware Attack, IT Theft Loss, Phishing, E-mail Spoofing, Cyber Extortion, Cyber Stalking, Media Wrongful Act etc. to which individuals are exposed to, due to Cyber Attacks.</p>
                      <p>Cyber Insurance is a policy which provides a comprehensive insurance cover to an individual or business to pay for the losses that could arise if the Cyber Risks are subjected to Cyber Attacks.</p><br/>
                      <p>Cyber Insurance Policy covers the following -</p>
                          <ul class="listnone bullet bullet-check-circle-default">
                            <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                              Coverage Of Financial Losses (as per defined t&amp;c in the product brochure)
                            </li>
                            <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                              Cover of Expenses incurred:
                                <ul>
                                    <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                                      Legal Fee for claiming damage fee against third party
                                    </li>
                                    <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                                      Restoration costs of the Insured’s computer system due to damage caused by Malware
                                    </li>
                                    <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                                      Coverage of the expenses of an certified psychiatrist, psychologist or counselor to treat the Insured for stress, anxiety
                                    </li>
                                    <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                                      Coverage of the cost of hiring an IT consultant in order to prove the amount and the extent of the cyber attack and losses attached to it
                                    </li>
                                </ul>
                            </li>
                          </ul>
                        <p>We at Bhumi Finance Brokers, ensure that your all the queries related to cyber risks are resolved. Not only this, our dedicated and expert claim team will help you to get your claim, in-case of an unforeseen event of Cyber Attack.</p>
                    </div>

                    <div class="col-12" style={{marginTop:"50px"}}>
                      <h5>Directors and Officers Liability Policy</h5>
                        <p>The Directors and Officers liability policy stands out to bail out the directors or officers in the times of difficulty when they are sued for damages for allegedly taking wrong decisions. However, the risks associated with such policies are a plenty.</p>
                         <ul class="listnone bullet bullet-check-circle-default">
                            <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                              Unfair trading, Illegal price fixing
                            </li>
                            <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                              Infringement of copyright or patent law
                            </li>
                           <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                            Abuse of authority
                           </li>
                          <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                            Mismanagement
                          </li>
                        <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                            Controversial financial transactions.
                        </li>
                        <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                            Misstatements
                        </li>
                        <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                            Breach of confidentiality
                        </li>
                        <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                            Libel and slander cases.
                        </li>
                      </ul><br/>
                        <p>The Directors and Officers Liability Policy thus covers</p>
                        <ul class="listnone bullet bullet-check-circle-default">
                          <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                            The damages awarded against the directors
                          </li>
                          <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                            The defence costs.
                          </li>
                          <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                            Insurers also cover criminal allegations but only if the director is found innocent
                          </li>
                        </ul>
                        <p>Such liabilities may arise out of legal action from Shareholders, Investigation by regulatory bodies, Competitors, Consumers and Clients, Employees Third parties.</p>
                   </div>

                   <div class="col-12" style={{marginTop:"50px"}}>
                     <h5>Professional Indemnity Policy</h5>
                     <p>This policy is meant for professionals to cover liability falling on them as a result of errors and omissions committed by them whilst rendering professional service.</p>
                     <p>The policy offers a benefit of Retroactive period on continuous renewal of policy whereby claims reported in subsequent renewal but pertaining to earlier period after first inception of the policy, also become payable.</p>
                      <p>Group policies can also be issued covering members of one profession. Group discount in premium is available depending upon the number of members covered.</p>
                   </div>

                   <div class="col-12" style={{marginTop:"50px"}}>
                     <h5>Products Liability Policy</h5>
                     <p>This policy covers all sums (inclusive of defence costs) which the insured becomes legally liable to pay as damages as a consequence of:</p>
                     <ul class="listnone bullet bullet-check-circle-default">
                        <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                          Accidental death / bodily injury or disease to any third party.
                        </li>
                        <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                          Accidental damage to property belonging to a third party.
                        </li>
                     </ul>
                     <p>Arising out of any defect in the product manufactured by the insured and specifically mentioned in the policy after such product has left the insured's premises.</p>
                     <p>The policy offers the benefit of retroactive period on continuous renewal of policy whereby claims reported in subsequent renewal but pertaining to earlier period after first inception of policy, also become payable.</p>
                   </div>

                   <div class="col-12" style={{marginTop:"50px"}}>
                    <h5>Commercial General / Public Liability Policy</h5>
                     <p>This policy covers the amount which the insured becomes legally liable to pay as damages to third parties as a result of accidental death, bodily injury, loss or damage to the property belonging to a third party. The legal cost and expenses incurred in defending the case with prior consent of the insurance company are also payable subject to certain terms and conditions.</p>
                     <p>One can insure more than one unit situated in different locations under a single policy.</p>
                     <p>The policy offers a benefit of Retroactive period on continuous renewal of policy whereby claims reported in subsequent renewal but pertaining to earlier period after first inception of the policy, also become payable.</p>
                   </div>


                   <div class="col-12" style={{marginTop:"50px"}}>
                     <h5>Workmen's Compensation Policy</h5>
                     <p>The Indian Workmen's Compensation Act 1923 provides for the payment of compensation by the employer to his employees (or their dependents in the event of fatal accidents) if personal injury is caused to them by accidents arising out of and in the course of their employment.</p>
                     <p>The maximum compensation payable is upon the following scale as per W.C. Amendment Act 2000 &amp; its amendment</p>
                     <ul class="listnone bullet bullet-check-circle-default">
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Fatal Injury
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Permanent Total Disablement
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Permanent Partial Disablement (According to incapacity)
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Temporary Disablement (Half monthly compensation)
                      </li>
                     </ul>
                     <p>The policy can be provided with extension under table "A" by charging an additional premium of 50% of book rate to cover disease mentioned in part 'C' of Schedule III of Workmen's Compensation Act.</p>
                   </div>

                   <div class="col-12" style={{marginTop:"50px"}}>
                     <h5>IPO Insurance</h5>
                     <p>The policy provides protection from the following in related to Initial Public Offer of a company:</p>
                     <ul class="listnone bullet bullet-check-circle-default">
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Wrongful Acts of the company and its directors arising from the issue of a prospectus
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Insurance Cover for the underwriter or sponsor of the Issue
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Insurance Cover for third parties associated with the issue
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Security claims equity or debt both initial and secondary
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Liability arising from prior negotiations, discussions and decisions in connection with the offering
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        US and Non US exposures, including SEC registered listings
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Misstatement in prospectus and listing particulars
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Includes Selling shareholders as well (could be VCAPs, private equity investors, FIIs etc)
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Includes Family holding company to the extent that they are selling their shares in the company
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Public Relationship expenses
                      </li>
                     </ul>
                   </div>

                   <div class="col-12" style={{marginTop:"50px"}}>
                     <h5>Media Liability Insurance</h5>
                     <p>The Policy provides indemnity for a legal liability claim made on the insured for a Wrongful Act, Error or Omission of the Insured or any other person for whom the Insured is legally responsible, for loss caused to others, in the performance of professional Multimedia services for others for a fee or compensation.</p>
                     <ul class="listnone bullet bullet-check-circle-default">
                        <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                          Professional Liability - Breach of duty
                        </li>
                        <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                          Infringement of intellectual property
                        </li>
                        <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                          Fraud/dishonesty
                        </li>
                        <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                          Defamation - Libel/Slander
                        </li>
                        <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                          Defence costs
                        </li>
                     </ul>
                   </div>


                  <div class="col-12" style={{marginTop:"50px"}}>
                    <h5>Public Liability Act Policy</h5>
                    <p>In terms of Public Liability Insurance Act, 1991, all "owners" "handling" "hazardous substances" are compulsorily required to take an insurance policy to the extent laid down in the Act.</p>
                    <p>"Owners" means persons owning or having control over handling of hazardous substances at the time of accident.</p>
                    <p>"Handling" means manufacturing, processing, treatment, packaging, storing, transportation, use, collection, destruction, conversion, offering for sales, transfer or any similar form of dealing with hazardous substances.</p>
                    <p>"Hazardous Substances" means the items listed and grouped under Public Liability Insurance Act, 1991 and the Rules framed there under.</p>
                    <p>This Insurance Policy broadly covers the Owner's statutory liability on no-fault principle for death or injury to any person or damage to property resulting from an accident while handling any hazardous substance.</p>
                  </div>
                  </div> 
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div id="Cover"></div>
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h3> Group Cover Insurance </h3>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="testmonial-box-5">
                    <ul class="listnone bullet bullet-check-circle-default">
                     <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                       Group Domestic Mediclaim
                     </li>
                     <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                       Corporate Overseas Travel
                     </li>
                     <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                       Group Personal Accident
                     </li>
                     </ul> 
                      
                      <div class="col-12" style={{marginTop:"50px"}}>
                        <h5>Group Domestic Mediclaim</h5>
                        <p>In terms of Public Liability Insurance Act, 1991, all "owners" "handling" "hazardous substances" are compulsorily required to take an insurance policy to the extent laid down in the Act.</p>
                        <p>"Owners" means persons owning or having control over handling of hazardous substances at the time of accident.</p>
                        <p>"Handling" means manufacturing, processing, treatment, packaging, storing, transportation, use, collection, destruction, conversion, offering for sales, transfer or any similar form of dealing with hazardous substances.</p>
                        <p>"Hazardous Substances" means the items listed and grouped under Public Liability Insurance Act, 1991 and the Rules framed there under.</p>
                        <p>This Insurance Policy broadly covers the Owner's statutory liability on no-fault principle for death or injury to any person or damage to property resulting from an accident while handling any hazardous substance.</p>
                        <p><strong>Salient Features</strong></p>
                        <ul class="listnone bullet bullet-check-circle-default">
                          <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                            Enjoying the mediclaim (hospitalization benefit) under group policy.
                          </li>
                          <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                            Group Discount on premium is available for a group size above 100.
                          </li>
                          <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                            Depending on the claim ratio, a Bonus or Malus in the form of discount or loading on the premium is allowed / charged.
                          </li>
                          <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                            At extra premium Maternity benefit Extension and pre-existing disease covers are available which are not available under individual mediclaim policy.
                          </li>
                          <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                            Option of deletion of Domiciliary Hospitalization benefits can be availed so as to get discount.
                          </li>
                          <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                            No cumulative bonus is available on individual basis.
                          </li>
                        </ul>
                      </div>

                      <div class="col-12" style={{marginTop:"50px"}}>
                        <h5>Corporate Overseas Travel</h5>
                        <p><strong>Salient Features</strong></p>
                        <ul class="listnone bullet bullet-check-circle-default">
                          <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                            This policy has been designed for corporate clients having people traveling overseas on a regular basis throughout the year.
                          </li>
                          <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                            The master policy will be issued to the company. Certificates will also be issued Online, so that each time an employee travels the authorized person in the company will issue the policy Online.
                          </li>
                          <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                            The company will keep a deposit for an amount covering 30 days premium. The billing will be done on a weekly basis.
                          </li>
                          <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                            Hence as the billing will be done on a weekly basis, the company needs to inform only when the employee returns to India, in which case only the exact number of days are billed.
                          </li>
                          <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                            Under this policy there are no cancellation charges involved in case of change or cancellation of the program.
                          </li>
                          <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                            Under this policy premium is charged on a per day basis, hence the company will be paying for the exact number of days a person travels, instead of on a slab basis.
                          </li>
                          <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                            This policy is completely hassle free &amp; paperless and reduces administrative work to the minimum.
                          </li>
                        </ul>
                      </div>

                      <div class="col-12" style={{marginTop:"50px"}}>
                        <h5>Group Personal Accident</h5>
                          <p><strong>Salient Features</strong></p>
                          <ul class="listnone bullet bullet-check-circle-default">
                            <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                              It covers accidental death and all sort of disability both permanent and temporary.
                            </li>
                            <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                              Enjoying the personal accident cover under group policy.
                            </li>
                            <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                              Group Discount on premium is available for a group size above 100.
                            </li>
                            <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                              Depending on the claim ratio, a Bonus or Malus in the form of discount or loading on the premium is allowed / charged.
                            </li>
                            <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                              At extra premium medical benefit Extension and hospitalization cash covers are available.
                            </li>
                            <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                              No cumulative bonus is available on individual basis.
                            </li>
                          </ul>
                      </div>
                  </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div id="Life"></div>
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h3> Group Life Insurance </h3>
                        <div class="row">
                            <div class="col-md-12">
                               <div class="testmonial-box-5">
                    <ul class="listnone bullet bullet-check-circle-default">
                     <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                       Group Term Insurance.  
                     </li>
                     <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                       Group EDLI Insurance Scheme.
                     </li>
                     <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                       Group Gratuity Plan.
                     </li>
                     <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                       Group Superannuation Scheme.
                     </li>
                     </ul> 

                     <div class="col-12" style={{marginTop:"50px"}}>
                       <h5>Group Term Insurance</h5>
                       <p>Group Term Insurance Scheme is meant to provide life insurance protection to groups of people. Administration of the scheme is on group basis and cost is very low. Under Group Term Insurance Scheme, life insurance cover is allowed to all the members of a group subject to some simple insurability conditions without insisting upon any medical evidence.</p>
                       <p>Group Term Insurance Scheme is at present offered under One Year Renewable basis.</p>
                       <p>The Premium rates applicable to a group at commencement depend on its size as also the occupation, employment and working conditions of the members of the group. Subsequently, the Premium Rates may be reviewed on the basis of actual experience. In some schemes, a part of the excess of premium over actual claim is allowed as rebate from subsequent year's premium as Experience Rating adjustment or Profit Sharing if the claim experience is favorable.</p>
                     </div>

                     <div class="col-12" style={{marginTop:"50px"}}>
                       <h5>Group EDLI Insurance Scheme</h5>
                       <p>All employers to whom the Employee's Provident Fund and Miscellaneous Provision Act, 1952 applies, have a Statutory liability to subscribe to Employee's Deposit Linked Insurance Scheme, 1976 to provide for the benefit of Life insurance to all their employees.</p>
                       <p>Under the scheme as amended with effect from 24th June, 2000 the insurance benefit is equal to the average balance to the credit of the deceased employee in the Provident Fund during the last 12 months, provided that where such balance exceeds Rs.35, 000, insurance cover would be equal to Rs.35,000 plus 25% of the amount in excess of Rs.35,000 subject to a maximum of Rs.60,000. Thus if the length of service is not adequate and/ or the salary is low the average balance may be substantially less and as such the benefit to the employee's family is either inadequate or non-existent.</p>
                       <p>The contribution @ 0.50% of each employee's salary is payable by the Employer to the Provident Fund Authorities. In addition an administration and inspection charge of 0.01% is also payable to RPFC.</p>
                       <p>Under Sec. 17(2A) of the Act, the employer may be exempted from contributing to this scheme, if he/she has provided for better insurance benefits through an alternative scheme. The insurance provider should be approved by the RPFC.</p>
                     </div>

                     <div class="col-12" style={{marginTop:"50px"}}>
                       <h5>Group Gratuity Plan</h5>
                       <p>Under the Payment of Gratuity Act, 1972, it is employer's statutory liability to pay 15 days salary (15/26 of a month's wages) for every completed year of service to each of his employees on their exit, for any reason after five years of continuous service, subject to maximum limit of 3.5 lacs. Higher benefits can be paid if the employer so desires.</p>
                       <p>Gratuity payable to the employees can be paid as and when liability arises and can be claimed as deductible expense under P &amp; L A/c of the relevant financial years. However, the sound system of financial management envisages providing for Gratuity liability every year and claiming the tax benefits as it is mandatory as per Accounting Standards 15 (AS15) to account for the liability on Actual basis.</p>
                       <p>This can be done by creating a Trust, managed privately or by insurance company and paying the amount to the Trust every year. In case of Privately Managed Trust, investment of funds will have to be done as per Income-Tax Act by the trustees and entire administration of the Trust including Actuarial Valuation will be the responsibility of the Trustees</p>
                       <p>In case of insurance company managed trust, the job of investment and actuarial valuation is taken over by the corporation free of charge and in addition, interest is paid by the Corporation on the accumulated funds.</p>
                       <p>The employer has to pay an initial contribution at the inception of the scheme to secure past Service gratuity. The initial contribution may be paid in lump sum or spread over a maximum period of five years. The corporation determines contribution payable as annual premium, under the policy, on the basis of an actuarial variation of the gratuity liability subject to the statutory limit of 81/3% of the annual wage bill taking into consideration the relevant factors. When the Trustees pay the contribution under the policy, the amount required towards the premium for life insurance benefits is utilized and balance is credited to the running account of the scheme which accumulates at an interest rate declared by insurance company form time to time.</p>
                       <p>When the contingency of payment of gratuity arises the necessary amount is withdrawn from the running account for making payment. Upon a claim arising by death the gratuity pertaining to the past service is withdrawn from the running account and the balance is paid from insurance company's Life fund.</p>
                     </div>

                     <div class="col-12" style={{marginTop:"50px"}}>
                      <h5>Group Superannuation Scheme</h5>
                      <p>An organization today, has not only to man the various positions with competent and trained personnel but also has to create an environment wherein they can give their best and derive a sense of well-being, a sense of fulfillment and security and take pride in their continued association with the organization.</p>   
                      <p>Provision of pension may be an attraction for such persons to continue in the organization and give their best to the organization, as with continuous improvement in longevity a regular income even after retirement has become a necessity.</p>  
                      <p>To provide the pension benefits to employees, an employer has two alternatives under the provisions of Rule 89 of Income Tax Rules 1962.</p>  
                      <ul class="listnone bullet bullet-check-circle-default">                
                          <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                            Create a privately managed trust fund and as and when a member retires, purchase annuity from insurance company to provide pension for such retiring member.
                          </li>
                          <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                            Entrust the Management of the Pension Fund to insurance company by purchasing its Group Superannuation Scheme.
                          </li>
                      </ul>
                     </div>

                   </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h3> Corporate Insurance Brochure </h3>
                        <div class="row">
                            <div class="col-md-12">
                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
    </section>


            <HelpYou />
        </>
    )
}

export default GroupInsurance
