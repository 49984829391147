import React from 'react'
import './basicstyle.css'
import img from './images/logo.jpeg'
import { Link } from 'react-router-dom'
const Header = () => {
  return (
    <>
   <nav class="navbar navbar-expand-lg sticky-top"  style={{backgroundColor:'yellow'}}>
  <div class="container-fluid">
    <img src={img} alt="" style={{width:'100px',height:'50px'}}/>
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav me-auto mb-2 mb-lg-0">
        <li class="nav-item">
          <Link class="nav-link active" aria-current="page" to="/">Home</Link>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            About
          </a>
          <ul class="dropdown-menu">
            <li><Link class="dropdown-item" to="/aboutus">About Us</Link></li>
            <li><Link class="dropdown-item" to="/leadershipteam">Leadership Team</Link></li>
            <li><Link class="dropdown-item" to="/companybranch">Company Branch</Link></li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Personal Loan
          </a>
          <ul class="dropdown-menu">
            <li><Link class="dropdown-item" to="/personalloan">Personal Loan</Link></li>
            <li><a class="dropdown-item" href="#types">Personal Loan Types</a></li>
            <li><a class="dropdown-item" href="#why">Why RIDDHISIDDHI FINANCES</a></li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Home Loan
          </a>
          <ul class="dropdown-menu">
            <li><Link class="dropdown-item" to="/homeloan">Home Loan</Link></li>
            <li><a class="dropdown-item" href="#eli">Eligibility Criteria</a></li>
            <li><a class="dropdown-item" href="#document">Documents Required</a></li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Business Loan
          </a>
          <ul class="dropdown-menu">
            <li><Link class="dropdown-item" to="/businessloan">Business Loan</Link></li>
            <li><a class="dropdown-item" href="#eli">Eligibility Criteria</a></li>
            <li><a class="dropdown-item" href="#document">Types of Business Loan</a></li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Car Refinance
          </a>
          <ul class="dropdown-menu">
            <li><Link class="dropdown-item" to="/loanagainstcar">Loan Against Car</Link></li>
            <li><Link class="dropdown-item" to="/balancetransfer">Car Loan Balance Transfer + Topup</Link></li>
            <li><Link class="dropdown-item" to="/refinancepurchase">Refinance Purchase</Link></li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Other Loan
          </a>
          <ul class="dropdown-menu">
            <li><Link class="dropdown-item" to="/goldloan">Gold Loan</Link></li>
            <li><Link class="dropdown-item" to="/loanagainstproperty">Loan Against Property</Link></li>
            <li><Link class="dropdown-item" to="/carloan">Car Loan</Link></li>
          </ul>
        </li>
        <li class="nav-item dropdown">
          <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
            Insurance
          </a>
          <ul class="dropdown-menu">
            <li><Link class="dropdown-item" to="/reinsurance">Reinsurance</Link></li>
            <li><Link class="dropdown-item" to="/generalinsurance">General Insurance</Link></li>
            <li><Link class="dropdown-item" to="/groupinsurance">Group Insurance</Link></li>
            <li><Link class="dropdown-item" to="/healthinsurance">Health Insurance</Link></li>
            <li><Link class="dropdown-item" to="/lifeinsurance">Life Insurance</Link></li>

          </ul>
        </li>
      </ul>
      <button className='button'><Link to="/contact">Contact</Link>

      </button>
    </div>
  </div>
</nav>
    </>
  )
}

export default Header;