import React from 'react'
import './applicationprocess.css'
const ApplicationProcess = () => {
  return (
    <div className="container-fluid py-5" style={{backgroundColor:"rgba(232, 232, 232, 0.726)"}}>
    <div className="container application-cards">
            <div className="heading">
                <h1>Fast & Easy Application Process</h1>
            </div>
            <div className="application " style={{margin:'100px'}}>
                <div className="application-card">
                    <div className="num">1</div>
                    <h4>Choose Loan Amount</h4>
                    <p>Choose the loan amount according to your need.</p>
                </div>
                <div className="application-card">
                    <div className="num">2</div>
                    <h4>Approved Your Loan</h4>
                    <p>We approve your loan upfront and provide it soon.</p>
                </div>
                <div className="application-card">
                    <div className="num">3</div>
                    <h4>Get Your Cash</h4>
                    <p>We provide your loan as soon as possible.</p>
                </div>
            </div>
        </div>
        </div>
  )
}

export default ApplicationProcess
