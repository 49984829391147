import React from 'react'

const Call = () => {
  return (
    <>
        <div className="call">
            <a href="tel:+91-9506010080" title=" +91-9506010080" style={{textDecoration:'none'}}>
                <img src="https://w7.pngwing.com/pngs/489/957/png-transparent-phone-call-icon-illustration-samsung-galaxy-s-plus-whatsapp-android-phone-text-trademark-logo.png" alt="" style={{width:'50px',height:'50px',borderRadius:'50%'}} />
            </a>
        </div>
    </>
  )
}

export default Call
