import React from 'react'
import img from '../../images/carloan.jpeg'
import img1 from '../../images/bt1.jpeg'
import img3 from '../../images/cl2.jpeg'
import img2 from '../../images/pl3.jpeg'
import img4 from '../../images/bt4.jpeg'
import img5 from '../../images/cl1.jpeg'
import { Link } from 'react-router-dom'

import HelpYou from '../home/whychoose/HelpYou'
import '../personalloan/personalloan.css'
const RefinancePurchase = () => {
    return (
        <>
            <div id="carouselExampleCaptions" class="carousel slide">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src={img} class="d-block w-100" alt="..." style={{ height: '45vh' }} />
                        <div class="carousel-caption d-md-block">
                            <h1 style={{ color: 'white' }}>Car Refinance</h1>
                            <p style={{ color: 'white' }}>Home &nbsp; &#10095; &nbsp; Car Refinance</p>
                        </div>
                    </div>
                </div>

            </div>
            <nav class="navbar navbar-dark bg-dark navbar-expand-lg navBar">
                <div class="container-fluid">
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                           

                        <li class="nav-item">
                    <a class="nav-link" href="#refinance">car refinance</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#features">Features</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#documents">Required Documents</a>
                </li>
                 <li class="nav-item">
                    <a class="nav-link" href="#eligibility">Eligibility</a>
                </li>
                 <li class="nav-item">
                    <a class="nav-link" href="#guide">A guide to applying</a>
                </li>
                        </ul>
                        <a href="https://wa.me/919506010080"><button class="btn btn-outline-wtsap my-2 my-sm-0" style={{ color: 'white', backgroundColor: 'green', borderRadius: '5px', padding: '6px 15px', marginLeft: '10px' }}><i class="fa fa-whatsapp"></i>&nbsp;Whatsapp</button></a>
                        <Link to="/getappointment"> <button class="btn btn-outline-apply my-2 my-sm-0" type="submit" style={{ color: 'black', backgroundColor: 'white', borderRadius: '5px', padding: '6px 15px', marginLeft: '10px' }}>Apply Now</button></Link>

                    </div>
                </div>
            </nav>

            <div id="refinance"></div>
    <section class="main-body">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h3>What is a car refinance?</h3>
                        <div class="row">
                            <div class="col-md-8">
                                <p>A Car loan is a credit product which essentially means that the borrower can take money from the bank and purchase a car, it is a secured loan, unlike personal loans. However, Car Loan Refinancing is the replacement of your existing auto loan with a brand new one from a different lender. You can refinance a car loan even when you have an existing loan.
<br/><br/>Depending on the auto loan refinance rates that you have agreed with your lender, you can pay back the car loan in installments or by EMIs. Car loans are popular credit products and most lenders offer Car Refinance in Varanasi to borrowers.
</p>
                               
                                 
                            </div>
                            <div class="col-md-4">
                                <img width="100%" src={img1} alt=""/>
                            </div>
                        </div>
                         <div id="features"></div>
                    </div>
                </div>
                 
            </div>
            
          
            <div class="row">
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h4>Features of Car Refinance </h4>
                        <p>We can now look at some of the common characteristics</p>
                         <div class="row">
                                    <div class="col-md-8">
                                        
                                    <ul class="listnone bullet bullet-check-circle-default">
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                     	<b>It's easy to buy a car through car loans -</b> Any lender can offer car loans to people in need of financing, and they do not need to seek informal credit.
                    </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                   <b>No collateral requirement -</b>   No collateral is required for car loans.
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                      <b>Used car loans are also available-</b>Loans are available for those looking to buy second-hand cars.
                      </li> 
                      
                     </ul>
                                    </div>

                                    <div class="col-md-4">
                                        <img width="80%" src={img2} alt=""/>
                                    </div>

                                </div>
                        <div id="documents"></div>
                    </div>
                </div>
            </div>

            
          
            <div class="row">
                <div class="col-md-12">
                    <div class="loan-lg-card">


                        <h4>Required Documents for a car refinance include-
 
</h4>
                        <div class="row">
                            <div id="cfpl"></div>
                            <div class="col-md-8">
                    
                            <ul class="listnone bullet bullet-check-circle-default">
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                    Proof of address (photocopies of recent telephone bills/electricity bills).
                    </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                      Self-employed individuals and professionals must submit three years of IT returns.
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                     Documents proving your identity (photocopies of your voter identification card, passport, driving license, or IT PAN card).
                      </li> 
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                  Three recent passport photos.
                      </li>
                       <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                      Fill out the loan application completely.
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                     Recent bank statements (within the past six months).
                      </li>
                     </ul>
                     

                            </div>
                            <div class="col-md-4">
                                <img width="100%" src={img3} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div id="eligibility"></div>
                </div>
            </div>
            

            
            
               <div class="row">
                <div class="col-md-12">
                    <div class="loan-lg-card">


                        <h4>Eligibility for Car Loans
 
</h4>
                        <div class="row">
                            <div id="cfpl"></div>
                            <div class="col-md-8">
                    
                            <ul class="listnone bullet bullet-check-circle-default">
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                    <b>Age:</b> Applicants between 21 and 75 years of age are eligible for car loans.
                    </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                      <b>Nationality:</b> Indian citizens are the only ones eligible for car loans.


                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                     <b>Minimum Salary:</b> A minimum salary of 10,000 is required.
                      </li> 
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                 <b>	Employment Type:</b> Salaried or self-employed candidates are accepted.
                      </li>
                       <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                     <b>Tenure: </b>The term of a car loan begins at one year and can extend to five years.
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                   <b>Credit History:</b> A credit score of 750 and above is considered a good credit score.
                      </li>
                     </ul>
                     
                     <p>The exact criteria for loan eligibility might differ from one lender to another, so check with the lender for details.</p>
                     

                            </div>
                            <div class="col-md-4">
                                <img width="100%" src={img4} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div id="guide"></div>
                </div>
            </div>
            
            
            
            
            
            
             <div class="row">
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h4>A guide to applying for a car loan through Bhumi Finance</h4>
                        <div class="row">
                            <div class="col-md-8">
                                <p>Our website allows you to create a credit profile; the application process takes just a few steps. A credit profile helps applicants understand their credit scores from their past loans and credit card usage.</p>
                              <p>As a result of your credit profile, you will be matched to credit products, such as auto loans, for which you will be more likely to qualify. Following an application on the lender's website and submitting all required documents, you can begin repaying the loan with EMIs. The used car refinance rates vary according to the lender.</p> 
                                 
                            </div>
                            <div class="col-md-4">
                                <img width="100%" src={img5} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            

        </div>
    </section>
            <HelpYou />
        </>
    )
}

export default RefinancePurchase
