export const loandata=[
    {imgUrl:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTEdz4Jd6TVNWipuLV_E1F9sMIBPHil1LGZTg&usqp=CAU" , h4:"11.25*%" , p:"Personal Loan", url:"/personalloan"},
    {imgUrl:"https://resources.freemi.in/wordpress/wp-content/uploads/2020/04/bl-main-img-2.png" , h4:"12.75*%" , p:"Business Loan", url:"businessloan"},
    {imgUrl:"https://w7.pngwing.com/pngs/323/357/png-transparent-va-loan-mortgage-loan-caliber-home-loans-business-lending-angle-hand-logo-thumbnail.png" , h4:"11.75*%" , p:"CA/Dr Professional Loan", url:"/businessloan"},
    {imgUrl:"https://mir-s3-cdn-cf.behance.net/projects/404/fe884c135506647.Y3JvcCwxMDgwLDg0NCwwLDExNw.jpg" , h4:"8.8*%" , p:"Home Loan", url:"/homeloan"},
    {imgUrl:"https://www.businessleague.in/wp-content/uploads/2018/12/home-loan-01-2-770x433.jpg" , h4:"9.00*%" , p:"Loan Against Property", url:"/loanagainstproperty"},
    {imgUrl:"https://thumbs.dreamstime.com/z/loan-document-thin-line-color-vector-icon-loan-document-vector-icon-elements-mobile-concept-web-apps-thin-line-icons-146710573.jpg" , h4:"9.00*%" , p:"DOD/OD/CC", url:"/contact"},
    {imgUrl:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0D6SvB_fxOgaVUaAsA9qyOMVHKWu82d9eng&usqp=CAU" , h4:"10.25*%" , p:"Gold Loan", url:"/goldloan"},
    {imgUrl:"https://www.balajicorploan.com/bcc-assets/uploads/2017/04/car-key-loan.png" , h4:"8.75*%" , p:"New Car Loan", url:""},
    {imgUrl:"https://st4.depositphotos.com/3936669/25152/v/600/depositphotos_251524902-stock-illustration-colorful-print-element-design-for.jpg" , h4:"14.00*%" , p:"Old Car Loan", url:"/refinancepurchase"},
]