import img from '../../images/contact.jpeg'
import './businesspartner.css'
import img1 from '../../images/getappo.jpeg'
import React,{useState} from 'react'
import { useToast } from '@chakra-ui/react'
import {useNavigate} from 'react-router-dom'
import axios from 'axios'
const GetAppointment = () => {
  const toast = useToast()
  const navigate =useNavigate()

const [name,setName] = useState("")
const [appointmentfor,setAppointmentfor] = useState("")
const [email,setEmail] = useState("")
const [mobile,setMobile] = useState("")
const [address,setAddress] = useState("")


const nameChange=(e)=>{
    setName(e.target.value)
}
const appointmentforChange=(e)=>{
    setAppointmentfor(e.target.value)
}
const emailChange=(e)=>{
    setEmail(e.target.value)
}
const mobileChange=(e)=>{
    setMobile(e.target.value)
}
const addressChange=(e)=>{
    setAddress(e.target.value)
}



const formSubmit=async(e)=>{
  e.preventDefault()
  if(!name || !appointmentfor || !email || !mobile || !address ){
      toast({
          title: 'Failed.',
          description: "Please Fill all the details.",
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
        return;
  }
  try{
      const config={
          headers:{
              "Content-type":"application/json"
          }
      }
      const {data} =await axios.post("/api/user/getappointment",{name,appointmentfor,email,mobile,address},config)
          toast({
              title: 'Submitted Successfully.',
              description: "Our team will contact you soon.",
              status: 'success',
              duration: 5000,
              isClosable: true,
            })
            localStorage.setItem("userData",JSON.stringify(data))
            navigate("/")
     
  }catch(err){
      toast({
          title: 'Failed.',
          description: err.message,
          status: 'error',
          duration: 5000,
          isClosable: true,
        })
  }
}
    return (
        <>
            <div id="carouselExampleCaptions" class="carousel slide">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src={img} class="d-block w-100" alt="..." style={{ height: '45vh' }} />
                        <div class="carousel-caption d-md-block">
                            <h1 style={{ color: 'white' }}>Get Appointment</h1>
                            <p style={{ color: 'white' }}>Home &nbsp; &#10095; &nbsp; Get Appointment</p>
                        </div>
                    </div>
                </div>

            </div>
           

            <div className="heading">
                <h6 style={{color:'red',marginTop:'30px',textAlign:'center'}}>Get in Touch</h6>
                <h1>Get Your Appointment</h1>
            </div>
            <div class="section-block">
  <div class="container my-3">
    <div class="row mt-50">
      
      <div class="col-md-6 col-sm-6 col-12">
<form class="contact-form" method="post">
 
          <div class="row">
            <div class="col-md-12">
              <input type="text" name="name" className='form-control' value={name} onChange={nameChange} placeholder="Name" required/>
                    </div>
                    <div class="col-md-12">
              <select id= "profession" value={appointmentfor} onChange={appointmentforChange} name="profession" className='form-select' required>
              <option value="">Appointment For</option>
                <option value="personal">Personal Loan</option>
                <option value="home">Home Loan</option>
                <option value="property">Loan Against Property</option>
                <option value="car">Car Loan</option>
                <option value="gold">Gold Loan</option>
                <option value="business">Business Loan</option>
                <option value="ca">CA/ Dr Professional Loan</option>
                <option value="dod">DOD/OC/CC</option>
              </select>
                            </div>

             <div class="col-md-6 col-sm-6 col-12">
              <input type="email" name="email" className='form-control' value={email} onChange={emailChange} placeholder="E-mail" required/>
                    </div>

             <div class="col-md-6 col-sm-6 col-12">
              <input type="number" name="mobile" className='form-control' value={mobile} onChange={mobileChange} placeholder="Mobile" required/>
                    </div>
            
                    <div class="col-md-12">
              <textarea name="address" id="address"  placeholder="Address" value={address} onChange={addressChange}></textarea>
          		   </div>
            <div class="g-recaptcha" id="rcaptcha"  data-sitekey="6LeJ4eQfAAAAAMfapz_pEBGhXp3n7ETcqTJYFowK"></div>
<span id="captcha" style={{color:"red"}} ></span>
            <div class="col-md-12">
                <p>Our team will connect with you shortly.</p>
            </div>
            <div class="col-md-12">
              <div class="center-holder">
                <button type="submit" onClick={formSubmit}>Send</button>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="col-md-6 col-sm-6 col-12">
        <img src={img1} alt="image" style={{width:"80%"}}/>
        
      </div>
    </div>

  </div>
</div>
        </>
    )
}
export default GetAppointment;