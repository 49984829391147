import React from 'react'
import Carousel from 'react-elastic-carousel'
import './loanproducts.css'
import {Link} from 'react-router-dom'

const LoanProducts = () => {
    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 1, itemsToScroll: 2, pagination: false },
        { width: 850, itemsToShow: 3 },
        { width: 1150, itemsToShow: 3, itemsToScroll: 2 },
        { width: 1450, itemsToShow: 3 },
        { width: 1750, itemsToShow: 4 },
      ]
  return (
    <>
    <div className="container " style={{padding:'100px 0'}}>
        <div className="heading">
            <h1>Find Loan Products We Offers</h1>
            <p>We will match you with a loan program that meets your financial need. In short term liquidity, by striving to make funds available to them <strong>within 24 hours of application</strong> .</p>
        </div>
        <div className="container my-5 loanproducts">
            <Carousel breakPoints={breakPoints}>
            <div className="loanproduct">
            <span class="material-symbols-outlined">
monetization_on
</span>
<h4>Personal Loan</h4>
<Link to="/personalloan">View All</Link>
            </div>
            <div className="loanproduct">
            <span class="material-symbols-outlined">
cottage
</span>
<h4>Home Loan</h4>
<Link to="/homeloan">View All</Link>
            </div>
            <div className="loanproduct">
            <span class="material-symbols-outlined">
location_city
</span>
<h4>Loan Against Property</h4>
<Link to="/loanagainstproperty">View All</Link>
            </div>
            <div className="loanproduct">
            <span class="material-symbols-outlined">
payments
</span>
<h4>Business Loan</h4>
<Link to="/businessloan">View All</Link>
            </div>
            <div className="loanproduct">
            <span class="material-symbols-outlined">
local_shipping
</span>
<h4>Used Car Loan</h4>
<Link to="/refinancepurchase">View All</Link>
            </div>
            <div className="loanproduct">
            <span class="material-symbols-outlined">
savings
</span>
<h4>Gold Loan</h4>
<Link to="/goldloan">View All</Link>
            </div>
            <div className="loanproduct">
            <span class="material-symbols-outlined">
credit_card
</span>
<h4>Credit card</h4>
<Link to="/contact">View All</Link>
            </div>
            </Carousel>
        </div>
    </div>
    
    </>
  )
}

export default LoanProducts
