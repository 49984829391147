import React from 'react'
import img from '../../images/goldloan.jpeg'
import img1 from '../../images/gl1.jpeg'
import img2 from '../../images/gl2.jpeg'
import img3 from '../../images/gl3.jpeg'
import { Link } from 'react-router-dom'

import HelpYou from '../home/whychoose/HelpYou'
import '../personalloan/personalloan.css'
const GoldLoan = () => {
    return (
        <>
            <div id="carouselExampleCaptions" class="carousel slide">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src={img} class="d-block w-100" alt="..." style={{ height: '45vh' }} />
                        <div class="carousel-caption d-md-block">
                            <h1 style={{ color: 'white' }}>Gold Loan</h1>
                            <p style={{ color: 'white' }}>Home &nbsp; &#10095; &nbsp; Gold Loan</p>
                        </div>
                    </div>
                </div>

            </div>
            <nav class="navbar navbar-dark bg-dark navbar-expand-lg navBar">
                <div class="container-fluid">
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                    <a class="nav-link" href="#gold">Gold Loan</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#why">Why to Choose a Gold Loan?</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#doc">Documentation</a>
                </li>
                
                <li className='nav-item'>
                 <Link class="nav-link" to="/emicalc">Calculate your Gold Loan EMI</Link>
                </li>
                <li class="nav-item">
                    <Link class="nav-link" to="/contact">Contact Us</Link>
                </li>

                        </ul>
                        <a href="https://wa.me/919506010080"><button class="btn btn-outline-wtsap my-2 my-sm-0" style={{ color: 'white', backgroundColor: 'green', borderRadius: '5px', padding: '6px 15px', marginLeft: '10px' }}><i class="fa fa-whatsapp"></i>&nbsp;Whatsapp</button></a>
                        <Link to="/getappointment"> <button class="btn btn-outline-apply my-2 my-sm-0" type="submit" style={{ color: 'black', backgroundColor: 'white', borderRadius: '5px', padding: '6px 15px', marginLeft: '10px' }}>Apply Now</button></Link>

                    </div>
                </div>
            </nav>
            <div id="gold"></div>
    <section class="main-body">
        
        
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h3>Gold Loan</h3>
                        <div class="row">
                            <div class="col-md-8">
                                <p>Gold loan is a great & easy solution for any financial needs. It can be opted for many purposes like business, personal, education, medical, agriculture etc. documentation and other formalities are very simple. you can easily avail a Gold Loan against your gold jewellery up to Rs 1 crore on attractive interest rates* </p>
                                
                            </div>
                            <div class="col-md-4">
                                <img width="100%" src={img1} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
              <div id="why"></div>
            
            
            
            <div class="row">
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h3>Why to Choose a Gold Loan?</h3>
                        <p>A few reasons for the popularity loan against gold are as follows:</p>
                        <div class="row">
                            <div class="col-md-8">
                                
                                    <ul class="listnone bullet bullet-check-circle-default">
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                      Low interest rate 
                    </li>
                      <li style={{color:"#404040"}} ><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                     Tenure ranges from a few days to 5 years.
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                     As per choice repayment options 
                      </li> 
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                   Minimal documentation required. 
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                    Fast disbursement 
                      </li>
                      

                    </ul>
                    
                    <h4>Interest rate: 7.35 %*</h4>
                    We compare and offer best interest rate possible!
                                
                                
                                
                            </div>
                            <div class="col-md-4">
                                <img width="100%" src={img2} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            
              <div id="doc"></div>
              
            <div class="row">
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h3>Documentation</h3>
                      
                        <div class="row">
                            <div class="col-md-8">
                                
                                    <ul class="listnone bullet bullet-check-circle-default">
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                   Two passport-size photographs
                    </li>
                      <li style={{color:"#404040"}} ><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                    Identity proof with a photo. (Any of the following: Driving Licence/Passport/Voters’ ID Card/Aadhaar Card/Ration Card)
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                   Address proof (Any of the following: Driving Licence/Passport/Voters’ ID Card/Aadhaar Card/Ration Card/Job Card Issued by NREGA) 
                      </li> 
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                   For direct agricultural activity, proof of land holding is required if the loan is above Rs 1 lakh.
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                   For all allied agricultural activities, supporting documents are required if the loan is above Rs 5 lakh. 
                      </li>
                       <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                  Any other document as per banks’ requirement
                      </li>
                      

                    </ul>
                    
    
                            </div>
                            <div class="col-md-4">
                                <img width="70%" src={img3} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            
            
      
        

        </div>
    </section>
            <HelpYou />
        </>
    )
}

export default GoldLoan
