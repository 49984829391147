import React from 'react'
import img from '../../images/loanap.jpeg'
import img1 from '../../images/lap1.jpeg'
import img2 from '../../images/pl4.jpeg'
import img3 from '../../images/lap2.jpeg'
import img4 from '../../images/lap2.jpeg'
import img5 from '../../images/pl3.jpeg'



import { Link } from 'react-router-dom'
import HelpYou from '../home/whychoose/HelpYou'
import '../personalloan/personalloan.css'
const LoanAgainstProperty = () => {
    return (
        <>
            <div id="carouselExampleCaptions" class="carousel slide">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src={img} class="d-block w-100" alt="..." style={{ height: '45vh' }} />
                        <div class="carousel-caption d-md-block">
                            <h1 style={{ color: 'white' }}>Loan Against Property</h1>
                            <p style={{ color: 'white' }}>Home &nbsp; &#10095; &nbsp; Loan Against Property </p>
                        </div>
                    </div>
                </div>

            </div>
            <nav class="navbar navbar-dark bg-dark navbar-expand-lg navBar">
                <div class="container-fluid">
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                    <a class="nav-link" href="#About">About Loan Against Property</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#help">Help </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#Eligibility">Eligibility</a>
                </li>

                        </ul>
                        <a href="https://wa.me/919506010080"><button class="btn btn-outline-wtsap my-2 my-sm-0" style={{ color: 'white', backgroundColor: 'green', borderRadius: '5px', padding: '6px 15px', marginLeft: '10px' }}><i class="fa fa-whatsapp"></i>&nbsp;Whatsapp</button></a>
                        <Link to="/getappointment"> <button class="btn btn-outline-apply my-2 my-sm-0" type="submit" style={{ color: 'black', backgroundColor: 'white', borderRadius: '5px', padding: '6px 15px', marginLeft: '10px' }}>Apply Now</button></Link>

                    </div>
                </div>
            </nav>
            <div id="About"></div>
    <section class="main-body">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h3>About Loan Against Property</h3>
                        <div class="row">
                            <div class="col-md-8">
                                <p>Loan against Property (LAP/Mortgage Loan), lets consumers to raise funds by leveraging their residential, commercial, industrial properties. Like unsecured personal loans, this loan can also be availed for meeting both personal and business needs. Banks and HFCs offer loan against property starting at low interest rates for tenures of up to 20 years. The loan amount for LAP can go up to 70% of the property’s market value, depending on the lender and the credit profile of the borrowers. Many lenders also offer Lease Rental Discounting (LRD) facility, which allows consumers to avail loan by pledging the rental receipts of their tenants.</p>
                          
                   
                      
                                
                                
                            </div>
                            <div class="col-md-4">
                                <img width="100%" src={img1} alt=""/>
                            </div>
                        </div>
                    
                </div>
            </div>
            
            
            
            
            
            <div id="help"></div>
            <div class="row">
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h4>Loan against Property can be availed on below Properties</h4>
                         <div class="row">
                                    <div class="col-md-8">
                                        
                                    <ul class="listnone bullet bullet-check-circle-default">
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                    </li>
                    <b>Hospitals</b> 
                      <li style={{color:"#404040"}} ><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                     <b>Hotels</b>
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                       <b> Industrial Property </b>like factory, Workshops etc
                      </li> 
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                       <b> Commercial Property  </b>like Shopping Complex, Malls etc
                      </li>
                      
                      
                       <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                       <b> Residential Property  </b>like Row Houses, Flats etc
                      </li>
                      
                      
                       <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                       <b> Private School & Professional College Buildings  </b>
                      </li>
                     </ul>
                     
                     </div>

                                    <div class="col-md-4">
                                        <img width="80%" src={img2} alt=""/>
                                    </div>

                                </div>
                        
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="col-md-12">
                    <div class="loan-lg-card">


                        <h4>Characteristic of Loan Against Property</h4>
                        <div class="row">
                            <div id="cfpl"></div>
                            <div class="col-md-8">
                                
                   
                    
                    <ul class="listnone bullet bullet-check-circle-default">
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                     A Secured Loan, it is offered only when the individual/applicant keeps the property with the bank as security. The loan interest rates are allocated based on the individual’s monthly salary, amount taken as loan, interest rates etc. Rate of interest of loans are again based on the customer’s profile (salary, credit history etc).
                    </li>
                      <li style={{color:"#404040"}} ><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                      	Repayment periods are usually upto 20 Years and hence are long-tenure loans.
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                      The customer/applicant is also responsible for payment of charges towards administrative procedures while processing the application, along with the processing fee.
                      </li> 
                     </ul>
                     
                     
                     <h6>What purpose can one use loan against property funds for?</h6>
                     
                      <ul class="listnone bullet bullet-check-circle-default">
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                 	Funding business and working capital needs of individuals, partnerships, private companies and SME's
                    </li>
                      <li style={{color:"#404040"}} ><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                     	To purchase new property by pledging existing property
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                      As lower cost alternative to personal use and uses such as marriages, education, vacation, home renovation, medical emergency
                      </li> 
                     </ul>
                     
                            </div>
                            
                            
                            
                            <div class="col-md-4">
                                <img width="100%" src={img3} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div id="Eligibility"></div>
                </div>
            </div>
            
            <div class="row">
                <div class="col-md-12">
                    <div class="loan-lg-card">


                        <h4>Eligibility Criteria for Loan Against Property</h4>
                        <div class="row">
                            <div id="cfpl"></div>
                            <div class="col-md-8">
                    <p>The eligibility for Loan against property is determined by various factors including income, employment status, loan tenure and so on, and remains almost the same for professionals, self-employed professionals and businessmen.</p><br/>
                    <ul class="listnone bullet bullet-check-circle-default">
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                  Any Indian citizen in good standing who is salaried, self-employed or business person with regular source of income can apply for a home loan.
                    </li>
                      <li style={{color:"#404040"}} ><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                      The applicant should be above the age of 24 years.
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                        Should be currently employed with existing organization or been involved in your business for a specific number of years
                      </li> 
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                      Professional stability and savings history play a major role in approval of the loan, especially minimum required monthly salary and repaying capacity
                      </li>
                       <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                      Bad credit history would prove to be a put-off, especially anytime within 3 months prior to applying for home loan. Hence, should have submitted EMIs for other loans on time, for the said amount of period.
                      </li>
                     </ul>

                            </div>
                            <div class="col-md-4">
                                <img width="100%" src={img4} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div id="benefits"></div>
                </div>
            </div>
            

         
         
          <div class="row">
                <div class="col-md-12">
                    <div class="loan-lg-card">


                        <h4>Benefit of availing Loan Against Property (LAP) instead of other loans?</h4>
                        <div class="row">
                            <div id="cfpl"></div>
                            <div class="col-md-8">
                                
                   
                    
                    <ul class="listnone bullet bullet-check-circle-default">
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                    	As loan against property is secured against property, banks offer lower rate of interest compared to unsecured business loans or personal loans
                    </li>
                      <li style={{color:"#404040"}} ><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                      Loan tenure can be up to 20 years leading to lower EMI compared to unsecured loans where loan tenure can only be up to 5 years
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                      Loan tenure can be up to 20 years leading to lower EMI compared to unsecured loans where loan tenure can only be up to 5 years
                      </li> 
                      
                       <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                     	Loan can be taken in name of company/ firm even if the property is owned in individual name of partners/ directors
                      </li> 
                     </ul>
                     
                     
                   
                     
                            </div>
                            
                            
                            
                            <div class="col-md-4">
                                <img width="100%" src={img5} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div id="Eligibility"></div>
                </div>
            </div>
         
         
         
         
         
         </div>
            
            

        </div>
    </section>
            <HelpYou />
        </>
    )
}

export default LoanAgainstProperty;
