import React from 'react'
import {loandata} from './loandata'
import LoanItem from './LoanItem'
import './loan.css'

const LoanItems = () => {
  return (
    <>
    <div className="container loanitems my-5">
        <div className="grid">
            {
                loandata.map((item)=>{
                    return <LoanItem imgUrl={item.imgUrl} h4={item.h4} p={item.p} url={item.url}/>
                })
            }
        </div>
    </div>
    </>
  )
}

export default LoanItems
