import React from 'react'
import img from '../../images/carloan.jpeg'
import img1 from '../../images/cl1.jpeg'
import img2 from '../../images/cl2.jpeg'
import img3 from '../../images/pl3.jpeg'
import { Link } from 'react-router-dom'

import HelpYou from '../home/whychoose/HelpYou'
import '../personalloan/personalloan.css'
const CarLoan = () => {
    return (
        <>
            <div id="carouselExampleCaptions" class="carousel slide">
                <div class="carousel-inner">
                    <div class="carousel-item active">
                        <img src={img} class="d-block w-100" alt="..." style={{ height: '45vh' }} />
                        <div class="carousel-caption d-md-block">
                            <h1 style={{ color: 'white' }}>Car Loan</h1>
                            <p style={{ color: 'white' }}>Home &nbsp; &#10095; &nbsp; Car Loan</p>
                        </div>
                    </div>
                </div>

            </div>
            <nav class="navbar navbar-dark bg-dark navbar-expand-lg navBar">
                <div class="container-fluid">
                    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarTogglerDemo01" aria-controls="navbarTogglerDemo01" aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
                        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                        <li class="nav-item">
                    <a class="nav-link" href="#newloan">New Car Loan</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#carfinancing">About Car Financing</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" href="#process">How To Get A New Car Loan</a>
                </li>
                 <li class="nav-item">
                    <a class="nav-link" href="#eligibility">New Car Loan Eligibility Criteria</a>
                </li>
                 <li class="nav-item">
                    <a class="nav-link" href="#oldloan">Old Car Loan</a>
                </li>
                
                  <li class="nav-item">
                    <a class="nav-link" href="#oldprocess">The Process To Apply For A Used Car Loan</a>
                </li>
                        </ul>
                        <a href="https://wa.me/919506010080"><button class="btn btn-outline-wtsap my-2 my-sm-0" style={{ color: 'white', backgroundColor: 'green', borderRadius: '5px', padding: '6px 15px', marginLeft: '10px' }}><i class="fa fa-whatsapp"></i>&nbsp;Whatsapp</button></a>
                        <Link to="/getappointment"> <button class="btn btn-outline-apply my-2 my-sm-0" type="submit" style={{ color: 'black', backgroundColor: 'white', borderRadius: '5px', padding: '6px 15px', marginLeft: '10px' }}>Apply Now</button></Link>

                    </div>
                </div>
            </nav>
            <div id="newloan"></div>
    <section class="main-body">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h3>A Guide To Loans Against Cars</h3>
                        <div class="row">
                            <div class="col-md-8">
                                <p>A refinance loan involves taking out a new loan at a lower interest rate or extending the repayment period to lower your monthly payments.
</p>
<p>A refinance loan involves taking out a new loan at a lower interest rate or extending the repayment period to lower your monthly payments.</p>
                               
                                 
                            </div>
                            <div class="col-md-4">
                                <img width="100%" src={img1} alt=""/>
                            </div>
                        </div>
                         <div id="carfinancing"></div>
                    </div>
                </div>
                 
            </div>
            
            
          
            <div class="row">
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h4>About Car Financing</h4>
                        
                         <div class="row">
                                    <div class="col-md-8">
                                   <p>Many people once were unable to afford to own a new car. For many people, this is still a huge milestone. To buy a dream car, you need a lot of money. The process of buying a new car is no longer as challenging these days as most banks readily offer car loans.
                        <br/><br/>

These are brand new car loans provided by new car dealerships. As a brand-new car owner, you can expect to have easy access to service and spare parts, as well as brand-backed warranties.</p>     
                                   
                                    </div>

                                    <div class="col-md-4">
                                        <img width="100%" src={img2} alt=""/>
                                    </div>

                                </div>
                        <div id="process"></div>
                    </div>
                </div>
            </div>

            
          
            <div class="row">
                <div class="col-md-12">
                    <div class="loan-lg-card">


                        <h4>How To Get A New Car Loan
 
</h4>

<p>  The step-by-step process of taking out a car loan follows below. The term and factor list in our description may help you understand the process.</p>
                        <div class="row">
                            <div id="cfpl"></div>
                            <div class="col-md-8">
                    
                            <h5>Check your credit report</h5>
                            <p>Before you apply for a car loan, you should know your credit score. Scores have a direct correlation with loans you take out from the bank, as well as interest rates. Due to the increased risk of you defaulting on the payment, they will also charge a higher interest rate. Likewise, the reverse is true.</p>
                            
                            <h5>Calculate the borrowing amount</h5>
                            <p>By now, you would have already decided. Next, it will be time to figure out the EMIs a person may afford. Down payments will lessen the burden on the loan. In addition, you must determine how long the loan will last. An increased loan term means a lower EMI.</p>
                            
                             <h5>Choose the right lender</h5>
                            <p>Find a lender that offers the lowest interest rate. Some credit unions offer lower interest rates than large banks. However, their services may be substandard.</p>
                            
                            
                             <h5>Apply for pre-approval</h5>
                            <p>Once you have selected a lender, you can apply for pre-approval. Your dealer may be able to assist you with pre-approval and financing. If you are pre-approved by five lenders, you can choose one in 30-60 days.</p>
                            

                            </div>
                            <div class="col-md-4">
                                <img width="100%" src={img1} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div id="eligibility"></div>
                </div>
            </div>
            

            
            
               <div class="row">
                <div class="col-md-12">
                    <div class="loan-lg-card">


                        <h4>New Car Loan Eligibility Criteria
 
</h4>
<p>There are qualifications for obtaining a new car loan, and not everyone will qualify.</p>


                        <div class="row">
                            <div id="cfpl"></div>
                            <div class="col-md-8">
                    
                            <ul class="listnone bullet bullet-check-circle-default">
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                    <b>The minimum and maximum age</b> - From 18 to 65 years of age
                    </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                     <b>The loan amount</b> - Many banks provide loans up to 50 lakhs. The maximum loan amount in some banks is Rs. 1 crore.


                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                   <b>Net income per month</b> - Monthly income of at least Rs. 10,000
                      </li> 
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                <b>Employment Type</b> - Salaried or self-employed.
                      </li>
                       <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                   <b>Type of car</b> - The loan amount varies depending upon the new or old car.
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                  <b>Work Experience</b>A minimum of one year’s experience in the field is required for the position to be salaried. The self-employed must be in business for five years and have a minimum two-year ITR.
                      </li>
                      
                       <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span><b>Value of car</b> - Depending on the ex-showroom price or on-road price of your new car and the sale value of your old one, your loan value eligibility differs.
                      </li>
                      
                      
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span><b>Residence</b> - A city, a town, or a rural area.
                      </li>
                      
                      
                      
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span><b>Stability of residence</b> - A minimum of one year of residency is required at the current address.
                      </li>
                      
                     </ul>
                     
                     
                     

                            </div>
                            <div class="col-md-4">
                                <img width="100%" src={img3} alt=""/>
                            </div>
                        </div>
                    </div>
                    <div id="oldloan"></div>
                </div>
            </div>
            
            
            
            
            
            
             <div class="row">
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h4> About Old Car Financing</h4>
                        <div class="row">
                            <div class="col-md-8">
                                <p>Used car sales are booming when the automobile industry is struggling with sales declines. Several national and private banks and financing companies are financing used cars due to the growing demand for vehicles.<br/><br/>

Loans for used cars in India are much more challenging to obtain than those for new ones. Banks offer 70-80% financing of the used cars, while it’s up to 90% for the new cars. Similarly, the interest rates and loan terms for used cars are higher. Yet, there are options for getting a used car loan at a low rate and for an extended term.</p>
                                
                               
                                
                                 
                            </div>
                            <div class="col-md-4">
                                <img width="100%" src={img1} alt=""/>
                            </div>
                        </div>
                    </div>
                     <div id="oldloan"></div>
                </div>
            </div>
            
            
            
            
            
            
             <div class="row">
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h4> Do you know how to finance a used car?</h4>
                        <p>If you are taking out a loan for a used car, be sure to consider the type and basic requirements. The following steps will help you obtain a loan for a pre-owned car.</p>
                        <div class="row">
                            <div class="col-md-8">
                                
                                
                                
                                <ul class="listnone bullet bullet-check-circle-default">
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                          <h6>Car type and model</h6>
                   Select the type and model of car that fits your needs while still staying within your budget. A car loan is usually approved based on the age and condition of the model of the car. In addition, borrowers can get used car loans more quickly if they choose a relatively new model.
                    </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                   <h6>Calculate the value of your old car</h6>
                   
                   Calculate how much you need to pay after you select the car model. A small loan with a simple installment scheme may be appropriate if you can pay lump sum advance payments. It will let you know how much financing is necessary for buying used cars.
                      </li>
                      
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                   <h6>Identify your requirements</h6>
                   The monthly payments will depend on the loan amount and loan term you select. Most of the time, it depends on your income and future projections.
                      </li> 
                      
                         
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                   <h6>Verify and submit the documents</h6>
                   A bank or other lending institution will need authorization and proof of income stability before lending money. Banks require different documents. For more information, contact the bank. The bank issues the loan to the borrower after submission of all the required documentation.
                      </li>
                      </ul>
                      
                    
                            </div>
                            <div class="col-md-4">
                                <img width="100%" src={img2} alt=""/>
                            </div>
                        </div>
                    </div>
                     <div id="oldprocess"></div>
                </div>
            </div>
            
            
         
         
         
             <div class="row">
                <div class="col-md-12">
                    <div class="loan-lg-card">
                        <h4>The Process To Apply For A Used Car Loan</h4>
                        <div class="row">
                            <div class="col-md-8">
                                
                                
                               
                                
                                <ul class="listnone bullet bullet-check-circle-default">
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                    Apply online or offline to get a used car loan. The first step is to fill out a loan application and submit it.
                    </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                 Gather the necessary documents and upload or submit them for verification.
                      </li>
                      <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                Afterward, we will determine your eligibility after verifying your documents.
                      </li> 
                      
  <li style={{color:"#404040"}}><span class="material-symbols-outlined">
                                                check_circle
                                            </span>
                You will receive the funds once the loan is approved. After submitting documents, loan disbursal may take anywhere from 24 to 36 hours.
                      </li> 
                      </ul>
                      
                    

                            </div>
                            <div class="col-md-4">
                                <img width="100%" src={img1} alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
         
      
   

        </div>
    </section>
            <HelpYou />
        </>
    )
}

export default CarLoan
