import React from 'react'
import img1 from '../../images/associate.jpeg'
import img2 from '../../images/businessloan.jpeg'
import img3 from '../../images/carloan.jpeg'
import img4 from '../../images/calculator.jpeg'
import img5 from '../../images/homeloan.jpeg'
import {Link} from 'react-router-dom'
const Carousel = () => {
  return (
    <>
    <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">

    <div class="carousel-indicators">
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="1" aria-label="Slide 2"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="2" aria-label="Slide 3"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="3" aria-label="Slide 4"></button>
    <button type="button" data-bs-target="#carouselExampleCaptions" data-bs-slide-to="4" aria-label="Slide 5"></button>

  </div>
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src={img1} class="d-block w-100" alt="..." style={{height:'45vh'}}/>
      <div class="carousel-caption d-md-block">
        <h1 style={{color:'white'}}>Increase your earning now !!!</h1>
        <p style={{color:'white'}}><strong style={{color:'red'}}>Become Riddhi Siddhi Finances Associate Program</strong> and increase your earning.</p>
        <button className="btn btn-danger"><Link style={{color:'white'}} to="/joinassociate">Know More</Link></button>
      </div>
    </div>
    <div class="carousel-item">
      <img src={img2} class="d-block w-100" alt="..." style={{height:'45vh'}}/>
      <div class="carousel-caption d-md-block">
        <h1 style={{color:'white'}}>Welcome the new beginnings !!!</h1>
        <p style={{color:'white'}}>Starting a new business or expanding an existing business? Get <strong style={{color:'red'}}>instant business loans</strong> with the minimal procedure.</p>
        <button className="btn btn-danger"><Link style={{color:'white'}} to="/businessloan">Know More</Link></button>

      </div>
    </div>
    <div class="carousel-item">
      <img src={img3} class="d-block w-100" alt="..." style={{height:'45vh'}}/>
      <div class="carousel-caption d-md-block">
        <h1 style={{color:'white'}}>Get the ride to your destination!!!!</h1>
        <p style={{color:'white'}}>Looking for a Dream car. Get your ride of joy with low-interest rate <strong style={{color:'red'}}>car loans</strong> .</p>
        <button className="btn btn-danger"><Link style={{color:'white'}} to="/carloan">Get in Touch</Link></button>

      </div>
    </div>
    <div class="carousel-item">
      <img src={img4} class="d-block w-100" alt="..." style={{height:'45vh'}}/>
      <div class="carousel-caption d-md-block">
        <h1 style={{color:'white'}}>Get Transparent and Simple EMIs details!!</h1>
        <p style={{color:'white'}}><strong style={{color:'red'}}>Calculate your EMI easily</strong> and take a well-calculated decision.</p>
        <button className="btn btn-danger"><Link style={{color:'white'}} to="/emicalc">Calculate Now</Link></button>

      </div>
    </div>
    <div class="carousel-item">
      <img src={img5} class="d-block w-100" alt="..." style={{height:'45vh'}}/>
      <div class="carousel-caption d-md-block">
        <h1 style={{color:'white'}}>You Dream, We build !!!!</h1>
        <p style={{color:'white'}}>Get your dream house with easy <strong style={{color:'red'}}>Home Loans</strong> at low EMI rates.</p>
        <button className="btn btn-danger"><Link to="/homeloan" style={{color:'white'}}>Apply Now</Link></button>

      </div>
    </div>
  </div>
</div>
    </>
  )
}

export default Carousel
